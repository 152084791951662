import React, { useState } from 'react';
import AccordionButton from '../AccordionButton';
import Content from './Content';

import Flowallet from '../../PaymentOptions/PaymentOptionContents/Flowallet';


const AccordionItem = ({onClick, itemName, isActive, selected, item, paymentType, subPaymentMethods }) => {

  return (
    item.code !== "flowallet" &&
    <div>
      <AccordionButton
        onClick={onClick}
        itemName={itemName}
        isActive={isActive}
        selected={selected}
        item={item}
      />
     { selected === isActive 
      ? 
      <Content className="content" item={item} paymentType={paymentType} subPaymentMethods={subPaymentMethods} />
     : "" 
     }
    </div>
  )
}

export default AccordionItem;
