import GalleryModal from './GalleryModal';
import Gallery from './Gallery';
import OldProducts from './OldProducts';
import ShareBasket from './ShareBasket/ShareBasket';
import SizeWrapper from './SizeWrapper';
import SpecialCampaign from './SpecialCampaign';

export {
  GalleryModal,
  OldProducts,
  ShareBasket,
  SizeWrapper,
  Gallery,
  SpecialCampaign
};
