import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// icon
import {
  OutOfStockIconOrange,
  OutOfStockIconBlack,
  OutOfStockIconButigo,
} from '@Icons';
import {
  gettext,
} from '@Translate';
import config from '@Config';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderSummaryAction, cartActionRequest, setInstallmentAction } from '@Actions'
import { toast } from 'react-toastify';
// Components
import OutOfStockCard from '../OutOfStockCard';
import { IsArray } from '../../utils/IsArray';

// TODO dummy data ve project silinip products ve project prop olarak eklenecek
const OutOfStock = ({ products, redirectUrl }) => {
  const { project } = config;
  const navigate = useNavigate();
  const { shoppingCart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { savedCards, creditCardPlatform, creditCartNumber } = useSelector((state) => state.order);

  const handleRedirect = async () => {
    if (redirectUrl) {
      navigate('/basket');
    } else {
      if (savedCards && creditCardPlatform) {
        await dispatch(
          setInstallmentAction({
            creditCardPlatform,
          }),
        );
      } else if (creditCartNumber && creditCartNumber?.length > 8) {
        await dispatch(
          setInstallmentAction({
            creditCartNumber,
          }),
        );
      }
      await dispatch(cartActionRequest({ lastBasketItems: false, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0 }));
      await dispatch(getOrderSummaryAction());
    }
    toast.dismiss();
  };

  return (
    <div className="out-of-stock-container">
      <div
        data-test="icon-container"
        className="out-of-stock__icon-container"
      >
        {project === 'flo' || project === 'instreet' ? (
          <OutOfStockIconOrange />
        ) : (
          project === 'butigo'
        ) ? (
          <OutOfStockIconButigo />
          ) : (
            <OutOfStockIconBlack />
          )}
      </div>
      <div
        data-test="header"
        className={`out-of-stock__header-${project} out-of-stock__header`}
      >
        {gettext('Sepetinizdeki Ürünün Stoğu Tükendi!')}
      </div>
      <div data-test="content" className="out-of-stock__content">
        {gettext(
          'Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.',
        )}
      </div>
      <div
        data-test="out-of-stock-container"
        className="out-of-stock--container"
      >
        {IsArray(products)?.length > 0
          ? IsArray(products)?.map((product) => (
            <OutOfStockCard
              image_vertical={product?.image_vertical}
              name={product?.name}
              size={product?.size}
              manufacturer={product?.manufacturer}
              image={product?.image}
              key={product?.id}
            />
          ))
          : ''}
      </div>
      <div
        data-test="out-of-stock-footer"
        className={`out-of-stock__footer-${project} out-of-stock__footer`}
      >
        <button className={`out-of-stock__ok-button ${project}-out-of-stock__ok-button`} onClick={handleRedirect} data-test="checkout-outofstock-confirm-button">
          {gettext('TAMAM')}
        </button>
      </div>
    </div>
  );
};

OutOfStock.defaultProps = {
  products: [],
  redirectUrl: '',
};

OutOfStock.propTypes = {
  products: PropTypes.array,
  redirectUrl: PropTypes.string,
};

export default OutOfStock;
