import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToShoppingCartAction, bunsarEventAction, setRevampingProductsLoading, removeFavoriteActionRequest, setRevampingTabProductsAction, setFavouriteProductsAction
} from '@Actions';
import {
  timeframe, logEvent,
} from '@Constants';
import {
  gettext,
} from '@Translate';
import config from '@Config';
import CustomButton from '../../CustomButton';
import ProductDetail from '../../ProductDetail';
import ToastfyConfirm from '../../ToastfyConfirm/ToastfyConfirm';
import { buildEventActionPayload, isValidCredentials } from '../../../utils/BunsarHelpers';
import { addToCartEvent } from '../../../utils/gtmEvents';
import OldProductPrice from './Price';
import CustomDropdown from '../../CustomDropdown';
import API from '@API';
import { ToastfyMessage, EmptyWidgetInfo } from '@Components';
import Cookie from 'js-cookie';
import brandxAnimation from '../../../assets/icons/brandx.json'

const OldProducts = ({ successPage }) => {
  const dispatch = useDispatch();
  const { guest } = useSelector((state) => state.auth);
  const { webview } = useSelector((state) => state.config);
  const { project } = config;
  const { shoppingCart, visitorId, shopperId, revampingTabProducts, favouriteProducts } = useSelector((state) => state.cart);
  const [gtmControl, setGtmControl] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('prevAdd');
  const [recentlyProducts, setRecentlyProducts] = useState([]);
  const [selectedProductSku, setSelectedProductSku] = useState(0);
  // Birden fazla selectbox'ı kontrol etmek için state kullanıyoruz
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [notSelectedSizeForAdd, setNotSelectedSizeForAdd] = useState(false)
  const [openStatus, setOpenStatus] = useState(false);

  const handleUpdate = (quantity, sku, price) => {
    if (project !== 'flo' || webview) return;

    const credentials = shoppingCart?.bunsarEventCredentials;

    if (!isValidCredentials(credentials) || !sku) {
      return;
    }

    const productInfo = [sku, quantity, price].filter(Boolean).join('|');

    if (!productInfo) {
      return;
    }

    const payload = buildEventActionPayload({
      credentials,
      visitorId,
      shopperId,
      eventType: 'Cart.Rec.AddToCart',
      additionalParams: { recType: 'ctc3rd', recProduct: productInfo }
    });

    dispatch(bunsarEventAction(payload));
  };

  const addToCart = async ({
    product, sku, quantity, productIndex
  }) => {
    if (sku > 0){
      setGtmControl(false);
      const productId = sku;
      dispatch(addToShoppingCartAction({
        product, productId, sku, quantity,
      }));
      handleUpdate(quantity, product?.parentSku, product?.price)
      let modifiedProduct = { ...product };
      modifiedProduct.quantity = '1'
      addToCartEvent(modifiedProduct)
      setSelectedProductSku((prevSku) => ({
        ...prevSku,
        [productIndex]: 0,
      }));
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [productIndex]: 0,
      }));
    } else {
      setNotSelectedSizeForAdd(!notSelectedSizeForAdd)
      setOpenIndex(productIndex)
      setOpenStatus(!openStatus)
      //toast.error(ToastfyMessage({ text: gettext('Lütfen beden seçimi yapınız.') }));
    }
  };

  useEffect(async () => {
    if (favouriteProducts?.length < 1){
      const response = await API.getFavouriteProducts()
      dispatch(setFavouriteProductsAction(response.data))
    }
  }, [])

  useEffect(() => {
    if (!gtmControl) {
      setGtmControl(true);
      if (shoppingCart?.shoppingCartItemList?.length > 0 && webview) {
        logEvent('begin_checkout', {
          items: shoppingCart.shoppingCartItemList,
          value: shoppingCart.grandTotal,
          checkout_step: 1,
          currency: 'TRY',
        });
      }
    }
  }, [shoppingCart]);

  const handleProducts = async (activeTab) => {
    if (activeTab === 'prevAdd'){
      return shoppingCart.products
    } else if (activeTab === 'favourites'){
      if (favouriteProducts?.length < 1){
        // TODO: Adının genel olarak değişmesi gerekiyor
        dispatch(setRevampingProductsLoading(true));
        const response = await API.getFavouriteProducts()
        dispatch(setRevampingProductsLoading(false));
        dispatch(setFavouriteProductsAction(response.data))
        return response.data
      }
      return favouriteProducts
    } else if (activeTab === 'lastVisited'){
      if (recentlyProducts?.length < 1){
        const recentlyProductsCookie = Cookie.get('fe_pwProductJourney');
        const formData = new FormData();

        try {
          if (recentlyProductsCookie) {
            // Her bir SKU'yu sku[] key'i ile formData'ya ekle
            await JSON.parse(recentlyProductsCookie).forEach(sku => {
              formData.append('sku[]', sku.c);
            });
        
            dispatch(setRevampingProductsLoading(true));
            const response = await API.getRecentlyProducts(formData)
            dispatch(setRevampingProductsLoading(false));
            setRecentlyProducts(response.data)
            return response.data
          } else {
            dispatch(setRevampingProductsLoading(true));
            setRecentlyProducts([])
            dispatch(setRevampingProductsLoading(false));
            return null
          }
        } catch (error) {
          console.error(error)
          dispatch(setRevampingProductsLoading(false))
          setRecentlyProducts([])
          return null
        }
      }
      return recentlyProducts
    }
  }

  useEffect(() => {
    handleProducts(activeTab).then((response) => {
      dispatch(setRevampingTabProductsAction(response || []))
    })
  }, [activeTab, shoppingCart])

  // TODO : Kontrol Edilecek, burada bir kontrol yapılması dopru değil,
  // bu durum hem performansı etkiler, hemde best practice kullanıma aykırıdır
  if (Object?.values(shoppingCart?.products || [])?.length === 0) {
    return null;
  }

  const openConfirmPopup = async (product) => {
    if (!guest) {
      dispatch(setRevampingProductsLoading(true));
      const response = await dispatch(removeFavoriteActionRequest({ ...product, product, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0 }));
      dispatch(setFavouriteProductsAction(response?.payload?.data))
      dispatch(setRevampingTabProductsAction(response?.payload?.data || []))
      dispatch(setRevampingProductsLoading(false));
    }
  };

  const handleTabChange = (type) => {
    setIsButtonLoading(true)
    setActiveTab(type)
    setSelectedValues({});
    setSelectedProductSku(0)
    setOpenIndex(null);
    setIsButtonLoading(false)
  }

  const emptyWidgetText = () => {
    if (activeTab === 'prevAdd') {
      return 'Sepetini <span>rahat ve hızlı</span> kullanabilmen için sepetinden sildiğin ürünleri burada görebilirsin.'
    }
    if (activeTab === 'favourites') {
      return 'Sepetini <span>rahat ve hızlı</span> kullanabilmen için favorilediğin ürünleri burada görebilirsin.'
    }
    if (activeTab === 'lastVisited') {
      return 'Sepetini <span>rahat ve hızlı</span> kullanabilmen için son gezdiğin ürünleri burada görebilirsin.'
    }
    return null
  }

  return (
    <div className={`prev-add-card__container my-basket ${project}-prev-add-card__container`}>
      <div className='prev-add-card-tabs-wrapper'>
        <div className='prev-add-card-tabs-container'>
          <div className='prev-add-card-tabs'>
            <div className={`prev-add-card-tabs__item ${activeTab === 'prevAdd' ? 'active' : ''}`} onClick={() => handleTabChange('prevAdd')}>{gettext('Önceden Eklediklerim')}</div>
            {shoppingCart.isWidgetBasketFavouritesActive ? (
              <div className={`prev-add-card-tabs__item ${activeTab === 'favourites' ? 'active' : ''}`} onClick={() => handleTabChange('favourites')}>{gettext('Favorilerim')}</div>
            ) : null}
            {shoppingCart.isWidgetBasketRecentlyProductsActive ? (
              <div className={`prev-add-card-tabs__item ${activeTab === 'lastVisited' ? 'active' : ''}`} onClick={() => handleTabChange('lastVisited')}>{gettext('Son Gezdiklerim')}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={revampingTabProducts.length ? "prev-add-card__all-wrapper" : "prev-add-card__all-wrapper with-empty-info"}>
        {revampingTabProducts.length ? (
          revampingTabProducts.map((product, productIndex) => {
            if (!product) {
              return null
            }
            return (
              <div key={String(product.sku)} className=" basket-item tracking init prev-add-card__wrapper">
                <div className="prev-add-card__first-row">
                  <ProductDetail
                    brand={product.name}
                    element={product}
                    deliveryType={product.type}
                    cargoDate={product.merchant?.estimatedDeliveryTimeStart && timeframe(product.merchant?.estimatedDeliveryTimeStart, product.merchant?.estimatedDeliveryTimeEnd)}
                    cargoText={gettext(shoppingCart?.deliveryDateText || orderDetail?.estimatedDateText)}
                    successPage={false}
                    project={project}
                  />
                  <OldProductPrice product={product} project={project} />
                </div>
                <div className={activeTab === 'favourites' ? "prev-add-card__second-row" : "prev-add-card__second-row row-flex-end"}>
                  {/* ----- TODO ---- */}
                  {/* yayina cikarken display none olacak be bitince acilacak */}
                  { activeTab === 'favourites' && !isButtonLoading && (
                    <div className="merchant-product-card__item-delete-wrapper">
                      <span type="button">
                        {!successPage && (
                          <div className="cart__action">
                            <CustomButton
                              className="card__delete-btn"
                              onClick={() => openConfirmPopup(product)}
                              dataTest="open-to-cart-page-delete-product-from-cart-open-modal"
                            >
                              {gettext('Sil')}
                              &nbsp;
                              <i className="icon-bin" />
                            </CustomButton>
                          </div>
                        )}
                      </span>
                    </div>
                  )}
                  {/* Custom Dropdown */}
                  <div className='prev-add-card__second-row-buttons'>
                    <CustomDropdown
                      placeholder={gettext("Beden")}
                      options={product.options}
                      value={selectedValues[productIndex]}
                      isOpened={null}
                      openIndex={openIndex}
                      productIndex={productIndex}
                      openStatus={openStatus}
                      onChange={(size, variantSku) => {
                        setSelectedValues({
                          ...selectedValues,
                          [productIndex]: size, // Her bir selectbox'ın kendi seçimi olacak
                        });
                        setSelectedProductSku({
                          ...selectedProductSku,
                          [productIndex]: variantSku
                        })
                        setOpenIndex(null); // Seçim yapıldığında dropdown'ı kapatıyoruz
                      }}
                    />
                    <CustomButton
                      type="button"
                      project={project}
                      className={`prev-add-card__add-to-cart-btn ${project}-prev-add-card__add-to-cart-btn`}
                      onClick={() => addToCart({
                        product, sku: selectedProductSku[productIndex], quantity: 1, merchantId: (product.merchant?.id || 10000), productIndex
                      })}
                      dataTest="click-to-cart-page-previously-added-to-cart-add-cart"
                    >
                      {gettext('Sepete Ekle')}
                    </CustomButton>
                  </div>
                </div>
              </div>
            )
          })
        ) : <EmptyWidgetInfo icon={brandxAnimation} text={emptyWidgetText()} />}
      </div>
    </div>
  );
};

export default OldProducts;
