import Cookie from 'js-cookie';
import { logoutAction } from '@Actions';
import store from '@Store';
import HeaderHandler from '../../utils/HeaderHandler';

let isLoggingOut = false;

export const isHandlerEnabled = (conf = {}) => !(
  conf.hasOwnProperty('handlerEnabled') && !conf.handlerEnabled
);

// loadingActionları store üzerinden yönettiğimiz için burada ihtiyacımız olmuyor

export const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    //store.dispatch(showLoadingAction(true));
  }
  request.headers = {
    ...request.headers,
    ...HeaderHandler(),
  };
  return request;

};

export const paymentUrlRequestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    //store.dispatch(showLoadingAction(true));
  }

  let baseURL = '';
  baseURL = store.getState().dynamicBaseUrl
  const validBaseUrl = baseURL.url
  

  request.headers = {
    ...request.headers,
    ...HeaderHandler(),
  };
 
  try {
    if (validBaseUrl !== '') {
      request.baseURL = validBaseUrll;
    }
  } catch (error) {
    console.error('Bir hata oluştu:', error);
  }
 

  return request;

}


export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Handle responses
    //store.dispatch(hideLoadingAction());

    /* TODO:
      isLoggingOut kontrolü payment refactor sonrası kaldırılacak.
      Payment sayfasında sepette ürün olmasada servislere istek atılıyor. İstek tekrarını önlemek için bu kontrol eklendi. YC.
    */
      if (response?.data?.status?.code === 2072 && !isLoggingOut && process.env.NODE_ENV !== "development" ) {
        store.dispatch(logoutAction())
    }
  }
  return response;
};


export const errorHandler = (error) => {
  if (isHandlerEnabled(error?.config)) {
    //store.dispatch(hideLoadingAction());
    // You can decide what you need to do to handle errors.
    // here's example for unautherized user to log them out .
    // error.response.status === 401 && Auth.signOut();
    const message = {
      event: 'consoleErrorHandler',
      eventName: 'consoleErrorHandler',
      headers: error?.config?.headers,
      method: error?.config?.method,
      'url: ': error?.config?.url,
      'status: ': error?.response?.status,
      'message: ': error?.response?.statusText,
    };

    if (Cookie.get('webview')) {
      logEvent('consoleErrorHandler', message);
    } else {
      dataLayer.push(message);
    }
  }
  return Promise.reject({ ...error });
};
