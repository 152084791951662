import { setCurrentLang, getCurrentLang } from './lang';
import {
  setAuthToken,
  deleteAuthToken,
  getAuthToken,
  setGuest,
  isGuestAction,
  isPhoneLoginAction,
  loginAction,
  setUserInformation,
  registerAction,
  questAction,
  facebookConnectAction,
  getCustomerAction,
  signInWithGoogleAction,
  signInWithAppleAction,
  forgotPasswordAction,
  phoneLoginAction,
  logoutAction,
} from './auth';
import { setLoader } from './loader';
import { showSnackbarAction, hideSnackbarAction } from './snackbar';
import { showLoadingAction, hideLoadingAction } from './loading';
import {
  cartActionRequest,
  useLoyaltyAction,
  applyBonusAction,
  updateCartItemActionRequest,
  removeCartItemActionRequest,
  getCouponsAction,
  setCouponsAction,
  applyCouponAction,
  removeCouponAction,
  setBasketItemLimitAction,
  setBasketInfo,
  setBunsarStoreAction,
  setFavouriteProductsAction,
  setRevampingTabProductsAction,
  cartActionReceive,
  addToShoppingCartAction,
  addFavoriteActionRequest,
  removeFavoriteActionRequest,
  bunsarEventAction
} from './cart';
import {
  deliveryOptionsRequest,
  orderAddressesActionReceive,
  orderSameAddressBillingAction,
  updateCustomerAddressAction,
  getOrderSummaryAction,
  getCitiesAction,
  getCitiesReceiveAction,
  getCountryAction,
  getCountiesAction,
  getNeighbourhoodAction,
  getCountiesReceiveAction,
  insertCustomerAddressAction,
  customerDefaultShippingAddressActionReceive,
  customerDefaultBillingAddressActionReceive,
  setCustomerAddressAction,
  setUseShippingAddressAsBillingAddressAction,
  setCreditCardAction,
  getCreditCardAction,
  resetInstallmentsAction,
  setInstallmentAction,
  getInstallmentsAction,
  getAllInstallmentsAction,
  setPlatformAction,
  getPlatformAction,
  getSavedCardsAction,
  setSavedCardsAction,
  setCreditCartIdAction,
  getCreditCartIdAction,
  setCreditCartPlatformAction,
  getCreditCartPlatformAction,
  setNumberOfInstallmentsAction,
  setAxessAction,
  getNumberOfInstallmentsAction,
  orderCompleteAction,
  getInitializeAction,
  setPickupEnabledStoresAction,
  setPickupCitiesAction,
  setPickupWhoWillTakeAction,
  setPaymentMethodsAction,
  setDeliveryOptionChanged,
  setAddressLoadedAction,
  setOrderSummaryAction,
  setinstallmentsUrlAction,
  setPaymentMethodAction,
  setDijitalPaymentMethodAction,
  setGarantiPayMethodAction,
  set3DPopupAction,
  set3DPopupCloseAction,
  set3DInfoAction,
  setAddressFieldValueAction,
  setDeliveryOptionAction,
  setCustomerInfoAction,
  resetShippingAddressAction,
  resetNeighbourhoodAction,
  resetCountysAction,
  resetCitiesAction,
  setPickupTakeItMyself,
  setPickupInStoreEnabledAction,
  setOrderDetailAction,
  getOrderDetailAction,
  sendActivationSmsAction,
  setActivateCardAction,
  checkActivationSmsAction,
  getAgreementsAction,
  setAgreementsAction,
  isOrderSuccessAction,
  resetPaymentStepAction,
  getSummoningShoppingCartIdAction,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  setDefaultPickupBillingAddress,
  insertPickupBillingAddressAction,
  getCustomerRequest,
  setPickupOwnFirstName,
  setPickupOwnCustomerId,
  setPickupOwnStoreName,
  setPickupOwnLastName,
  setPickupOwnPhone,
  setPickupCities,
  setPickupDistricts,
  setSelectedPickupCity,
  setSelectedPickupDistrict,
  setSelectedPickupStores,
  setSelectedPickupStoreId,
  resetAddress,
  resetThreeDInfo,
  setUseBonusAction,
  sendActivationOtpOrderAction,
  validateActivationOtpOrderAction,
  setWalletOtpStepAction,
  setCreditCartNumber,
  setCreditCartName,
  setExpirationMonth,
  setExpirationYear,
  setCvcNumber,
  setCodeAction,
  setSavedCards,
  setOtpStep,
  resetBillingAddressAction,
  resetCustomerSelections,
  setAgreementCheck,
  setLoyaltyCheck,
  setfloWalletCardActiveAction,
  setSavedCardStep,
  setHepsipayAlreadyLinked,
  setHepsiPaySavedCardsAction,
  setHepsipayLinkAccountLoading,
  setRevampingProductsLoading,
  getChequeAction,
} from './order';
import {
  showPopupAction,
  hidePopupAction,
  isTopPopupAction,
  isDisasterPopupAction,
} from './popup';
import {
  showFModalAction,
  hideFModalAction,
} from './fModal';
import {
  cardNumberIsValidAction,
  cardCVVNumberIsValidAction,
  cardMonthIsValidAction,
  cardYearIsValidAction,
  cardValidationPopupAction,
  walletBalanceAction,
  headerValidationCardAction,
  headerValidationWithMonthAction,
  headerValidationWithCVVAction,
  headerValidationWithYearAction,
  isPaymentWithCardAction,
  bonusBalanceAction,
  bonusUsageAction,
  checkboxValidationAction,
} from './cardValidation';
import {
  showOtpAction,
} from './login';
import {
  visibleBackButtonAction,
  visibleCloseButtonAction,
  setDisasterAction,
} from './config';
import {
  setStepAction,
} from './checkout';

import {
  getBagsAction,
  createQrCodeAction,
  cancelOrderAction,
  qrCodeCheckAction,
  activeQrCodeAction,
  setSelfCheckoutAction,
  setOrderTimeOutAction,
  setStoreOrderDetailAction,
} from './storeBasket';

import {
  getBalanceAction,
  sendActivationOtpAction,
  validateActivationOtpAction,
  getSavedCardsWalletAction,
  getRefundToCardAction,
  walletTopupAction,
  setCreditCartId,
  setCreditCardPlatform,
  setWalletAction,
  set3DPopupWalletAction,
  close3DPopupWalletAction,
  setTelephoneWalletAction,
  setCodeWalletAction,
  setFastpriceFreetext,
  getQrAction,
  checkQrAction,
  rejectQrPaymentAction,
  checkQrComplateAction,
  getInitializeWalletAction,
  getQrPaymentDetailAction,
  approveQrPaymentAction,
  setUseBonusQrAction,
  setIsPayment,
  isBackActiveAction,
  isBarcodeStepAction,
  setBarcodeTime,
  setBarcodeCode,
} from './wallet';
import {
  goToPage,
} from './redirect';

import { headerActiveAction, paymenHeaderActiveAction } from './headerActive';
import { setCCIsActiveAction } from './CCActive';
import { setPrevPathAction } from './navigation';
import { setIsMailVerifiedAction } from './otp';
import { setDynamicBaseUrlAction } from './dynamicBaseUrl';

export {
  setCurrentLang,
  getCurrentLang,
  setAuthToken,
  setGuest,
  isGuestAction,
  isPhoneLoginAction,
  deleteAuthToken,
  getAuthToken,
  setLoader,
  showSnackbarAction,
  hideSnackbarAction,
  cartActionRequest,
  useLoyaltyAction,
  applyBonusAction,
  updateCartItemActionRequest,
  loginAction,
  deliveryOptionsRequest,
  orderAddressesActionReceive,
  orderSameAddressBillingAction,
  showPopupAction,
  isTopPopupAction,
  hidePopupAction,
  showFModalAction,
  hideFModalAction,
  updateCustomerAddressAction,
  getOrderSummaryAction,
  getCitiesAction,
  getCitiesReceiveAction,
  getCountryAction,
  getCountiesAction,
  getNeighbourhoodAction,
  getCountiesReceiveAction,
  insertCustomerAddressAction,
  customerDefaultShippingAddressActionReceive,
  customerDefaultBillingAddressActionReceive,
  setCustomerAddressAction,
  setUseShippingAddressAsBillingAddressAction,
  setCreditCardAction,
  getCreditCardAction,
  resetInstallmentsAction,
  setInstallmentAction,
  getInstallmentsAction,
  getAllInstallmentsAction,
  setPlatformAction,
  getPlatformAction,
  getSavedCardsAction,
  setSavedCardsAction,
  setCreditCartIdAction,
  getCreditCartIdAction,
  setCreditCartPlatformAction,
  getCreditCartPlatformAction,
  setNumberOfInstallmentsAction,
  setAxessAction,
  getNumberOfInstallmentsAction,
  orderCompleteAction,
  removeCartItemActionRequest,
  getInitializeAction,
  setPickupEnabledStoresAction,
  setPickupCitiesAction,
  setUserInformation,
  setIsMailVerifiedAction,
  setPickupWhoWillTakeAction,
  setPaymentMethodsAction,
  setDeliveryOptionChanged,
  setOrderSummaryAction,
  setAddressLoadedAction,
  setinstallmentsUrlAction,
  setPaymentMethodAction,
  setDijitalPaymentMethodAction,
  setGarantiPayMethodAction,
  set3DPopupAction,
  set3DPopupCloseAction,
  setUseBonusQrAction,
  setIsPayment,
  checkQrComplateAction,
  getInitializeWalletAction,
  isBackActiveAction,
  isBarcodeStepAction,
  set3DInfoAction,
  setBarcodeTime,
  getSummoningShoppingCartIdAction,
  setAddressFieldValueAction,
  setDeliveryOptionAction,
  setCustomerInfoAction,
  resetBillingAddressAction,
  resetShippingAddressAction,
  resetNeighbourhoodAction,
  resetCountysAction,
  resetCitiesAction,
  showLoadingAction,
  hideLoadingAction,
  setPickupTakeItMyself,
  getCouponsAction,
  setCouponsAction,
  setFavouriteProductsAction,
  setRevampingTabProductsAction,
  applyCouponAction,
  removeCouponAction,
  setOrderDetailAction,
  getOrderDetailAction,
  registerAction,
  questAction,
  getAgreementsAction,
  sendActivationSmsAction,
  setActivateCardAction,
  checkActivationSmsAction,
  setStepAction,
  setAgreementsAction,
  isOrderSuccessAction,
  resetPaymentStepAction,
  setPickupInStoreEnabledAction,
  setBasketItemLimitAction,
  setBasketInfo,
  cartActionReceive,
  visibleBackButtonAction,
  visibleCloseButtonAction,
  facebookConnectAction,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  setDefaultPickupBillingAddress,
  insertPickupBillingAddressAction,
  getCustomerRequest,
  addToShoppingCartAction,
  addFavoriteActionRequest,
  removeFavoriteActionRequest,
  setPickupOwnFirstName,
  setPickupOwnCustomerId,
  setPickupOwnStoreName,
  setPickupOwnLastName,
  setPickupOwnPhone,
  setPickupCities,
  setPickupDistricts,
  setSelectedPickupCity,
  setSelectedPickupDistrict,
  setSelectedPickupStores,
  setSelectedPickupStoreId,
  resetAddress,
  getCustomerAction,
  sendActivationOtpAction,
  validateActivationOtpAction,
  getQrAction,
  approveQrPaymentAction,
  getBalanceAction,
  setBarcodeCode,
  getSavedCardsWalletAction,
  getRefundToCardAction,
  resetThreeDInfo,
  setUseBonusAction,
  walletTopupAction,
  getChequeAction,
  setCreditCartId,
  setCreditCardPlatform,
  setWalletAction,
  set3DPopupWalletAction,
  close3DPopupWalletAction,
  goToPage,
  signInWithGoogleAction,
  logoutAction,
  signInWithAppleAction,
  sendActivationOtpOrderAction,
  validateActivationOtpOrderAction,
  setWalletOtpStepAction,
  checkQrAction,
  getQrPaymentDetailAction,
  rejectQrPaymentAction,
  setCreditCartNumber,
  setCreditCartName,
  setExpirationMonth,
  setExpirationYear,
  setCvcNumber,
  setTelephoneWalletAction,
  setCodeWalletAction,
  setCodeAction,
  setFastpriceFreetext,
  setSavedCards,
  setOtpStep,
  resetCustomerSelections,
  showOtpAction,
  forgotPasswordAction,
  phoneLoginAction,
  cardNumberIsValidAction,
  cardCVVNumberIsValidAction,
  cardMonthIsValidAction,
  cardYearIsValidAction,
  cardValidationPopupAction,
  walletBalanceAction,
  headerValidationCardAction,
  headerValidationWithMonthAction,
  headerValidationWithCVVAction,
  headerValidationWithYearAction,
  setAgreementCheck,
  setLoyaltyCheck,
  headerActiveAction,
  paymenHeaderActiveAction,
  bonusBalanceAction,
  bonusUsageAction,
  checkboxValidationAction,
  setCCIsActiveAction,
  setfloWalletCardActiveAction,
  setPrevPathAction,
  setSelfCheckoutAction,
  setOrderTimeOutAction,
  getBagsAction,
  createQrCodeAction,
  cancelOrderAction,
  setStoreOrderDetailAction,
  qrCodeCheckAction,
  activeQrCodeAction,
  setDisasterAction,
  isDisasterPopupAction,
  isPaymentWithCardAction,
  bunsarEventAction,
  setBunsarStoreAction,
  setSavedCardStep,
  setDynamicBaseUrlAction,
  setHepsipayAlreadyLinked,
  setHepsiPaySavedCardsAction,
  setHepsipayLinkAccountLoading,
  setRevampingProductsLoading,
};
