import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setPaymentMethodAction,
} from '@Actions';

import PaymentMethodItem from '../PaymentMethodItem'

const SubPaymentOptions = ({subPaymentMethods}) => {
  const dispatch = useDispatch();

  const onChangePaymentMethod = async (subPaymentMethodItem) => {
    await dispatch(setPaymentMethodAction(subPaymentMethodItem?.code));
  };


  return (
    <div data-widget="checkout/other_payment" className="widget widget_checkout_other_payment">
      <form id="other-payment" className="other-payment">
        <>
          <div className="dijital-payment__select">
            {subPaymentMethods?.map((subPaymentMethodItem, index) => (
              <PaymentMethodItem
                key={`other-payment-${index.toString()}`}
                subPaymentMethodItem={subPaymentMethodItem}
                onChangePaymentMethod={onChangePaymentMethod}
              />
            ))}
          </div>
        </>
      </form>
    </div>
  );
};

export default SubPaymentOptions
