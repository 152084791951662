import * as React from 'react'
import { memo } from 'react'
const SvgDiscountCoupon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 45.009 45'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#f60'
      fillRule='evenodd'
      d='m39.726 16.651 1.474 1.476a6.21 6.21 0 0 1 .041 8.715l-.041.041-.02.02-1.454 1.456a3.07 3.07 0 0 0-.9 2.176v2.11a6.18 6.18 0 0 1-6.176 6.174h-2.113a3.07 3.07 0 0 0-2.174.9l-1.477 1.48a6.14 6.14 0 0 1-4.369 1.8 6.21 6.21 0 0 1-4.369-1.782l-1.5-1.5a3.07 3.07 0 0 0-2.174-.9h-2.111a6.18 6.18 0 0 1-6.174-6.174v-2.11a3.16 3.16 0 0 0-.9-2.2l-1.479-1.45a6.2 6.2 0 0 1-.021-8.733l1.5-1.5a3.08 3.08 0 0 0 .9-2.194v-2.09a6.177 6.177 0 0 1 6.174-6.167h2.113a3.08 3.08 0 0 0 2.174-.9l1.477-1.476a6.187 6.187 0 0 1 8.738-.024l1.5 1.495a3.08 3.08 0 0 0 2.174.9h2.111a6.18 6.18 0 0 1 6.174 6.17v2.114a3.07 3.07 0 0 0 .902 2.173M17.225 29.569a1.72 1.72 0 0 0 1.272-.533L29.04 18.499a1.81 1.81 0 0 0 0-2.545 1.787 1.787 0 0 0-2.523 0L15.974 26.493a1.807 1.807 0 0 0 0 2.543 1.7 1.7 0 0 0 1.251.533m8.758-1.8a1.795 1.795 0 1 0 1.805-1.784 1.794 1.794 0 0 0-1.805 1.78Zm-8.738-12.328a1.794 1.794 0 0 1 0 3.588 1.794 1.794 0 1 1 0-3.588'
    />
  </svg>
)
const Memo = memo(SvgDiscountCoupon)
export default Memo
