import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import config from '@Config';

// API
import API from '@API';
import {
  setSavedCardsAction,
  getSavedCardsAction,
  setDynamicBaseUrlAction
} from '@Actions';

import { SimpleCreditCard, Delete } from '@Icons';
import { gettext } from '@Translate';
import { toast } from 'react-toastify';
import CustomPopover from '../../CustomPopover';
import ToastfyMessage from '../../ToastfyMessage/ToastfyMessage';
import CustomButton from '../../CustomButton';

import masterLogo from '../../../assets/images/Mastercard-logo.svg';
import visaLogo from '../../../assets/images/visa-logo.png';
import troyLogo from '../../../assets/images/troy-logo.png';
import defaultLogo from '../../../assets/images/default-cart-type.png';

const SavedCard = ({ item }) => {
  const dispatch = useDispatch();
  const { BASE_URL, project } = config;
  const { card_image } = item;
  const [popoverClose, setPopoverClose] = useState();
  const [defaultCard, setDefaultCard] = useState(item?.isDefault);

  let cardTypeLogo = defaultLogo;

  if (item?.cardAssociation === 'VISA') {
    cardTypeLogo = visaLogo;
  } else if (item?.cardAssociation === 'MASTER_CARD') {
    cardTypeLogo = masterLogo;
  } else if (item?.cardAssociation === 'TROY') {
    cardTypeLogo = troyLogo;
  }

  useEffect(() => {
    setDefaultCard(item?.isDefault);
  }, [item?.isDefault]);

  const handleCardDelete = (id, number) => {
    toast.error(ToastfyMessage({
      text: `${number} ${gettext('numaralı kayıtlı kartınızı silmek istediğinizden emin misiniz?')}`,
      type: 'credit-card-delete',
      callback: async () => {
        toast.dismiss();
      },
      confirm: async () => {
        toast.dismiss();
        const data = {
          id,
        };
        await API.deleteCreditCard(data)
          .then((response) => {
            if (response?.data?.status?.code === 200) {
              dispatch(setSavedCardsAction(response?.data?.savedCardList));
              setPopoverClose(true);
              setPopoverClose('');
            }
          }).catch((err) => console.log(err));
      },
    }), {
      autoClose: false,
      closeButton: false,
    });
  };
  const handleCardDefault = async (cardId) => {
    if (cardId) {
      const data = {
        cardId,
      };
      await API.defaultCreditCard(data)
        .then((response) => {
          if (response?.data?.status?.code === 200) {
            dispatch(getSavedCardsAction()).then(({ payload }) => {
              const { savedCardList, status, checkoutBEBaseUrl } = payload;
              if (status?.code === 200) {
                dispatch(setDynamicBaseUrlAction(checkoutBEBaseUrl));
                dispatch(setSavedCardsAction(savedCardList));
                setPopoverClose(true);
                setPopoverClose('');
              }
            });
          }
        }).catch((err) => console.log(err));
    }
  };
  return (
    <div className={`saved-cards__item ${defaultCard ? 'saved-cards__item--default' : ''} ${project}`}>
      <CustomPopover rootClassName="saved-cards__popover" triggerClassName="saved-cards__trigger" contentClassName="saved-cards__content" close={popoverClose}>
        {!defaultCard && (
          <CustomButton className="saved-cards__action" onClick={() => handleCardDefault(item?.id)}>
            <SimpleCreditCard />
            <span>{gettext('Varsayılan kartım yap')}</span>
          </CustomButton>
        )}
        <CustomButton className="saved-cards__action" onClick={() => handleCardDelete(item?.id, item?.number)}>
          <Delete />
          <span>{gettext('Sil')}</span>
        </CustomButton>
      </CustomPopover>
      <picture className="saved-cards__banks">
        <img src={`${BASE_URL}${card_image}`} />
      </picture>
      <div className="saved-cards__footer">
        <picture className="saved-cards__type">
          <img src={`${cardTypeLogo}`} />
        </picture>
        <div className="saved-cards__number">{item?.number}</div>
      </div>
    </div>
  );
};

export default SavedCard;
