import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  addFavoriteActionRequest,
  updateCartItemActionRequest,
  removeCartItemActionRequest,
  bunsarEventAction,
  setFavouriteProductsAction
} from '@Actions';
import {
  toMoney,
  timeframe,
  getItems,
  isMobile,
} from '@Constants';
import {
  gettext,
} from '@Translate';
import config from '@Config';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import API from '@API';
import { buildEventActionPayload, isValidCredentials} from '../../utils/BunsarHelpers';
import { addToCartEvent, removeFromCartEvent } from '../../utils/gtmEvents';
// Components
import ToastfyConfirm from '../ToastfyConfirm/ToastfyConfirm';
import RenderSwitch from '../RenderSwitch';
import CustomButton from '../CustomButton';
import SizeWrapper from '../Basket/SizeWrapper';
import CustomBadge from '../CustomBadge';
import ProductDetail from '../ProductDetail';

const MerchantProductCard = ({ products, successPage, openModal, pageType }) => {
  const { project } = config;
  const { webview } = useSelector((state) => state.config);

  const dispatch = useDispatch();
  const [items, setItems] = useState({});

  const [countdown, setCountdown] = useState();
  const { guest } = useSelector((state) => state.auth);
  const { shoppingCart, visitorId, shopperId, favouriteProducts } = useSelector((state) => state.cart);
  const {
    orderDetail,
  } = useSelector((state) => state.order);

  const updateCartItem = async ({
    quantity, sku, itemId, oldQuantity, stock_source_id, delivery_type, price, parentSku
  }) => {
    await dispatch(updateCartItemActionRequest({
      quantity, sku, itemId, oldQuantity, stock_source_id, delivery_type, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0
    }));
    handleUpdate(
      quantity, parentSku, price
    );
  };

  const handleUpdate = (quantity, sku, price) => {
    if (project !== 'flo' || webview) return;

    const credentials = shoppingCart?.bunsarEventCredentials;

    if (!isValidCredentials(credentials) || !sku ) {
        return;
    }

    const productInfo = [sku, quantity, price].filter(Boolean).join('|');

    if (!productInfo) {
        return;
    }

    const payload = buildEventActionPayload({
      credentials,
      visitorId,
      shopperId,
      eventType: "Cart.UpdateItem",
      additionalParams: { product: productInfo }
    });

    dispatch(bunsarEventAction(payload));
  };

  const handleQuantity = (type, element) => {
    const pro = products?.find((item) => item?.sku === element?.sku);

    const data = {
      quantity: (type === 'dec' ? (element?.quantity - 1) : (element?.quantity + 1)),
      sku: element?.sku,
      itemId: element?.itemId,
      oldQuantity: pro?.quantity,
      price: element?.price,
      parentSku: pro?.parentSku
    };
    if (element?.stockSourceId > 0) {
      data.stock_source_id = element?.stockSourceId;
    }
    if (element.type) {
      data.delivery_type = element?.type;
    }
    updateCartItem(data);

    let modifiedProduct = { ...pro };
    modifiedProduct.quantity = '1'
    if (type === 'inc') {
      addToCartEvent(modifiedProduct) 
    } else {
      removeFromCartEvent(modifiedProduct)
    }
  };

  const urlLocation = useLocation();

  useEffect(() => {
    /* --------------------- // Yeni tasarımda Merchant a göre filtreleme yapılması istenmiştir, tasarımda bir değişiklilk yapılmazsa filtrelemede bir değişiklik yapılmaması gerekiyor. BÖ // ------------------- */
    const productsFilter = getItems(products?.filter((item) => item?.brand), ['merchant', 'id']);
    setItems(productsFilter);
  }, [products]);

  useEffect(() => {
    setCountdown(shoppingCart?.shoppingCartItemList?.find((item) => item?.type === 'SDD')?.countdown);
    const intervalCountdown = setInterval(() => {
      setCountdown((prev) => {
        if (isNaN(prev)) return prev;
        const newCount = prev - 1;
        if (newCount < 1) {
          clearInterval(intervalCountdown);
        }
        return newCount;
      });
    }, 1000);
    return () => { clearInterval(intervalCountdown); };
  }, [shoppingCart]);

  // TODO son aşamada kontrol edilip silinecek bunun yerine handleQuantity fonsiyonu çalışıyor

  // const onChange = (event, element) => {
  //   debugger;
  //   const pro = shoppingCart.shoppingCartItemList?.find((item) => item.sku === element.sku);
  //   const data = {
  //     quantity: event.target.value,
  //     sku: element.sku,
  //     itemId: element.itemId,
  //     oldQuantity: pro.quantity,
  //   };
  //   if (element.stockSourceId > 0) {
  //     data.stock_source_id = element.stockSourceId;
  //   }
  //   if (element.type) {
  //     data.delivery_type = element.type;
  //   }
  //   updateCartItem(data);
  // };


  const handleBunsarRemoveActions = (product) => {
    if (project !== 'flo' || webview) return;

    const credentials = shoppingCart?.bunsarEventCredentials;

    if (!isValidCredentials(credentials)|| !product?.parentSku) {
        return;
    }

    const payload = buildEventActionPayload({
      credentials,
      visitorId,
      shopperId,
      eventType: "Cart.RemoveItem",
      additionalParams: { product: product?.parentSku }
    });

    dispatch(bunsarEventAction(payload));
  };

  const openConfirmPopup = async (product) => {
    const hasProductInFavourites = favouriteProducts?.some(item => item.productId === product.productId) ?? false;
    if (guest || hasProductInFavourites) {
      await dispatch(removeCartItemActionRequest({ ...product, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0 }));
      handleBunsarRemoveActions(product)
      removeFromCartEvent(product)
    } else {
      toast.error(ToastfyConfirm({
        callback: async () => {
          toast.dismiss();
          await dispatch(removeCartItemActionRequest({ ...product, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0 }));
          handleBunsarRemoveActions(product)
          removeFromCartEvent(product)
        },
        confirm: async () => {
          toast.dismiss();
          await dispatch(addFavoriteActionRequest({ ...product, getRevampingCoupons: shoppingCart?.revampingCoupons?.length ? 1 : 0 }));
          const response = await API.getFavouriteProducts()
          await dispatch(setFavouriteProductsAction(response.data))
          handleBunsarRemoveActions(product)
          removeFromCartEvent(product)
        },
      }), { autoClose: 15000 });
    }
  };

  // TODO kontrol edilecek - başka bir yerde kullanlmıyor ise tamamen silinicek
  // const sendLongEvent = (element) => {
  //   if (webview) {
  //     logEvent('select_item', {
  //       ...element,
  //       value: shoppingCart.grandTotal,
  //       currency: 'TRY'
  //     });
  //     window.location.href = `${appDeepLink}product.detail?sku=${element.parentSku}`;
  //   }
  // }

  const handleDeliveryTimeDate = (element) => {
    // eslint-disable-next-line no-unused-vars
    let timeText = '';
    if (element?.type === 'SDD' && countdown < 1) {
      timeText = element?.merchant?.estimatedDeliveryTimeTextAfterTitle;
    } else if (element?.type === 'SD' || element?.type === 'CC') {
      if (urlLocation?.pathname === '/success' && (project === 'flo' || project === 'instreet')) {
        timeText = timeframe(element?.merchant.estimatedDeliveryDate, element?.merchant?.estimatedDeliveryDate);
      } else {
        timeText = timeframe(element?.merchant?.estimatedDeliveryTimeStart, element?.merchant?.estimatedDeliveryTimeEnd);
      }
    } else {
      timeText = element?.merchant?.estimatedDeliveryTimeText;
    }
    return timeText;
  };

  const handleDeliveryTimeText = (element) => {
    // eslint-disable-next-line no-unused-vars
    let timeText = '';
    if (urlLocation?.pathname === '/success' && (project === 'flo' || project === 'instreet')) {
      timeText = gettext(element?.merchant?.estimatedDeliveryLabelText);
    } else {
      timeText = gettext(shoppingCart?.deliveryDateText || orderDetail?.estimatedDateText);
    }
    return timeText;
  };

  return (
    <>
      {Object?.keys(items)?.length > 0 && Object?.values(items)?.map((elements, index) => (
        <div className={`merchant-product-card--container ${project}-merchant-product-card--container`} key={`elements-${index?.toString()}`}>
          <div className="merchant-product-card__wrapper" key={index?.toString()}>
            {elements?.map((element, i) => (
              <div className="merchant-product-card__item" key={String(element?.sku)}>
                {/* {i === 0 && project === 'flo' && element.type === 'BC' && (
                    <div className="merchant-product-card__header">
                      <div className="merchant-product-card__header--merchant-wrapper">
                        <div className="cart__header-link d-flex align-items-center">
                          <i className="cart__header-icon icon-info" />
                            <span className="ml-1 mr-1">
                              {gettext('Bu ürünler')}
                                <span className="cart__label--live">
                                  {' '}
                                  {element?.bcInfo?.store_name}
                                  {' '}
                                  {gettext('Mağazasında')}
                                  {' '}
                                </span>
                                {gettext('teslim edilecektir.')}
                              </span>
                            </div>
                            <CustomBadge className="" rate={true}>{element.merchant.merchantRate}</CustomBadge>
                          </div>
                          <div className="merchant-product-card__header--shipping-wrapper">
                          <div className="merchant-product-card__header--shipping-wrapper-info">
                            <strong>
                              <span className="icon icon-express-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span></span>
                              <span> {element?.title}</span>
                            </strong>
                            <span className="cart__label--live ml-2">{element.type === 'SDD' ? '+' : ''} {element?.shippingFee > 0 ? toMoney(element?.shippingFee) : gettext('ÜCRETSİZ') }</span>
                          </div>
                        </div>
                      </div>
                  )}
                  {i === 0 && project === 'flo' && element.type === 'SDD' && (
                    <div className="merchant-product-card__header">
                      <div className="merchant-product-card__header--merchant-wrapper">
                      <div className="cart__header-link d-flex align-items-center">
                        <i className="cart__header-icon icon-info" />
                        <span className="ml-1 mr-1">
                          {gettext('Bu ürün')}
                          <span className="cart__label--live">
                            {' '}
                              {countdown < 1 ? element?.afterTitle ? element?.afterTitle : element?.title : element?.title}
                            {' '}
                          </span>
                          {gettext(' ile adresinize teslim edilecektir.')}
                        </span>
                      </div>
                      <CustomBadge className="" rate={true}>{element.merchant.merchantRate}</CustomBadge>
                      </div>
                      <div className="merchant-product-card__header--shipping-wrapper">
                        <div className="merchant-product-card__header--shipping-wrapper-info">
                          <strong>
                        <span className="icon icon-truck-icon"></span>
                            <span> {countdown < 1 ? element?.afterTitle ? element?.afterTitle : element?.title : element?.title}</span>
                          </strong>
                          <span className="cart__label--live ml-2">{element.type === 'SDD' ? '+' : ''} {element?.shippingFee > 0 ? toMoney(element?.shippingFee) : gettext('ÜCRETSİZ') }</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {i === 0 && project !== 'flo' && project !== 'instreet' && (
                    <div className="merchant-product-card__header">
                      <div className="merchant-product-card__header--merchant-wrapper">
                      <a href={element?.merchant?.seo || '#'} className="cart__header-link d-flex align-items-center">
                        <i className="cart__header-icon icon-info" />
                        <span className="ml-1 mr-1">
                          {gettext('Ürünler ')}
                        </span>
                      </a>
                      <CustomBadge className="" rate={true}>{element.merchant.merchantRate}</CustomBadge>
                      </div>
                      <RenderSwitch
                        status={getMerchantIsShow(elements)}
                        total={getTotalPriceForMerchant(elements)}
                        limit={Number(getFreeShippingLimit(elements))}
                        price={getPriceForShippingLimit(elements)}
                        scale={75}
                      />
                    </div>
                  )}
                  {i === 0 && urlLocation.pathname === '/success' && (project === 'flo' || project === 'instreet') && element?.merchant?.id === 10000 && (element.type === 'SD' || element.type === 'CC') ? (
                    <div className="merchant-product-card__header">
                      <div className="merchant-product-card__header--merchant-wrapper">
                      <a href={element?.merchant?.seo || '#'} className="cart__header-link d-flex align-items-center">
                        <i className="cart__header-icon icon-info" />
                        <span className="ml-1 mr-1">
                          {gettext('Bu Ürünler ')}
                          <span className="cart__label--live">
                            {' '}
                            {element?.merchant?.warehouseTitle}
                            {' '}
                          </span>
                          {gettext(' tarafından gönderilecektir.')}
                        </span>
                      </a>
                      <CustomBadge className="" rate={true}>{element.merchant.merchantRate}</CustomBadge>
                      </div>
                      <RenderSwitch
                        status={getMerchantIsShow(elements)}
                        total={getTotalPriceForMerchant(elements)}
                        limit={Number(getFreeShippingLimit(elements))}
                        price={getPriceForShippingLimit(elements)}
                        scale={75}
                      />
                    </div>
                  ) : (
                    <div className="merchant-product-card__header">
                      <div className="merchant-product-card__header--merchant-wrapper">
                      <a href={element?.merchant?.seo || '#'} className="cart__header-link d-flex align-items-center">
                        <i className="cart__header-icon icon-info" />
                        <span className="ml-1 mr-1">
                          {gettext('Satıcı: ')}
                          <span className="cart__label--live">
                            {' '}
                            {element?.merchant?.name}
                            {' '}
                          </span>
                        </span>
                      </a>
                      <CustomBadge className="" rate={true}>{element.merchant.merchantRate}</CustomBadge>
                      </div>
                      <RenderSwitch
                        status={getMerchantIsShow(elements)}
                        total={getTotalPriceForMerchant(elements)}
                        limit={Number(getFreeShippingLimit(elements))}
                        price={getPriceForShippingLimit(elements)}
                        scale={75}
                      />
                    </div>
                  )} */}
                { i === 0
                          && (
                          <>
                            <div className="merchant-product-card__header">
                              <div className="merchant-product-card__header--merchant-wrapper">
                                {
                                  project === 'flo'
                                    ? (
                                      <div className="merchant-product-card__header--merchant-wrapper-info">
                                        <div className="cart__header-link d-flex align-items-center vendor">
                                          <i className="cart__header-icon icon-info" />
                                          <span className="ml-1 mr-1">
                                          { pageType === 'self_checkout' ? (
                                            <>
                                              {shoppingCart?.storeName && gettext('Mağaza: ')}
                                              <span className="cart__label--live">
                                                {' '}
                                                {shoppingCart?.storeName}
                                                {' '}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {element?.merchant?.name && gettext('Satıcı: ')}
                                              <span className="cart__label--live">
                                                {' '}
                                                {element?.merchant?.name}
                                                {' '}
                                              </span>
                                            </>
                                          )}
                                          </span>
                                        </div>
                                        { pageType !== 'self_checkout' &&
                                          <CustomBadge className="" rate>{element?.merchant?.merchantRate}</CustomBadge>
                                        }
                                      </div>
                                    )
                                    : (
                                      <span className="ml-1 mr-1">
                                        {pageType !== 'self_checkout' && gettext('Ürünler')}
                                      </span>
                                    )
                                }
                              </div>
                              { pageType !== 'self_checkout' &&
                                <RenderSwitch
                                  merchantShipping={element?.merchant?.shipping}
                                  successPage={successPage}
                                  priceForFreeShipping={element?.merchant?.price_for_free_shipping}
                                />
                              }

                            </div>
                            <div>
                              {element?.merchant?.price_for_free_shipping > 0 && !successPage && pageType !== 'self_checkout' && (
                              <div className="merchant-product-card__header--shipping-wrapper cart__footer-info--warning">
                                <div className="merchant-product-card__header--shipping-wrapper-info light-bg">
                                  <Tippy
                                    theme="orange"
                                    content={(
                                      <>
                                        <div className="basket-price-info">
                                          { project === "sneakerbox" ? <span>
                                            {gettext('ve üzeri ücretsiz kargo')}
                                            {' '}
                                            {toMoney(element?.merchant?.shipping?.freeShippingLimit)}
                                          </span>:<span>
                                            {toMoney(element?.merchant?.shipping?.freeShippingLimit)}
                                            {gettext(' ve üzeri ücretsiz kargo')}
                                          </span> }
                                        </div>
                                      </>
                                          )}
                                    placement="bottom"
                                  >
                                    <i className="icon icon-info" />
                                  </Tippy>
                                  <span className="shipping-for-free-info">
                                    <span className="info-first-text">{gettext('Kargo bedava olması için')}</span>
                                    <strong className="primary-color">{toMoney(element?.merchant?.price_for_free_shipping)}</strong>
                                    {project !== 'sneakerbox' && <span>{gettext('daha ürün ekleyin.')}</span>}
                                  </span>
                                </div>
                              </div>
                              )}
                            </div>
                          </>
                          )}

                <div data-id="16711" data-sku={element?.sku} className="merchant-product-card__cart-item basket-item w-100">
                  <div className="merchant-product-card__with-mobile-colon--first">
                    <div className="merchant-product-card__with-mobile-colon--second">
                      {/* PRODUCT DETAIL */}
                      <div className="merchant-product-card__detail-wrapper">
                        <ProductDetail
                          openModal={openModal}
                          imgSource={(element?.thumbUrlVertical || element?.imageUrlVertical || element?.thumbUrl || element?.image)}
                          cargoDate={handleDeliveryTimeDate(element)}
                          cargoText={handleDeliveryTimeText(element)}
                          element={element}
                          project={project}
                          wareHouseTitle={element?.merchant?.warehouseTitle}
                          deliveryType={element?.type}
                          successPage={successPage}
                          pageType={pageType}
                        />
                        <div className="merchant-product-card__with-mobile-colon--third">
                          {/* SIZE */}
                          {!isMobile() && (
                            <div className="merchant-product-card__size-wrapper">
                              <SizeWrapper size={element?.size} />
                            </div>
                          )}
                          <div className="merchant-product-card__inc-dec-wrapper">
                            {successPage || pageType === 'self_checkout'? (
                              <>
                                {!isMobile() && (
                                <div className="merchant-product-card__size-wrapper">
                                  <span className="size-title">
                                    {gettext('Adet')}
                                    :
                                  </span>
                                  <span className="size-number">{element?.quantity}</span>
                                </div>
                                )}
                              </>
                            ) : (
                              <span className="inc-dec--buttons">
                                <button
                                  className={`dec-button ${element?.quantity === 1 ? 'disabled' : ''}`}
                                  disabled={element?.quantity === 1}
                                  onClick={() => handleQuantity('dec', element)}
                                  data-test="checkout-product-card-negative-button"
                                >
                                  -
                                </button>
                                <span className="item-units">{element?.quantity}</span>
                                <button
                                  className={`inc-button ${element?.quantity === element?.stock && element?.quantity <= 7 ? 'disabled' : ''}`}
                                  disabled={!!(element?.quantity === element?.stock && element?.quantity <= 7)}
                                  onClick={() => handleQuantity('inc', element)}
                                  data-test="checkout-product-card-plus-button"
                                >
                                  +
                                </button>
                              </span>
                            )}
                          </div>
                          {/* PRICE */}
                          <div className="merchant-product-card--cart__price">
                            {(element?.secondPrice > 0 && !element?.storeMode) && (
                              <div className="cart__price--old">
                                <span>
                                  {toMoney(element?.firstPrice * element?.quantity)}
                                </span>
                              </div>
                            )}
                            <div className="merchant-product-card__price-wrapper primary-color">
                              {toMoney(element?.secondPrice > 0 ? (element?.secondPrice * element?.quantity) : ((element?.firstPrice || element?.price) * element?.quantity))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {element?.campaignName?.length > 0 && (
                        <div className="merchant-product-card__with-mobile-colon--message">
                          {element?.campaignName[0]}
                          <br />
                          {element?.campaignName[1]}
                        </div>
                      )}
                    </div>
                    {isMobile() && (project === 'flo' || project === 'instreet') && element?.merchant?.id === 10000 && (element?.type === 'SD' || element?.type === 'CC') && successPage && (
                      <div className="shipping-wrapper warehouse_area">
                        <span className="shipping-title">
                          Gönderim Bölgesi:
                          <span className="shipping-date">
                            {element?.merchant?.warehouseTitle}
                              &nbsp;bölgesinden yola çıkacaktır.
                          </span>
                        </span>
                      </div>
                    )}
                    {/* DELETE BUTTON */}
                    <div className="merchant-product-card__item-delete-wrapper">
                      <span type="button">
                        {!successPage && (
                          <div className="cart__action">
                            <CustomButton
                              className="card__delete-btn"
                              onClick={() => openConfirmPopup(element)}
                              dataTest="open-to-cart-page-delete-product-from-cart-open-modal"
                            >
                              {gettext('Sil')}
                              &nbsp;
                              <i className="icon-bin" />
                            </CustomButton>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

MerchantProductCard.defaultProps = {
  products: [],
  successPage: false,
  openModal: () => {},
};

MerchantProductCard.propTypes = {
  products: PropTypes.array,
  successPage: PropTypes.bool,
  openModal: PropTypes.func,
};

export default MerchantProductCard;

/*
SD -> Standart Teslimat
SDD -> Hızlı Teslimat
BC -> Mağazadan Teslimat
*/
