/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setAddressFieldValueAction,
} from '@Actions';
import config from '@Config';
import {
  gettext,
} from '@Translate';

import Input from '../../Elements/Input/Input';


const CorporateInvoice = ({ id, showErrors = false, addressType, onIsCorporateChange, isCorporate }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);

  const address = order[addressType];
  const { project } = config;

  return (
    <div className={`checkout-address__form--invoice ${project}-checkout-address__form--invoice`}>
      <div className="form-group">
        <label className={`checkbox ${project}-checkbox`} htmlFor={`js-corporate-bill${id}`}>
          <input
            type="checkbox"
            id={`js-corporate-bill${id}`}
            name="billing_invoice"
            data-test="is-corporate-invoice"
            checked={isCorporate}
            onChange={(event) => {
              dispatch(setAddressFieldValueAction({ addressType, key: 'corporateAddress', value: event.target.checked }))
              onIsCorporateChange(event.target.checked)
            }}
            className={`checkbox ${project}-chckbox`}
          />
          <span className={`xs ${project}-xs`} data-test="corporate-invoice"/>
          {gettext('Kurumsal Fatura İstiyorum')}
        </label>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="js-corporate-infos" style={{ display: isCorporate ? 'block' : 'none' }}>
            <div className="row small-gutter">
              <div className="col-6 col-lg-6">
                <div className="form-group">
                  <Input
                    error={showErrors}
                    type="text"
                    name="tax_office"
                    dataTest="tax-office"
                    pattern="alphanumeric"
                    value={address?.taxOffice}
                    data-test="checkout-address-invoice-tax-office-change"
                    minLength={10}
                    errorMessage={gettext('Lütfen en az 10 karakter uzunluğunda bir değer giriniz.')}
                    onChange={(event) => {
                      dispatch(setAddressFieldValueAction({ addressType, key: 'taxOffice', value: event.target.value.replace(/^\s+|\s(?=\s)/g, '')}));
                    }}
                    className={`form-control form-control__input js-replace-name-control ${project}-form-control__input`}
                    placeholder={gettext('Vergi Dairesi')}
                  />
                </div>
              </div>

              <div className="col-6 col-lg-6">
                <div className="form-group">
                  <Input
                    error={showErrors}
                    maxLength={11}
                    type="number"
                    dataTest="tax-number"
                    errorMessage={gettext('Lütfen bu alanı doldurunuz')}
                    secondMessage={gettext('Vergi/ TC Kimlik No hatalı kontrol ediniz.')}
                    value={address?.taxNumber || ''}
                    data-test="checkout-address-invoice-tax-number-change"
                    onChange={(event) => {
                      dispatch(setAddressFieldValueAction({ addressType, key: 'taxNumber', value: event.target.value.slice(0, event.target.maxLength) }));
                    }}
                    className={`form-control form-control__input ${project}-form-control__input`}
                    pattern="TCKNorVergi"
                    name="tax_number"
                    placeholder={gettext('Vergi/ TC Kimlik No')}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <Input
                error={showErrors}
                type="text"
                pattern="alphanumeric"
                dataTest="company"
                value={address?.companyName}
                data-test="checkout-address-Invoice-alphanumeric-change"
                onChange={(event) => {
                  dispatch(setAddressFieldValueAction({ addressType, key: 'companyName', value: event.target.value.replace(/^\s+|\s(?=\s)/g, '')}));
                }}
                className={`form-control form-control__input ${project}-form-control__input`}
                name="company"
                minLength={5}
                errorMessage={gettext('Lütfen en az 5 karakter uzunluğunda bir değer giriniz.')}
                placeholder={gettext('Şirket Adı')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CorporateInvoice.propTypes = {
  showErrors: PropTypes.bool.isRequired
};

export default CorporateInvoice;
