import React from 'react';
import {
  gettext,
} from '@Translate';
import { IconClose, DiscountCoupon } from '@Icons';

const ToastfyMessage = ({
  text, message, description, type, refunds, callback, confirm, head, subhead, subtext, buttonLink
}) => {
  return (
    <div className={`toastfy-message ${type === 'cheque' ? 'toastfy-message--cheque' : ''} ${type === 'credit-card-delete' ? 'toastfy-message--credit-card-delete' : ''} ${type === 'apply-coupon' ? 'toastfy-message--apply-coupon' : ''}`}>
      <div className="fancybox-hidden-content remove-product fancybox-content" style={{ display: 'inline-block' }}>
        <div className="remove-product__content mb-0">
          <>
            {type === 'credit-card-delete' && (
              <div className="toastfy-message__header">
                <span>{gettext('Kart Silme')}</span>
                <button type="button" className="Toastify__close-button" onClick={callback}><IconClose /></button>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-center toastfy-message__icons">
              {(type === 'refund' || type === 'refund_preview') && (
              <span className="icon icon-back-circle" />
              )}
              {type === 'topup' && (
              <span className="icon icon-wallet" />
              )}
              {type === 'success' && (
              <span className="icon icon-icon-check">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
              )}
              {type === 'store_cancel' && (
              <span className="icon icon-alert" />
              )}
              {type === 'basket_store_cancel' && (
                <span className='icon icon-alert flo' />
              )}
              {type === 'reject_success' && (
              <span className="icon icon-alert" />
              )}
              {type === 'cancel_payment' && (
              <span className="icon icon-alert" />
              )}
              {type === 'cheque' && (
                <span className="icon icon-cheque" />
              )}
            </div>
            {text !== undefined && (
            <>
              {(type === 'refund' || type === 'refund_preview' || type === 'topup') ? (
                <span className="toastfy-message__title success d-block mt-3">{text}</span>
              ) : type === 'apply-coupon' ? (
                <div className='toastfy-message--apply-coupon__title'>
                  <DiscountCoupon />
                  <span className="toastfy-message__title d-block mt-2">{text}</span>
                </div>
              ) : (
                <span className="toastfy-message__title d-block mt-3">{text}</span>
              )}
            </>
            )}
            {message !== undefined && (
            <span className="d-block mt-2 toastfy-message__description"><strong>{message}</strong></span>
            )}
            {refunds?.length > 0 && (
            <>
              <span className="d-block mt-4"><strong>{gettext('İade Edilecek Kart')}</strong></span>
              {refunds?.map((item, index) => (
                <span className="d-block mt-2">{item?.masked_card_number}</span>
              ))}
            </>
            )}
            {head && (
              <div className="toastfy-message__title d-block mt-3 primary-color">{head}</div>
            )}
            {subhead && (
              <div className="toastfy-message__subhead">{subhead}</div>
            )}
            {subtext && (
              <div className="toastfy-message__subtext">
                <strong>{gettext('Hediye Çekinin Geçerlilik Süresi')}</strong>
                <span>{subtext}</span>
              </div>
            )}
          </>
        </div>
        {description !== undefined && type !== 'apply-coupon' && (
        <span className="d-block mt-4 toastfy-message__description">{description}</span>
        )}
        {description !== undefined && type === 'apply-coupon' && (
        <span className="d-block mt-4 toastfy-message__description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {type === 'refund_preview' && (
        <button type="button" className="btn btn-orange mt-4" onClick={callback} data-test="checkout-message-confirm-button">
          {gettext('İade İşlemini Onayla')}
        </button>
        )}
        {type === 'store_cancel' && (
        <button type="button" className="btn w-100 btn-softgray mt-5" data-test="action-payment-cancel" onClick={confirm}>
          {gettext('ÖDEMEYİ İPTAL ET')}
        </button>
        )}
        {type === 'store_cancel' && (
        <button type="button" className="btn btn-cancel w-100 mt-2" data-test="action-qr-cancel" onClick={callback}>
          {gettext('VAZGEÇ')}
        </button>
        )}
        {type === 'basket_store_cancel' && (
          <button type="button" className="btn w-100 btn-qr-cancel  mt-4" data-test="action-payment-cancel" onClick={confirm}>
            {gettext('Evet')}
          </button>
        )}
        {type === 'basket_store_cancel' && (
          <button type="button" className="btn btn-softgray w-100 mt-2" data-test="action-qr-cancel" onClick={callback}>
            {gettext('Hayır')}
          </button>
        )}
        {type === 'reject_success' && (
        <button type="button" className="btn btn-orange w-100 mt-5" data-test="action-accept-reject" onClick={callback}>
          {gettext('TAMAM')}
        </button>
        )}
        {(type === 'cancel_payment' || type === 'cheque') && (
        <button type="button" className="btn btn-orange w-100 mt-5" data-test="action-accept-reject" onClick={confirm}>
          {gettext('TAMAM')}
        </button>
        )}
        {type === 'credit-card-delete' && (
          <>
            <div className="toastfy-message__button-group">
              <button type="button" className="btn btn-softgray" data-test="action-credit-card-delete" onClick={callback}>
                {gettext('VAZGEÇ')}
              </button>
              <button type="button" className="btn btn-orange" data-test="action-credit-card-delete" onClick={confirm}>
                {gettext('SİL')}
              </button>
            </div>
          </>
        )}
        {(type === 'apply-coupon' && buttonLink) && (
          <a href={buttonLink} className="btn btn-coupon w-100 mt-4" data-test="action-apply-coupon-link">
            {gettext('Tüm Ürünlere Git')}
          </a>
        )}
      </div>
    </div>
  );
} 

export default ToastfyMessage;
