import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { gettext } from '@Translate';
import config from '@Config';
import { logEvent, toMoney } from '@Constants';

// components
import { GalleryModal } from '../index';
import CustomDropdown from '../../CustomDropdown';
import CustomButton from '../../CustomButton';
import { addToShoppingCartAction, bunsarEventAction } from '@Actions';
import { addToCartEvent } from '../../../utils/gtmEvents';
import { buildEventActionPayload, isValidCredentials } from '../../../utils/BunsarHelpers';
import { ToastfyMessage } from '@Components';

const CampaignCard = ({ product, productIndex, project, grandTotal }) => {
  const [showModal, setShowModal] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedProductSku, setSelectedProductSku] = useState(0);
  const { webview } = useSelector((state) => state.config);
  const { shoppingCart, visitorId, shopperId } = useSelector((state) => state.cart); // event gönderimleri için gerekli

  // config
  const { appDeepLink, BASE_URL } = config;
  const dispatch = useDispatch();

  // Function for opening modal dialog
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const imgSrc = product?.image_vertical || product?.image;
  const reebokImgSrc = product?.thumbUrl || product?.image;

  const getProductLink = (product) => {
    if (webview) {
      return `${appDeepLink}product.detail?sku=${product?.parentSku}`;
    }
    let productUrl = product?.url || product?.link

    let RENOVATED_BASE_URL = BASE_URL;
    if (RENOVATED_BASE_URL.endsWith('/')) {
      RENOVATED_BASE_URL = RENOVATED_BASE_URL.slice(0, -1);
    }

    return `${RENOVATED_BASE_URL}${productUrl}`;
  };

  const sendLongEvent = (product) => {
    logEvent('select_item', {
      ...product,
      value: grandTotal || 0,
      currency: 'TRY',
    });
    window.location.href = `${appDeepLink}product.detail?sku=${product?.parentSku}`;
  };

  const handleUpdate = (quantity, sku, price) => {
    if (project !== 'flo' || webview) return;

    const credentials = shoppingCart?.bunsarEventCredentials;

    if (!isValidCredentials(credentials) || !sku) {
      return;
    }

    const productInfo = [sku, quantity, price].filter(Boolean).join('|');

    if (!productInfo) {
      return;
    }

    const payload = buildEventActionPayload({
      credentials,
      visitorId,
      shopperId,
      eventType: 'Cart.Rec.AddToCart',
      additionalParams: { recType: 'ctc3rd', recProduct: productInfo }
    });

    dispatch(bunsarEventAction(payload));
  };
  const addToCart = async ({ product, sku, quantity, }) => {
    if (sku > 0){
      const productId = sku;
      dispatch(addToShoppingCartAction({
        product, productId, sku, quantity,
      }));
      handleUpdate(quantity, product?.parentSku, product?.price)
      let modifiedProduct = { ...product };
      modifiedProduct.quantity = '1'
      addToCartEvent(modifiedProduct)
      setSelectedProductSku(0);
      setSelectedValues({
        ...selectedValues,
        [productIndex]: 0,
      });
    } else {
      toast.error(ToastfyMessage({ text: gettext('Lütfen beden seçimi yapınız.') }));
    }
  };

  return (
    <div className="product-detail--container special-campaign-card-wrapper__item">
      <GalleryModal isOpen={showModal} onClick={closeModal} src={imgSrc} />
      <div className={`product-detail__img-wrapper ${project}-product-detail__img-wrapper`}>
        <img className="" src={project === 'reebok' ? reebokImgSrc : imgSrc} alt="" onClick={openModal} style={{ width: 120, cursor: 'pointer' }} data-test="checkout-product-detail-image-link" />
      </div>
      <div className={`product-detail__content-wrapper ${project}-product-detail__content-wrapper`}>
        {/* <span className="brand">{brand}</span> */}
        <span className={`title ${product?.manufacturer === 'Reebok' ? 'reebok-title' : ''}`}>
          {
            (product?.manufacturer === 'Reebok' ? '' : (
              <>
                {product?.manufacturer}
                <br />
              </>
            ))
          }
          {webview ? (
            <div data-href={getProductLink(product)} onClick={() => sendLongEvent(product)} className="cart__item-name" data-test="checkout-product-detail-product-link">
              {product?.name}
            </div>
          ) : (
            <a href={getProductLink(product)} className="cart__item-name" data-test="checkout-product-detail-product-link">
              {product?.name}
            </a>
          )}
          <span className="cart__item-name color-red">
            {gettext('Sepetine Özel İndirim')}
          </span>
          <div className="cart__item-price">
            {(product.discountPrice && product.discountPrice > 0 && product.discountPrice < product.price) ? (
              <>
                <span className='cart__item-price-old'>{toMoney(product.price)}</span>
                <span className='cart__item-price-special'>{toMoney(product.discountPrice)}</span>
              </>
            ) : <span className='cart__item-price-special'>{toMoney(product.price)}</span>}
          </div>
          <div className='cart__item-buttons'>
            <CustomDropdown
              placeholder='Beden'
              options={product.options}
              value={selectedValues[productIndex]}
              isOpened={openIndex === productIndex}
              onChange={(size, variantSku) => {
                setSelectedValues({
                  ...selectedValues,
                  [productIndex]: size, // Her bir selectbox'ın kendi seçimi olacak
                });
                setSelectedProductSku(variantSku)
                setOpenIndex(null); // Seçim yapıldığında dropdown'ı kapatıyoruz
              }}
            />
            <CustomButton
              type="button"
              project={project}
              className={`prev-add-card__add-to-cart-btn ${project}-prev-add-card__add-to-cart-btn`}
              onClick={() => {
                addToCart({
                  product, sku: selectedProductSku, quantity: 1, merchantId: (product?.merchant?.id || 10000),
                })
              }}
            >
              {gettext('Sepete Ekle')}
            </CustomButton>
          </div>
        </span>
      </div>
    </div>
  );
};

CampaignCard.defaultProps = {
  brand: '',
  cargoDate: '',
  cargoText: '',
  successPage: false,
  deliveryType: 'SD',
  project: 'flo',
  openModal: () => {},
};

CampaignCard.propTypes = {
  brand: PropTypes.string,
  cargoDate: PropTypes.string,
  cargoText: PropTypes.string,
  successPage: PropTypes.bool,
  deliveryType: PropTypes.string,
  project: PropTypes.string,
  openModal: PropTypes.func,
};

export default CampaignCard;
