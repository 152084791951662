const data = {
  "‘si FLO Para’dır": "Si flo - это пункт",
  "’den alışveriş yaptığınız için teşekkür ederiz.": "Спасибо за покупки.",
  "’ı Aktif edin siz d": "Активировать вас D.",
  "’ı Aktif edin siz de": "Активируйте вас тоже",
  "’ni kabul ediyorum.": "Я согласен «Ni».",
  "’ni okudum.": "Я читаю.",
  "’si Kapıda Ödeme işlemi olduğu için hesabınıza nakit olarak aktarılabilir. Ücret iadenizi nakit olarak almak istiyorsanız lütfen": "Поскольку это процесс оплаты у двери, он может быть переведен на вашу учетную запись наличными. Если вы хотите получить вашу окупаемость наличными, пожалуйста",
  "’ten alışveriş yaptığınız için teşekkür ederiz.": "Спасибо за покупки.",
  "“Fatura Adresim” “Teslimat Adresim” ile Aynı": "Мой биллинг -адрес такой же, как и мой адрес доставки",
  "/aydinlatma-metni": "/aydinlatma-metni",
  "/flo-cuzdan-kullanici-sozlesmesi": "/flo-cuzdan-kullanici-sozlesmesi",
  "/page?url=sss-desktop&tab=cuzdan": "/page?url=sss-desktop&tab=cuzdan",
  "1- Siparişiniz kargo aracılığıyla ilgili mağazamıza teslim edilecektir.": "1- Ваш заказ будет доставлен в наш магазин через Cargo.",
  "2- Mağazadan teslimat seçeneği sadece belirli mağazalarımızda geçerlidir.": "2- Вариант доставки из магазина действителен только в наших конкретных магазинах.",
  "3- Mağazadan teslimat seçeneğinde ödemelerinizi sadece kredi kartı ile yapabilirsiniz": "3- Вы можете сделать ваши платежи только на вариант доставки только кредитной картой",
  "4- Siparişiniz talep ettiğiniz mağazaya ulaştığında otomatik SMS ve e-mail gönderimi ile bilgi verilecektir.": "4- Он будет проинформирован автоматическим SMS и электронной почтой, когда ваш заказ достиг запрашиваемого магазина.",
  "5- Mağazalarımızdan siparişinizi teslim alırken sipariş no, ad, soyad, TC kimlik no ve size gönderilen güvenlik kodunuzu mağaza personeline bildirmeniz gerekmektedir.": "5- Когда вы сдаете свой заказ из наших магазинов, вам нужно сообщить о вашем заказе Нет, имя, соевое имя, TC ID NO и ваш код безопасности, отправленного вам.",
  "6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.": "Введите свой номер телефона, чтобы получить 6-значный код подтверждения.",
  "6- Mağazalarımıza teslim edilen siparişleriniz '3 iş günü içerisinde teslim alınmalıdır.'": "6- Ваши заказы, доставленные на наши магазины, должны быть доставлены в течение 3 рабочих дней.",
  "7- Mağazalarımıza ulaşan siparişleriniz zamanında teslim alınmadığında iptal edilecektir.": "7- Ваши заказы, достигающие наших магазинов, будут отменены, когда не получены вовремя.",
  "8 karakter": "Не менее 8 символов",
  "8- Mağazalarımızdan teslimat hergün 10:00 - 20:00 saatleri arasında yapılmaktadır.": "8- Доставка из наших магазинов производится с 10:00 до 8:00 каждый день.",
  "Ad": "Имя",
  "AD SOYAD": "ИМЯ ФАМИЛИЯ",
  "Ad ve Soyad": "Имя и фамилия",
  "Adet": "Кол-во",
  "ADET:": "Кол-во:",
  "Adınız": "Твое имя",
  "Adres": "Ярцевская ул, 19",
  "Adres Başlığı": "Название адреса",
  "Adrese Teslimat": "Курьером по адресу",
  "Adrese Teslim": "Курьером по адресу",
  "Adres/Ödeme": "Адрес/оплата",
  "Adresi Kaydet": "Сохранить адрес",
  "adresine bir doğrulama e-postası gönderelim.": ".",
  "Ağustos": "Август",
  "aktarabilirsiniz.": "может передавать.",
  "Aktarmak İstediğiniz Tutar Giriniz": "Введите сумму, которую вы хотите перенести",
  "ALIŞVERİŞE BAŞLA": "В магазин!",
  "ALIŞVERİŞE DEVAM ET": "ПРОДОЛЖИТЬ ПОКУПКИ",
  "Alışveriş tutarınıza kargo firması tarafından talep edilen": "Запрашивается грузовой фирмой в вашу сумму покупок",
  "Alt Limit:": "Минимальная сумма заказа:",
  "Apple bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Ваша информация Apple не может быть проверена, пожалуйста, попробуйте еще раз.",
  "Apple ile Giriş Yap": "Войти с Apple",
  "Apple ile Kayıt Ol": "Зарегистрируйтесь с Apple",
  "Aralık": "Декабрь",
  "Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.": "Следующий продукт / продукты были удалены из вашей корзины, поскольку они отсутствуют на складе.",
  "Ay": "Луна",
  "aydinlatma-metni-mobile": "Уточнение-текст-мобильный",
  "Aynı Gün Mağazadan Teslimat": "Доставка из магазина в тот же день",
  "Bakiye": "Остаток средств",
  "Bakiye": "Остаток средств",
  "BAKİYE YÜKLE": "Загрузить баланс",
  "Bakiye:": "Остаток средств:",
  "Başka Bir Kart İle Ödeme Yap": "Платить с другой картой",
  "Başka Kredi Kartı İle Öde": "Оплата с другой кредитной картой",
  "Beden": "Тело",
  "Beden:": "Размер:",
  "Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.": "Вы можете собрать корзину и отправить её своим друзьям.",
  "binlerce ürün ve model seni bekliyor.": "Давайте это исправим!",
  "Bir tutar giriniz.": "Введите сумму.",
  "Bu alanın doldurulması zorunludur.": "Это поле обязательно к заполнению.",
  "Bu ekranı gördüğünüz için üzgünüz!": "Извините, что вижу этот экран!",
  "Bu ürünler": "Эти продукты",
  "Bu ürünün iadesini yalnızca Kargo ile gerçekleştirebilirsiniz.": "Вы можете выполнить только возвращение этого продукта с помощью грузов.",
  "buraya": "здесь",
  "büyük harf": "Прописная буква",
  "Cep Telefonu": "Мобильный телефон",
  "Cüzdan Detayları": "Ваум Детали",
  "Cüzdana yüklemek istediğiniz tutarı giriniz.": "Введите сумму, которую вы хотите загрузить в кошелек.",
  "CVV": "CVV.",
  "değerinde": "ценность",
  "daha ürün ekleyin.": "добавьте больше продуктов.",
  "DEMO": "Демонстрация",
  "DENEME": "ПЫТАТЬСЯ",
  "DEVAM ET": "ПРОДОЛЖАЙ",
  "Devam Et": "Применить",
  "Devam etmek ister misiniz?": "Применить промокод?",
  "Diğer Adreslerim": "Мои другие адреса",
  "Düzenle": "Редактировать",
  "Doğrulama Kodu": "Проверочный код",
  "Doğrulama E-postası Gönder": "Отправить письмо с подтверждением",
  "E-Posta Adresi": "Адрес электронной почты",
  "E-Posta Adresiniz": "Адрес электронной почты",
  "Ekim": "Октябрь",
  "eklenecektir.": "будет добавлено.",
  "En az": "Минимально",
  "En fazla": "Максимум",
  "En fazla 20 adet ürün ekleyebiliriz": "Мы можем добавить до 20 продуктов",
  "Erkek": "Мальчик",
  "Eylül": "Сентябрь",
  "E-Posta Doğrulama": "Подтверждение по элетронной почте",
  "Facebook bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Ваша информация на Facebook не может быть проверена, попробуйте еще раз.",
  "Facebook ile Giriş Yap": "Войти с Facebook",
  "Facebook ile Kayıt Ol": "Зарегистрируйтесь с Facebook.",
  "Fatura Adresi": "Адрес выставления счета",
  "Faturamı aynı adrese gönder": "Укажите адрес для передачи чека или адрес электронной почты для направления чека.",
  "Filtrele": "Фильтр",
  "FLO Cüzdan": "FLO кошелек",
  "FLO Cüzdan ile Öde": "FLO платят с кошельком",
  "FLO Cüzdan ile Ödendi": "Оплачивается с FLO Wooler",
  "Flo Cüzdan Kullanıcı Sözleşme": "Пользовательское соглашение FLO кошелька",
  "Flo Cüzdan Kullanıcı Sözleşmesi": "Пользовательское соглашение FLO кошелька",
  "FLO Cüzdan Oluştur": "Создать FLO кошелек",
  "FLO Cüzdana Yüklendi ve Ödendi": "передал и оплатил FLO кошелек",
  "FLO Cüzdanı Aktif Et ve Öde": "Активировать FLO кошелек и оплатить",
  "Flo Cüzdanım": "Мой фол кошелек",
  "FLO Cüzdanım": "Мой фол кошелек",
  "FLO Cüzdanım’a Git": "Перейти к Flo Wallet",
  "FLO Cüzdanınız´a": "к вашим фол кошелек",
  "FLO Cüzdanınızı aktif etmek için": "Активировать свой фол кошелек",
  "FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni": "FLO Storing и Marketing Inc. Освещение клиентов",
  "Flo Müşteri Aydınlatma Metni": "Flo клиент освещение текста",
  "FLO Para": "Фло",
  "FLO Para Geri Kazan": "Наличными назад flo деньги",
  "Flo Para Kazan": "Flo Money",
  "FLO Para Kazan": "FLO Money",
  "FLO PARA KAZANDIN!": "Вы выиграли Flo Money!",
  "FLO Para Kullan": "Используйте Flo Money",
  "FLO Paralarınız karta iade edilemez.": "Ваши монеты FLO не могут быть возвращены на карту.",
  "flo-cuzdan-kullanici-sozlesmesi-app": "Flo-cuzdan-user-sozlesation-приложение",
  "FLO Plus ile Hızlı Öde": "Платите быстро с FLO Plus",
  "Geçerli telefon numarası giriniz.": "Введите текущий номер телефона.",
  "Geçerliliğini Kaybedecek FLO Paralarım": "Мои монеты FLO, чтобы потерять свою достоверность",
  "geçerlilik": "срок действия",
  "Geçerlilik süresini yitirecek olan FLO Paralar aşağıda aylık olarak listelenmektedir.": "FLO монеты, чтобы потерять время действия, перечислены через несколько месяцев ниже.",
  "Genel Toplam": "Итого итог",
  "Giriş": "Вход",
  "Giriş Yap": "Авторизоваться",
  "GİRİŞ YAP": "АВТОРИЗОВАТЬСЯ",
  "GÖNDER": "ОТПРАВИТЬ",
  "Google bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Ваша информация Google не может быть проверена, попробуйте еще раз.",
  "Google ile Giriş Yap": "Войти с Google",
  "Google ile Kayıt Ol": "Зарегистрируйтесь с Google",
  "Güncel": "Текущий",
  "Güvenli Alışveriş ve Ödeme": "Безопасная покупка и оплата",
  "Hata! Lütfen tekrar deneyiniz.": "Ошибка! Пожалуйста, попробуйте еще раз.",
  "Haziran": "Июнь",
  "Hemen Aktif Et": "Active İmmed соответствует",
  "Hemen Üye Ol": "Зарегистрироваться сейчас",
  "Hesabınıza en fazla": "Больше всего к вашей учетной записи",
  "Hesap Özetim": "Моя учетная запись Сводка",
  "Hızlı Yükle": "Загрузите быстро",
  "İade Edilecek Kart": "Карта будет возвращена",
  "İade İşlemini Onayla": "Подтвердите процесс возврата",
  "İade işlemlerinde fatura ibrazı zorunludur, faturasız iade kabul edilmemektedir.": "Представление счета требуется в обратных транзакциях, не принимаются биллинговые доходы.",
  "IBAN Numaranız": "Ваш номер IBAN",
  "IBAN numaranız yukarıda vermiş olduğunuz ‘’Ad ve Soyad’’ bilgileriyle eşleşmelidir.": "Ваш номер IBAN должен соответствовать информации «имя и фамилию», которую вы предоставили выше.",
  "İl": "Область",
  "İl Seçiniz": "Выберите провинцию",
  "İlçe": "Город",
  "İlçe Seçiniz": "Выберите уезд",
  "İndirim kodunuz varsa lütfen giriniz": "Пожалуйста, введите купон на скидку",
  "İptal": "Отмена",
  "İptal Et": "Отменить",
  "İşlem Gerçekleştiriliyor...": "Обработка...",
  "İndirimli": "Скидка",
  "Kadın": "Женщина",
  "Kaldır": "Удалить",
  "Kalan Süreniz:": "Ваш оставшийся период:",
  "Kampanya": "Предложение",
  "Kampanya dönemlerinde yapılan sürpriz indirimlerden, stoklar ve bedenler tükenmeden ilk sizin haberiniz olsun.": "Сюрприз скидки, сделанные во время периодов кампании, акции и тела, первыми, кто уходит из ваших новостей.",
  "Kampanya Kodu": "Введите промокод",
  "Kapıda kredi kartı ile ödeme kabul edilmemektedir": "Оплата кредитной картой не принимается у двери",
  "Kapıda Nakit Ödeme": "Наличными в дверь",
  "Kapıda Ödeme": "Заплатить у двери",
  "Kapıda ödeme seçeneğinde sadece nakit ödeme kabul edilmektedir.": "Только наличный платеж принимается в варианте оплаты у двери.",
  "Kapıda ödeme seçeneğiyle alınan ürünlerin iadesi mağazalarımızdan veya iade adresimize gönderilerek yapılabilir": "Возврат продуктов, полученных с вариантом оплаты в дверях, может быть сделан нашими магазинами или обратным адресом на наш обратный адрес",
  "kapıda ödeme ücreti": "Плата за оплату у двери",
  "Kargo Bedava": "Бесплатный груз",
  "Kargo": "Груз",
  "Kargo Ücreti": "Стоимость доставки",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin <strong>Kargo Bedava</strong> olması gerekmektedir.": "Все предметы в вашей корзине должны быть <strong> бесплатной доставкой </strong> для того, чтобы вы не платите за доставку.",
  "Kargo bedava olması için": "Стоимость доставки:",
  "Kart Bilgileri": "Информация о карте",
  "Kart Numarası": "Номер карты",
  "Karta Yüklenebilir FLO Para": "Загружаемые FLO деньги на корзину",
  "Kartıma Geri Yükle": "Восстановить на мою карту",
  "Kartınıza en fazla": "До вашей карты",
  "Kartınıza geri yükleme süresi bankalara bağlı olarak 2-10 iş günü arasında sürmektedir.": "Время перезапуска на вашу карту составляет от 2 до 10 рабочих дней в зависимости от банков.",
  "Kartınızın arka yüzündeki son 3 rakamı yazınız.": "Напишите последние 3 цифры на задней стороне вашей карты.",
  "Kasım": "Ноябрь",
  "KAYDET": "СПАСТИ",
  "Kayıtlı Kart İle Devam Et": "Продолжить с зарегистрированной картой",
  "kazanabilirdiniz.": "Вы могли бы победить.",
  "Kazandın!": "Победил!",
  "Kazanmaya Başlayın!": "Начните побеждать!",
  "Kendim": "Сам",
  "Kodu Tekrar Gönder": "Отправить код еще раз",
  "Kolay İade Seçeneği": "Опция Easy Repe.",
  "Kolay İade' Butonuyla hızlıca yapabilirsiniz.": "Вы можете быстро сделать с помощью кнопки Easy Rend.",
  "Kredi Kartı ile Yükleme Yap": "Нагрузка с кредитной картой",
  "Kredi Kartımı Sakla": "Сохранить мою кредитную карту",
  "Kopyala": "Копировать",
  "Kopyalandı": "Скопировано",
  "Kullan": "Применить",
  "Kupon Kodunu Yazınız": "Введите код купона",
  "Kurumsal Fatura İstiyorum": "Я хочу корпоративный счет",
  "Lütfen 3 karakter uzunluğunda bir değer giriniz": "Пожалуйста, введите значение в размере 3 символов",
  "Lütfen bilgileri eksiksiz doldurunuz": "Пожалуйста, заполните информацию",
  "Lütfen bu alanı doldurunuz": "Пожалуйста, заполните это поле",
  "Lütfen geçerli bir telefon girin.": "Пожалуйста, введите действительный телефон.",
  "Lütfen geçerli bir tutar giriniz.": "Пожалуйста, введите допустимую сумму.",
  "Lütfen IBAN Numaranızı Girin": "Пожалуйста, введите свой номер IBAN",
  "Lütfen Kredi Kart Bilgilerinizi Yazın": "Пожалуйста, введите информацию о вашей кредитной карте",
  "Lütfen sayfayı yeniden yükleyin.": "Пожалуйста, перезагрузите страницу.",
  "Lütfen T.C. kimlik numaranızı kontrol ediniz!": "Пожалуйста, проверьте свой идентификационный номер!",
  "Lütfen vergi kimlik numaranızı kontrol ediniz!": "Пожалуйста, проверьте свой налоговый идентификационный номер!",
  "Mağaza Adresi": "Адрес магазина",
  "Mağazadan": "Из магазина",
  "Mağazadan nasıl teslim alırım?": "Как я могу забрать из магазина?",
  "Mağazadan Teslimat Bilgileri": "Информация о доставке из магазина",
  "Mağazadan Teslimat": "Доставка в магазин",
  "Mağazalardan nakit iade yapılmamaktadır": "Денежные возвраты не сделаны из магазинов",
  "Mağazasından": "Из магазина",
  "Mahalle, cadde, sokak ve diğer bilgilerinizi giriniz.": "Введите свой район, улицу и другую адресную информацию.",
  "Mahalle": "Район",
  "Mart": "Марта",
  "Mayıs": "Мая",
  "Mesafeli Satış Sözleşmesini": "Договор о продаже расстояния",
  "Mobil Uygulamamız ile Sürpriz Avantajlar": "Неожиданные преимущества с нашим мобильным приложением",
  "Mobil Uygulamasına özel yaptığımız etkinliklerden ilk sizin haberiniz olsun.": "Есть ваши новости о деятельности, которые у нас есть специальные для мобильного приложения.",
  "Nisan": "апрель",
  "numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.": "Введите 6-значный код подтверждения, отправляемый на ваш номер телефона.",
  "Ocak": "Январь",
  "otp-form-text-1": "Пожалуйста, введите 6-значный код подтверждения, отправленный на адрес",
  "Ödeme": "Оплата",
  "ÖDEME": "ОПЛАТА",
  "ÖDEME ADIMINA GEÇ": "Перейти к оплате",
  "Ödeme Seçenekleri": "Банковская карта",
  "Ödeme Yöntemi": "Способ оплаты",
  "Ödeme Bilgileri": "Платежная информация",
  "ÖDEMEYİ TAMAMLA": "Оплатить",
  "Ödenecek Tutar": "Общая сумма",
  "okudum onaylıyorum": "Я прочитал и принимаю",
  "olması gerekmektedir.": "Надо быть.",
  "Ön Bilgilendirme Formunu": "Предварительная информационная форма",
  "Onay Kodu": "Код подтверждения",
  "ONAYLA": "УТВЕРДИТЬ",
  "Onayla": "УТВЕРДИТЬ",
  "Önceden Sepete Eklediklerim": "Вы добавляли:",
  "rakam giriniz": "Шт.",
  "Sana en yakın mağazadan gel al.": "Приходите и приобретайте его в ближайшем к вам магазине.",
  "Satın aldığınız ürünlerin iadelerini": "Их возвращение продуктов, которые вы покупаете",
  "Sayfa Bulunamadı": "Страница не найдена",
  "Sepet": "Корзина",
  "Sepete Ekle": "Добавить в корзину",
  "SEPETİ ONAYLA": "Оформить",
  "Sepetim": "Корзина",
  "Sepetinizde ürün bulunmamaktadır.": "В вашей корзине нет товаров.",
  "Sepetini Arkadaşınla Paylaş": "Делитесь своей корзиной с друзьями!",
  "Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?": "Добавить в избранное после удаления из корзины?",
  "Sepetinizdeki Ürünün Stoğu Tükendi!": "Продукт в вашей корзине отсутствует на складе!",
  "Sepette": " ",
  "Siparişlerin ve güncel fırsatlarla ilgili e-postaları almaya devam etmek için": "Подтвердите почту для получения информации о заказах и актуальных предложениях. Мы отправим письмо по адресу:",
  "Şifre": "Пароль",
  "Şifremi Unuttum": "я забыл мой пароль",
  "Şifreniz": "Ваш пароль",
  "Sil": "Удалить",
  "Sil ve Favorilere Ekle": "Удалить и добавить в избранное",
  "Sipariş No:": "№ заказа:",
  "Sipariş Numarası:": "Номер заказа:",
  "Sipariş Özeti": "Итог заказа",
  "Siparişime Git": "Мои заказы",
  "SİPARİŞİNİZ BAŞARI İLE TAMAMLANMIŞTIR": "Ваш заказ успешно оформлен",
  "SİPARİŞLERİM": "МОИ ЗАКАЗЫ",
  "Şirket Adı": "Название компании",
  "Size Özel Kupon Fırsatları": "Специальные купонные возможности для вас",
  "Son Kullanma Tarihi:": "Использовать до:",
  "SONRAKİ ADIMA DEVAM ET": "Продолжить следующий шаг",
  "Soyad": "Фамилия",
  "Soyadınız": "Ваша фамилия",
  "Sözleşme ve Formlar": "Приложения",
  "Standart Teslimat": "Стандартный комплект поставки",
  "Şubat": "Февраль",
  "Tahmini Kargoya Teslimat :": "Eta :",
  "Tahmini Kargoya Veriliş Tarihi:": "Дата доставки:",
  "Taksit": "Установка",
  "Taksit Seçenekleri": "Варианты оплаты",
  "Taksit seçenekleri kart bilgilerinizi girdikten sonra güncellenecektir.": "Параметры рассрочки будут обновлены после ввода информации о вашей карте.",
  "TAMAM": "ХОРОШО",
  "tarafından gönderilecektir.": "будет отправлено.",
  "Tek Çekim": "Выплата",
  "Tekrar SMS Gönder": "Отправить SMS снова",
  "Telefon Numaranız": "Ваш номер телефона",
  "Telefon numaranızı kontrol ediniz.": "Номер телефона недействителен.",
  "telefon numarasına gönderilen 6 haneli kodu giriniz.": "Введите 6-значный код, отправленный на номер телефона.",
  "Temmuz": "Июль",
  "Teşekkürler": "Спасибо",
  "Teslim Alacağım": "Я приму доставку",
  "teslim edilecektir": "Это будет доставлено",
  "Teslimat Adresi": "Адрес доставки",
  "Teslimat Bilgileri": "Информация о доставке",
  "Teslimat Seçenekleri": "Адрес доставки",
  "TEST": "КОНТРОЛЬНАЯ РАБОТА",
  "tıklayın.": "Нажмите.",
  "Toplam": "Итого",
  "TL": "Kzt.",
  "TL ’den": "RUB до",
  "TL bakiye yükleyebilirsiniz.": "KZT может быть загружен.",
  "TL en fazla": "Максимум KZT",
  "TL hesabınıza iade edebilirsiniz.": "KZT может быть возвращена на вашу учетную запись.",
  "TL iade edilebilir.": "KZT может быть возвращена.",
  "TL kartınıza iade edebilirsiniz.": "KZT может быть возвращена на вашу карту.",
  "TL’ye yükselmiştir.": "RUB.",
  "tr": "ru",
  "TR99 9999 9999 9999 9999 9999 99": "TR99 9999 9999 9999 9999 99",
  "TRY": "ПЫТАТЬСЯ",
  "tutarında yükleme yapıldı ve ödeme işlemi tamamlandı.:": "Сумма была сделана, и процесс оплаты был завершен.",
  "ÜCRETSİZ": "БЕСПЛАТНО",
  "Ücretsiz": "Бесплатно",
  "Ücretsiz Kargo": "Бесплатная доставка",
  "Ürün": "Шт.",
  "ürün": "Шт.",
  "Ürünler": "Товары",
  "ürün daha ekleyin Kargo Bedava olsun.": "Продукт может добавить больше доставки бесплатной доставки.",
  "Ürün Var": "товар в корзине",
  "Ürünlerinizi aşağıda adresi bulunan mağazamızdan teslim alabilirsiniz.": "Вы можете забрать продукцию из нашего магазина ниже.",
  "uyarınca işlenecektir.": "будет обрабатываться в соответствии с.",
  "ÜYE OL": "ЗАРЕГИСТРИРОВАТЬСЯ",
  "HESAP OLUŞTUR": "Создать аккаунт",
  "Üye olmadan": "Без члена",
  "ÜYE OLMADAN ALIŞVERİŞ": "Шоппинг без члена",
  "Üye Olmadan Devam Et": "Продолжать без члена",
  "üyeleri kapıda nakit ödeme avantajından yararlanabilirler.": "Участники могут извлечь выгоду из преимущества оплаты наличными у двери.",
  "Üyeleri, hiç kullanmadığı ürünleri 14 gün içerisinde hızlı ve kolay bir işlemle iade edebilme avantajından yararlanırlar.": "Члены извлекают выгоду из преимущества возможность вообще вернуть продукты в течение 14 дней с помощью быстрой и легкой транзакции.",
  "Üyelerimize özel yaptığımız kupon etkinliklerinden faydalanın": "Воспользоваться преимуществами купонных мероприятий, которые мы делаем наши члены особенным",
  "ÜYELİK AVANTAJLARI": "Преимущества членства",
  "uyelik_sozlesmesi": "uyelik_sozlesmesi",
  "Uygula": "Применить",
  "Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız": "Предложение по промокоду не суммируется со скидкой. Стоимость товаров в корзине увеличится с",
  "VAZGEÇ": "СДАТЬСЯ",
  "Vazgeç": "СДАТЬСЯ",
  "ve": "и",
  "ve üzeri ücretsiz kargo": " Бесплатная доставка при заказе от",
  "Vergi Dairesi": "Налоговая служба",
  "Vergi/ TC Kimlik No": "Налог / идентификатор нет",
  "Vergi/ TC Kimlik No hatalı kontrol ediniz.": "Налог / идентификатор нет недействителен.",
  "veya": "или",
  "ya Giriş Yapın": "Либо подписать",
  "Yapmış olduğun alışverişten": "От покупок вы сделали",
  "Yeni Adres Ekle": "Добавить новый адрес",
  "Yıl": "Год",
  "Yüklediğin Para": "Деньги, которые вы загрузили",
  "Yükleniyor": "Загрузка",
  "Tahmini Teslimat :": "Eta :",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin": "Чтобы получить бесплатную доставку, каждый предмет в вашей корзине должен быть",
  "Kargo Bedava": "Бесплатная доставка",
  "olması gerekmektedir.": ".",
  "SEPETE GİT": "Посмотреть корзину",
  "SİPARİŞİ TAMAMLA": "Полный заказ",
  "Siparişiniz hazırlandığında e-posta ile sizi bilgilendireceğiz.": "Вы получите сообщение, когда ваш заказ будет подтверждён.",
  "Siparişinizle ilgili gelişmeleri Siparişlerim sayfasından takip edebilirsiniz.": " Вы также можете следить за статусом заказа в разделе «Мои заказы».",
  "Lütfen geçerli bir eposta adresi yazın": "Пожалуйста, напишите действительный адрес электронной почты",
  "Kayıtlı kullanıcı bulunamadı": "Пользователь не найден",
  "istenilen miktarda ürün bulunmamaktadır.": "Нет желаемого количества продуктов.",
  "Kredi / Banka Kartı": "Банковская карта",
  "Lütfen geçerli bir numara giriniz.": "Пожалуйста введите правильное число.",
  "Adres bilgisi 10 karakterden fazla olmalıdır.": "Информация об адресе должна содержать более 10 символов.",
  "Lütfen en az 5 karakter uzunluğunda bir değer giriniz.": "Пожалуйста, введите значение длиной не менее 5 символов.",
  "Lütfen en az 10 karakter uzunluğunda bir değer giriniz.": "Пожалуйста, введите значение длиной не менее 10 символов.",
  "E-Posta Adresinizi Doğrulayın": "Подтвердите электронную почту",
  "Lütfen kupon kodunu giriniz.": "Пожалуйста, введите код купона.",
  "1- Mağazadan teslimat seçeneğinde il ve ilçe seçimi yaptıktan sonra teslim alınabilecek mağazaları görüntüleyebilirsiniz.": "1- Вы можете увидеть магазины, где можно забрать ваш заказ, после выбора города и района в опции доставки из магазина.",
  "2- Siparişiniz teslim almak için hazırlandığında SMS ve e-posta ile bilgi paylaşılacaktır.": "2- Когда ваш заказ будет готов к получению, вы получите уведомление по SMS и электронной почте.",
  "3- Siparişinizi mağazadan teslim alırken SMS ile gönderilen güvenlik kodunu mağaza çalışanı ile paylaşmanız gerekmektedir.": "3- При получении заказа в магазине вам нужно будет поделиться с сотрудником магазина кодом безопасности, отправленным по SMS.",
  "4- Siparişiniz hazırlandıktan sonra 10 gün içerisinde mağazadan teslim alınmalıdır.": "4- Ваш заказ должен быть забран из магазина в течение 10 дней после его подготовки.",
  "5- Mağazadan 10 gün içerisinde teslim alınmayan siparişin iade girişi yapılacaktır.": "5- Заказы, не забранные в течение 10 дней, будут возвращены.",
  "6- Siparişinizi mağazadan her gün 10:00 - 21:00 saatleri arasında teslim alabilirsiniz.": "6- Вы можете забрать свой заказ из магазина каждый день с 10:00 до 21:00.",
  "7- Saat 19:00'a kadar verdiğiniz siparişinizi aynı gün 21:00'a kadar teslim alabilirsiniz.": "7- Если вы оформите заказ до 19:00, вы сможете забрать его в тот же день до 21:00.",
  "Loyalty": "Программа лояльности",
  "Puanı hemen kullan.": "Применить.",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Введите не менее 3-х знаков.",
  "Önceden Eklediklerim": "Ранее добавленные",
};

export default data;
