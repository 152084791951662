import React, { useEffect, useRef, useState } from "react";
import { IconBottomArrow } from '@Icons';

const CustomSelect = ({ value, options, placeholder, onChange, isOpened, openIndex=null, productIndex=null, openStatus=null }) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const dropdownRef = useRef();
  const itemRef = useRef();


  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };


  useEffect(()=> {
    if(openIndex === productIndex){
      setIsOpen(true)
    }
  }, [openStatus])
 
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    if (isOpen) {
      // Elyase ref bir DOM nesnesi değildir o sebeple querySelecter eklenemez
      // aşağıdaki koşulun çalışmadığını düşünüyorum
      const activeItem = itemRef.current.querySelector(".active");
      if (activeItem) {
        itemRef.current.scrollTop = activeItem.offsetTop - 72;
      }
    }
  }, [isOpen]);
  return (
    <div className='customer-dropdown-wrapper' ref={dropdownRef}>
      <button className={`customer-dropdown-wrapper__btn ${ isOpen ? 'active' : ''} ${value ? 'selected' : ''} `} onClick={() => setIsOpen(!isOpen)}>
        {value || placeholder}
        {!isOpen ? (
          <span className="arrow-icon" data-test="click-to-cart-page-add-arrow">
            <IconBottomArrow />
          </span>
        ) : (
          <span className="arrow-icon" data-test="click-to-cart-page-add-arrow">
            <IconBottomArrow />
          </span>
        )}
      </button>
      {isOpen && (
        <div className='customer-dropdown-wrapper-list' ref={itemRef}>
          {options.filter(opt => opt.is_in_stock).map((opt, index) => (
            <button
              className={`customer-dropdown-wrapper-list__btn ${(opt === value)  ? 'active' : ''}`}
              key={index}
              onClick={() => {
                onChange(opt.option_value, opt.sku);
                setIsOpen(false);
              }}
            >
              {opt.option_value}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
