import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import config from '@Config';
import { gettext } from '@Translate';
import { useOutsideClick } from '@Hooks';
import {
  CustomSelectdownAngle,
  ReebokAngle,
  IconBottomArrow,
  IconClear,
} from '@Icons';
import { IsArray } from '../../utils/IsArray';

const CustomSelect = ({
  list, title, filter, name, defaultValue, onChange, dataTest, type, id, isFocused, tabIndex,
}) => {
  const { BASE_URL, project } = config;
  const [show, setShow] = useState(false);
  const [head, setHead] = useState(title);
  const [cardNumber, setCardNumber] = useState();
  const [image, setImage] = useState();
  const [optionFilter, setOptionFilter] = useState();
  const [filterList, setfilterList] = useState();
  const inputCityFocus = useRef(null);
  useEffect(() => {
    setfilterList(list);
  }, [list]);

  useEffect(() => {
    if (defaultValue) {
      setHead(defaultValue);
    } else {
      setHead(title);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (type === 'card' && list) {
      let defaultItem = list.filter((item)=> defaultValue === item.name);
      if (defaultItem && defaultItem.length > 0) {
        setHead(defaultItem[0]?.name);
        setImage(defaultItem[0]?.card_image);
        setCardNumber(defaultItem[0]?.number);
      }
    }

    if (type === 'default' && list && !title && !defaultValue) {
      setHead(list[0]?.name);
    }
  }, []);

  const handleClickOutside = () => {
    setShow(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const optionClick = (event) => {
    setShow(false);
    onChange(event);
    setHead(event?.name);
    if (type === 'card') {
      setImage(event?.card_image);
      setCardNumber(event?.number);
    }
  };

  const inputAutoFocusEvent = () => {
    setTimeout(() => {
        inputCityFocus?.current?.focus()
    },1);
  };

  const optionsFilter = (data) => {
    if (list) {
      setOptionFilter(data);
      const newlist = list?.filter((element) => element?.name?.replace('İ', 'i').toLowerCase().indexOf(data.replace('İ', 'i').toLowerCase()) !== -1);
      setfilterList(newlist);
    }
  };

  const clearFiltres = () => {
    setfilterList(list);
    setOptionFilter('');
  };

  return (
    <div className={`select-search ${show ? 'show' : ''} ${list?.length === 1 ? 'pe-none' : ''} ${list?.length ? '' : 'disabled'} ${type === 'card' ? 'select-search--card' : null}`} ref={ref}>
      {
        type === 'card' ? (
          <div
            className={`select-search__head ${project}-select-search__head`}
            onClick={(e) => {
              setShow(!show);
            }}
            data-test={dataTest}
            tabIndex={list?.length ? tabIndex : null}
          >
            <span>
              {head}
              {' '}
              Kartım -
              {' '}
              {cardNumber?.toString().slice(12, 16)}
            </span>
            <img src={`${BASE_URL}${image}`} />
            {list?.length > 1
              ? (project === 'reebok' ? <ReebokAngle /> : <IconBottomArrow />)
              : null}
          </div>
        ) : (
          <div
            className={`select-search__head ${project}-select-search__head ${isFocused ? 'focused' : ''} ${defaultValue ? 'selected' : ''}`}
            onClick={(e) => {
              setShow(!show);
              inputAutoFocusEvent();
            }}
            data-test={dataTest}
            tabIndex={list?.length ? tabIndex : null}
          >
            <span>{head}</span>
            {list?.length > 1
              ? (project === 'reebok' ? <ReebokAngle /> : <CustomSelectdownAngle />)
              : null}
          </div>
        )
      }
      {filterList ? (
        <div className="select-search__body">
          {filter ? (
            <div className="select-search__filter">
              <svg xmlns="http://www.w3.org/2000/svg" width="14.526" height="14.851" viewBox="0 0 14.526 14.851">
                <g id="Search" transform="translate(0.5 0.5)">
                  <circle id="Ellipse_739" cx="6.38" cy="6.38" r="6.38" fill="none" stroke="#a7a7a7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                  <path id="Line_181" d="M0,0,2.5,2.495" transform="translate(10.818 11.149)" fill="none" stroke="#a7a7a7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </g>
              </svg>
              <input
                type="text"
                placeholder={gettext('Filtrele')}
                value={optionFilter}
                data-test="checkout-custom-select-change"
                ref={inputCityFocus}
                onChange={(e) => optionsFilter(e.target.value)}
              />
              {optionFilter && (
                <button className="select-search__close" onClick={() => clearFiltres()} type="button" data-test="checkout-custom-select-filter-button">
                  <IconClear />
                </button>
              )}
            </div>
          ) : (
            null
          )}
          {
            filterList ? (
              <div className={`select-search__wrapper ${filter ? 'select-search__wrapper--filter' : ''}`}>
                {
                  type === 'card' ? (
                    IsArray(filterList).map((item, index) => (
                      <div
                        data-card-type="{{ card_type }}"
                        data-program-name={item?.name}
                        data-platform={item?.platform}
                        data-id={item?.id}
                        className="select-search__item"
                        key={index.toString()}
                        onClick={() => optionClick(item)}
                        data-test={`checkout-custom-select-item-change-${item?.name?.toLowerCase()}`}
                      >
                        {item?.name}
                        {' '}
                        Kartım -
                        {' '}
                        {item?.number?.toString().slice(12, 16)}
                        <img src={`${BASE_URL}${item?.card_image}`} />
                      </div>
                    ))
                  ) : (
                    IsArray(filterList).map((item, index) => (
                      <div
                        className="select-search__item"
                        key={index.toString()}
                        onClick={() => optionClick(item)}
                        data-test={`checkout-custom-select-item-change-${item?.name?.toLowerCase()}`}
                      >
                        {item?.name}
                      </div>
                    ))
                  )
                }
              </div>
            ) : (
              null
            )
          }
        </div>
      ) : (
        null
      )}
    </div>
  );
};

CustomSelect.defaultProps = {
  title: '',
  name: '',
  defaultValue: '',
  filter: false,
  list: [],
  onChange: () => {},
  dataTest: '',
  type: '',
  id: '',
  isFocused: false,
};

CustomSelect.propTypes = {
  list: PropTypes.array,
  filter: PropTypes.bool,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  dataTest: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  isFocused: PropTypes.bool,
  tabIndex: PropTypes.string,
};

export default CustomSelect;
