import React from 'react';
import { gettext } from '@Translate';
import { toMoney } from '@Constants';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import {
  applyCouponAction,
  removeCouponAction
} from '@Actions';
import config from '@Config';
import { selectPromotionEvent } from '@Utils';

const DiscountCoupons = () => {
  const { project } = config;
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const pathName = urlLocation?.pathname;
  const { shoppingCart } = useSelector((state) => state.cart);
  const revampingCoupons = shoppingCart?.revampingCoupons

  const handleCoupon = async (couponCode, conditionMinAmount, isActive) => {
    if (isActive) {
      await dispatch(
        removeCouponAction({
          couponCode,
          getRevampingCoupons: pathName === '/basket' ? 1 : 0
        })
      );
    } else {
      if (couponCode) {
        await dispatch(
          applyCouponAction({
            couponCode,
            getRevampingCoupons: pathName === '/basket' ? 1 : 0,
            showInfoPopup: shoppingCart?.total < conditionMinAmount ? true : false
          }),
        ).then(({ payload }) => {
          const { status } = payload;
          if (status?.code === 200) {
            const selectedCoupons = revampingCoupons?.find(coupon => coupon.code === couponCode) || [{
              code: couponCode
            }]
            selectPromotionEvent(selectedCoupons)
          }
        });
      }
    }
  };

  if (shoppingCart) {
    if (!revampingCoupons || revampingCoupons?.length === 0) {
      return null
    }
  }

  const showNavigation = revampingCoupons.length > 4

  return (
    <div className={`discount-coupons discount-coupons--${project}`}>
      {showNavigation && (
        <>
          <div className='coupon-prev-btn swiper-button-prev' />
          <div className='coupon-next-btn swiper-button-next' />
        </>
      )}
      <div className='discount-coupons__container'>
        <div className='discount-coupons__title'>
          <h4>{gettext('İndirim Kuponları')}</h4>
        </div>
        <div className='discount-coupons__wrapper'>
          <Swiper
            spaceBetween={10}
            slidesPerView={1.75}
            loop = {false}
            navigation={showNavigation ? {
              prevEl: '.coupon-prev-btn',
              nextEl: '.coupon-next-btn',
            } : false}
            modules={[Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 1.75,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            key='discount-swiper'
          >
            {revampingCoupons.map(discountCouponsItem => (
              <SwiperSlide>
                <div className={discountCouponsItem.isApplied ? 'discount-coupons__item discount-coupons__item--selected' : 
                'discount-coupons__item'} key={discountCouponsItem.code}>
                  <div className='discount-coupons__label'>
                    {discountCouponsItem.label}
                  </div>
                  <div className='discount-coupons__lower-limit'>
                    <span>{gettext('Alt Limit:')}</span> {toMoney(discountCouponsItem.conditionMinimumAmount)}
                  </div>
                  <div className='discount-coupons__footer'>
                    <div className='discount-coupons__end-date'>
                      {discountCouponsItem.endDate.split(' ')[0]}
                    </div>
                    <div className='discount-coupons__action'>
                      {discountCouponsItem.isApplied ? 
                        <button className='remove-coupon' onClick={() => handleCoupon(discountCouponsItem.code, discountCouponsItem.conditionMinimumAmount, discountCouponsItem.isApplied)}>{gettext('Kaldır')}</button> : 
                        <button className='apply-coupon' onClick={() => handleCoupon(discountCouponsItem.code, discountCouponsItem.conditionMinimumAmount, discountCouponsItem.isApplied)}>{gettext('Uygula')}</button>}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
};

export default DiscountCoupons;
