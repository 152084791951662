import React from 'react';
import config from '@Config';
import InstallmentItem from './InstalmentItem'

const AllInstallments = (allInstallments) => {
  const { project } = config;
  return (
    <>
        <div class={`installment-content ${project}`}>
          <h3 class="installment-content__title">Tüm Taksit Seçenekleri</h3>
          <div class="installment-content__wrapper">
            {
              allInstallments.map((installment, index)=>(
                <InstallmentItem installment={installment}/>
              ))
            }
          </div>
        </div>
    </>
  );
};

export default AllInstallments;
