import React from 'react';
import Flowallet from '../../PaymentOptions/PaymentOptionContents/Flowallet';
import  CreditCard  from '../../PaymentOptions/PaymentOptionContents/CreditCard';
import GiftCertificate  from '../../PaymentOptions/PaymentOptionContents/GiftCertificate';
import DigitalPayments from '../../PaymentOptions/PaymentOptionContents/DijitalPayment';
import SubPaymentOptions from '../../PaymentOptions/PaymentOptionContents/SubPaymentOptions';
import { CashOnDelivery, DefaultInfo } from '../../PaymentOptions';

const Content = ({item, className, paymentType, subPaymentMethods }) => {
  const renderPaymentMethod = () => {
    switch (paymentType) {
      case 'flopay':
        return <CreditCard {...item}/>;
      // case 'flowallet':
      //   return <Flowallet item={item} />;
      case 'cashondelivery':
        return <CashOnDelivery />;
      case 'flowallet_cheque':
        return <GiftCertificate />
      case 'dijital_banka_odemeleri':
        return <DigitalPayments paymentType={paymentType} />;
      case 'other_payment_methods':
        return <SubPaymentOptions subPaymentMethods={subPaymentMethods} />;
      default:
        if (subPaymentMethods?.length > 0) {
          return <SubPaymentOptions subPaymentMethods={subPaymentMethods} />;
        }
        return <DefaultInfo {...item} />;
    }
  };
  return (
    <div className={className}>
        {renderPaymentMethod()}
    </div>
  )
}

export default Content;
