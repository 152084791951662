const data = {
  "’den alışveriş yaptığınız için teşekkür ederiz.": "Thanks for shopping from ...",
  "’ı Aktif edin siz d": "Activate x, and you too can ...",
  "’ı Aktif edin siz de": "Activate x, and you too can ...",
  "’ni kabul ediyorum.": "I accept ...",
  "’ni okudum.": "I read ...",
  "’si Kapıda Ödeme işlemi olduğu için hesabınıza nakit olarak aktarılabilir. Ücret iadenizi nakit olarak almak istiyorsanız lütfen": "Because x is cash on delivery, it can be transferred to your account as cash. If you want to get your refund as cash, please ...",
  "’ten alışveriş yaptığınız için teşekkür ederiz.": "Thanks for shopping from ...",
  "“Fatura Adresim” “Teslimat Adresim” ile Aynı": "“My billing address” is the same as my “delivery address”.",
  "/aydinlatma-metni": "/aydinlatma-metni",
  "/flo-cuzdan-kullanici-sozlesmesi": "/flo-cuzdan-kullanici-sozlesmesi",
  "/page?url=sss-desktop&tab=cuzdan": "/page?url=sss-desktop&tab=cuzdan",
  "1- Siparişiniz kargo aracılığıyla ilgili mağazamıza teslim edilecektir.": "1- Your order will be delivered to our store via cargo.",
  "2- Mağazadan teslimat seçeneği sadece belirli mağazalarımızda geçerlidir.": "2- Pick up from the store option is only viable in our selective stores.",
  "3- Mağazadan teslimat seçeneğinde ödemelerinizi sadece kredi kartı ile yapabilirsiniz": "3- With the pick up from the store option, you can do your payments only with credit card.",
  "4- Siparişiniz talep ettiğiniz mağazaya ulaştığında otomatik SMS ve e-mail gönderimi ile bilgi verilecektir.": "4- When your order arrives at the selected shop, you will be informed by automatic SMS and E-mail.",
  "5- Mağazalarımızdan siparişinizi teslim alırken sipariş no, ad, soyad, TC kimlik no ve size gönderilen güvenlik kodunuzu mağaza personeline bildirmeniz gerekmektedir.": "5- When picking up your order from our shops, you need to tell the shop staff your order number, name, surname, Turkish identity number and the security code that was sent to you.",
  "6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.": "Enter your phone number to receive your 6-digit authentication code.",
  "6- Mağazalarımıza teslim edilen siparişleriniz '3 iş günü içerisinde teslim alınmalıdır.'": "6- Your orders that arrive at our stores should be picked up in 3 workdays.",
  "7- Mağazalarımıza ulaşan siparişleriniz zamanında teslim alınmadığında iptal edilecektir.": "7- Your non-picked up orders that arrive to our stores will be cancelled if not picked up in time.",
  "8 karakter": "8 characters",
  "8- Mağazalarımızdan teslimat hergün 10:00 - 20:00 saatleri arasında yapılmaktadır.": "8- Deliveries from our stores happen between 10 am - 8 pm every day.",
  "Ad": "Name",
  "AD SOYAD": "NAME SURNAME",
  "Ad ve Soyad": "Name and Surname",
  "Adet": "Amount",
  "ADET:": "AMOUNT:",
  "Adınız": "Your Name",
  "Adres": "Address",
  "Adres Başlığı": "Address Header",
  "Adrese Teslimat": "Address Delivery",
  "Adrese Teslim": "Delivery to Address",
  "Adres/Ödeme": "Address/Payment",
  "Adresi Kaydet": "Save Address",
  "adresine bir doğrulama e-postası gönderelim.": "Let us send a confirmation e-mail to your address.",
  "Ağustos": "August",
  "aktarabilirsiniz.": "You can transfer.",
  "Aktarmak İstediğiniz Tutar Giriniz": "Enter the amount you would like to transfer",
  "ALIŞVERİŞE BAŞLA": "START SHOPPING!",
  "ALIŞVERİŞE DEVAM ET": "CONTINUE SHOPPING",
  "Alışveriş tutarınıza kargo firması tarafından talep edilen": "Demanded amount(?) from the cargo company for your shopping amount",
  "Alt Limit:": "Lower limit:",
  "Apple bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Apple information could not be verified, please try again.",
  "Apple ile Giriş Yap": "Log In With Apple",
  "Apple ile Kayıt Ol": "Sign Up With Apple",
  "Aralık": "December",
  "Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.": "The item(s) below are removed from your cart because they are out of stock.",
  "Ay": "Month",
  "aydinlatma-metni-mobile": "Clarification-text-mobile",
  "Aynı Gün Mağazadan Teslimat": "Same Day Delivery from Store",
  "Bakiye": "Balance",
  "BAKİYE YÜKLE": "TOP UP BALANCE",
  "Bakiye:": "BALANCE:",
  "Başka Bir Kart İle Ödeme Yap": "Pay With Another Card",
  "Başka Kredi Kartı İle Öde": "Pay With Another Credit Card",
  "Beden": "Size",
  "Beden:": "Size:",
  "Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.": "You can easily add the products you like to your shopping cart and share them with your friends.",
  "binlerce ürün ve model seni bekliyor.": "Thousands of products and models are waiting for you",
  "Bir tutar giriniz.": "Enter an amount.",
  "Bu alanın doldurulması zorunludur.": "It is mandatory to fill this area.",
  "Bu ekranı gördüğünüz için üzgünüz!": "We are sorry that you are seeing this screen!",
  "Bu ürünler": "These products",
  "Bu ürünün iadesini yalnızca Kargo ile gerçekleştirebilirsiniz.": "You may only refund this product by cargo.",
  "buraya": "here",
  "büyük harf": "capital letter",
  "Cep Telefonu": "Mobile Phone",
  "Cüzdan Detayları": "Wallet Details",
  "Cüzdana yüklemek istediğiniz tutarı giriniz.": "Enter the amount you wish to top up to your wallet.",
  "CVV": "CVV.",
  "değerinde": "worth",
  "daha ürün ekleyin.": "add more products.",
  "DEMO": "DEMO",
  "DENEME": "TRIAL",
  "DEVAM ET": "CONTINUE",
  "Devam Et": "Continue",
  "Devam etmek ister misiniz?": "Would you like to continue?",
  "Diğer Adreslerim": "My other addresses",
  "Düzenle": "Edit",
  "Doğrulama Kodu": "Confirmation Code",
  "Doğrulama E-postası Gönder": "Send Confirmation E-Mail",
  "E-Posta Adresi": "E-Mail Address",
  "E-Posta Adresiniz": "Your E-Mail Address",
  "Ekim": "October",
  "eklenecektir.": "will be added.",
  "En az": "At least",
  "En fazla": "At most",
  "En fazla 20 adet ürün ekleyebiliriz": "You can add 20 products at most",
  "Erkek": "Male",
  "Eylül": "September",
  "E-Posta Doğrulama": "E-Mail Verification",
  "Facebook bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Facebook information could not be verified, please try again.",
  "Facebook ile Giriş Yap": "Log In With Facebook",
  "Facebook ile Kayıt Ol": "Sign Up With Facebook.",
  "Fatura Adresi": "Billing Address",
  "Faturamı aynı adrese gönder": "Send my bill to the same address.",
  "Filtrele": "Filter",
  "FLO Cüzdan": "FLO Wallet",
  "FLO Cüzdan ile Öde": "Pay With FLO Wallet",
  "FLO Cüzdan ile Ödendi": "Paid With FLO Wallet",
  "Flo Cüzdan Kullanıcı Sözleşme": "Flo Wallet User Agreement",
  "Flo Cüzdan Kullanıcı Sözleşmesi": "Flo Wallet User Agreement",
  "FLO Cüzdan Oluştur": "Create FLO Wallet",
  "FLO Cüzdana Yüklendi ve Ödendi": "Loaded to FLO Wallet and Paid For",
  "FLO Cüzdanı Aktif Et ve Öde": "Actiave FLO Wallet and Pay",
  "Flo Cüzdanım": "My FLO Wallet",
  "FLO Cüzdanım": "My FLO Wallet",
  "FLO Cüzdanım’a Git": "Go To My FLO Wallet",
  "FLO Cüzdanınız´a": "FLO Cüzdanınıza",
  "FLO Cüzdanınızı aktif etmek için": "To activate your FLO Wallet",
  "FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni": "FLO Mağazacılık ve Pazarlama A.Ş. Customer Clarification Text",
  "Flo Müşteri Aydınlatma Metni": "Flo Customer Clarification Text",
  "FLO Para": "FLO Money",
  "FLO Para Geri Kazan": "FLO Money Win Back",
  "Flo Para Kazan": "Win FLO Money",
  "FLO Para Kazan": "Win FLO Money",
  "FLO PARA KAZANDIN!": "YOU WON FLO MONEY!",
  "FLO Para Kullan": "Use FLO Money",
  "FLO Paralarınız karta iade edilemez.": "Your FLO Money can not be refunded to your card.",
  "flo-cuzdan-kullanici-sozlesmesi-app": "Flo-wallet-user-agreement-app",
  "FLO Plus ile Hızlı Öde": "Pay Fast With FLO Plus",
  "Geçerli telefon numarası giriniz.": "Enter viable phone number.",
  "Geçerliliğini Kaybedecek FLO Paralarım": "My FLO Money That Will Lose Viability",
  "geçerlilik": "viability",
  "Geçerlilik süresini yitirecek olan FLO Paralar aşağıda aylık olarak listelenmektedir.": "Flo Money that will lose viability are listed below monthly.",
  "Genel Toplam": "Total Sum",
  "Giriş": "Entrance",
  "Giriş Yap": "Sign In",
  "GİRİŞ YAP": "SIGN IN",
  "GÖNDER": "SEND",
  "Google bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Google information could not be verified, please try again.",
  "Google ile Giriş Yap": "Sign In With Google",
  "Google ile Kayıt Ol": "Sign Up With Google",
  "Güncel": "Current/Up to date",
  "Güvenli Alışveriş ve Ödeme": "Secure Shopping and Payment",
  "Hata! Lütfen tekrar deneyiniz.": "Error! Please try again.",
  "Haziran": "June",
  "Hemen Aktif Et": "Activate Immediately",
  "Hemen Üye Ol": "Sign Up Now",
  "Hesabınıza en fazla": "To your account, at most",
  "Hesap Özetim": "My Account Summary",
  "Hızlı Yükle": "Load Fast",
  "İade Edilecek Kart": "Refund Card",
  "İade İşlemini Onayla": "Accept Refund Process",
  "İade işlemlerinde fatura ibrazı zorunludur, faturasız iade kabul edilmemektedir.": "Submission of an invoice is mandatory in return transactions, returns without an invoice are not accepted.",
  "IBAN Numaranız": "Your IBAN Number",
  "IBAN numaranız yukarıda vermiş olduğunuz ‘’Ad ve Soyad’’ bilgileriyle eşleşmelidir.": "Your IBAN number should match the 'Name and Surname' information you have given above.",
  "İl": "City",
  "İl Seçiniz": "Choose City",
  "İlçe": "District",
  "İlçe Seçiniz": "Choose District",
  "İndirim kodunuz varsa lütfen giriniz": "Please enter if you have a discount code",
  "İptal": "Cancelation",
  "İptal Et": "Cancel",
  "İşlem Gerçekleştiriliyor...": "The Operation is Being Performed...",
  "İndirimli": "Discounted",
  "Kadın": "Woman",
  "Kaldır": "Remove",
  "Kalan Süreniz:": "Remaining Time:",
  "Kampanya": "campaign",
  "Kampanya dönemlerinde yapılan sürpriz indirimlerden, stoklar ve bedenler tükenmeden ilk sizin haberiniz olsun.": "Get notified first for the surprise discounts that are done during campaign season before they are out of stock and size.",
  "Kampanya Kodu": "Campaign Code",
  "Kapıda kredi kartı ile ödeme kabul edilmemektedir": "Payment on delilvery with a credit card is not accepted",
  "Kapıda Nakit Ödeme": "Cash on Delivery",
  "Kapıda Ödeme": "Payment on Delivery",
  "Kapıda ödeme seçeneğinde sadece nakit ödeme kabul edilmektedir.": "Only cash is accepted for payment on delivery.",
  "Kapıda ödeme seçeneğiyle alınan ürünlerin iadesi mağazalarımızdan veya iade adresimize gönderilerek yapılabilir": "Products that are bought through payment on delivery can be refunded at our stores or by sending the product to our refund address",
  "kapıda ödeme ücreti": "Payment on Delivery Fee",
  "Kargo Bedava": "Free Delivery",
  "Kargo": "Delivery",
  "Kargo Ücreti": "Delivery Fee",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin <strong>Kargo Bedava</strong> olması gerekmektedir.": "For us to pay the delivery fee, all the products in your cart have to be 'Free Delivery'.",
  "Kargo bedava olması için": "For the delivery to be free:",
  "Kart Bilgileri": "Card Information",
  "Kart Numarası": "Card Number",
  "Karta Yüklenebilir FLO Para": "Flo Money Available to be Loaded to Card",
  "Kartıma Geri Yükle": "Refund To My Card",
  "Kartınıza en fazla": "To your card, at most",
  "Kartınıza geri yükleme süresi bankalara bağlı olarak 2-10 iş günü arasında sürmektedir.": "Time to refund to your card can be between 2-10 workdays depending on banks.",
  "Kartınızın arka yüzündeki son 3 rakamı yazınız.": "Enter the last 3 numbers at the back of your card.",
  "Kasım": "November",
  "KAYDET": "SAVE",
  "Kayıtlı Kart İle Devam Et": "Continue With the Saved Card",
  "kazanabilirdiniz.": "you could have won.",
  "Kazandın!": "You Won!",
  "Kazanmaya Başlayın!": "Start Winning!",
  "Kendim": "Myself",
  "Kodu Tekrar Gönder": "Resend the Code",
  "Kolay İade Seçeneği": "Easy Refund Option",
  "Kolay İade' Butonuyla hızlıca yapabilirsiniz.": "You can do it quickly with the 'Easy Refund' button.",
  "Kredi Kartı ile Yükleme Yap": "Load With Credit Card",
  "Kredi Kartımı Sakla": "Keep My Credit Card",
  "Kopyala": "Copy",
  "Kopyalandı": "Copied",
  "Kullan": "Use",
  "Kupon Kodunu Yazınız": "Write the Coupon Code",
  "Kurumsal Fatura İstiyorum": "I want Corporate Bill",
  "Lütfen 3 karakter uzunluğunda bir değer giriniz": "Please enter a value that is 3 characters long",
  "Lütfen bilgileri eksiksiz doldurunuz": "Please fill the information complete",
  "Lütfen bu alanı doldurunuz": "Please fill this area",
  "Lütfen geçerli bir telefon girin.": "Please enter a viable phone.",
  "Lütfen geçerli bir tutar giriniz.": "Please enter a viable amount.",
  "Lütfen IBAN Numaranızı Girin": "Please Enter Your IBAN Number",
  "Lütfen Kredi Kart Bilgilerinizi Yazın": "Please Write Your Credit Card Information",
  "Lütfen sayfayı yeniden yükleyin.": "Please reload the page.",
  "Lütfen T.C. kimlik numaranızı kontrol ediniz!": "Please check your T.C. identity number!",
  "Lütfen vergi kimlik numaranızı kontrol ediniz!": "Please check your tax identity number!",
  "Mağaza Adresi": "Store Address",
  "Mağazadan": "From Store",
  "Mağazadan nasıl teslim alırım?": "How can i acquire it from the store?",
  "Mağazadan Teslimat Bilgileri": "Information on Delivery From Store",
  "Mağazadan Teslimat": "Delivery From Store",
  "Mağazalardan nakit iade yapılmamaktadır": "No cash refund from stores",
  "Mağazasından": "From it's store?",
  "Mahalle, cadde, sokak ve diğer bilgilerinizi giriniz.": "Enter your neighbourhood, avenue, street and other information.",
  "Mahalle": "Neighbourhood",
  "Mart": "March",
  "Mayıs": "May",
  "Mesafeli Satış Sözleşmesini": "Distance Sales Contract",
  "Mobil Uygulamamız ile Sürpriz Avantajlar": "Surprise Advantages With Our Mobile App",
  "Mobil Uygulamasına özel yaptığımız etkinliklerden ilk sizin haberiniz olsun.": "Get notified first of the special events we make for our mobile app.",
  "Nisan": "April",
  "numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.": "enter the 6-digit verification code sent to your ...... number.",
  "Ocak": "January",
  "otp-form-text-1": "otp-form-text-1",
  "Ödeme": "Payment",
  "ÖDEME": "PAYMENT",
  "ÖDEME ADIMINA GEÇ": "PROCEED TO THE PAYMENT STEP",
  "Ödeme Seçenekleri": "Payment Options",
  "Ödeme Yöntemi": "Payment Method",
  "Ödeme Bilgileri": "Payment Information",
  "ÖDEMEYİ TAMAMLA": "COMPLETE PAYMENT",
  "Ödenecek Tutar": "Amount TO BE PAID",
  "okudum onaylıyorum": "I read and accept",
  "olması gerekmektedir.": "has to be.",
  "Ön Bilgilendirme Formunu": "Pre Informing Form",
  "Onay Kodu": "Confirmation Code",
  "ONAYLA": "CONFIRM",
  "Onayla": "Confirm",
  "Önceden Sepete Eklediklerim": "Things I Previously Added To My Cart:",
  "rakam giriniz": "Enter number",
  "Sana en yakın mağazadan gel al.": "Come and get from the closest store to you.",
  "Satın aldığınız ürünlerin iadelerini": "The refunds of the products you purchased",
  "Sayfa Bulunamadı": "Couldn't Find the Page",
  "Sepet": "Cart",
  "Sepete Ekle": "Add To Cart",
  "SEPETİ ONAYLA": "Confirm the Cart",
  "Sepetim": "My Cart",
  "Sepetinizde ürün bulunmamaktadır.": "No products found in your cart.",
  "Sepetini Arkadaşınla Paylaş": "Share Your Cart With Your Friend!",
  "Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?": "Would you like to add a product to your favorites after you remove it from cart?",
  "Sepetinizdeki Ürünün Stoğu Tükendi!": "The Product In Your Cart Is Out Of Stock!",
  "Sepette": "In Cart",
  "Siparişlerin ve güncel fırsatlarla ilgili e-postaları almaya devam etmek için": "To continue getting e-mails about your orders and current opportunities:",
  "Şifre": "Password",
  "Şifremi Unuttum": "Forgot Password",
  "Şifreniz": "Your Password",
  "Sil": "Delete",
  "Sil ve Favorilere Ekle": "Delete and Add To Favorites",
  "Sipariş No:": "Order No:",
  "Sipariş Numarası:": "Order Number:",
  "Sipariş Özeti": "Order Summary",
  "Siparişime Git": "Go To My Order",
  "SİPARİŞİNİZ BAŞARI İLE TAMAMLANMIŞTIR": "YOUR ORDER HAS BEEN COMPLETED SUCCESSFULLY",
  "SİPARİŞLERİM": "MY ORDERS",
  "Şirket Adı": "Company Name",
  "Size Özel Kupon Fırsatları": "Coupon Opportunities Just For You",
  "Son Kullanma Tarihi:": "Date of Last Use:",
  "SONRAKİ ADIMA DEVAM ET": "CONTINUE WITH THE NEXT STEP",
  "Soyad": "Surname",
  "Soyadınız": "Your Surname",
  "Sözleşme ve Formlar": "Agreement and Forms",
  "Standart Teslimat": "Standard Delivery",
  "Şubat": "February",
  "Tahmini Kargoya Teslimat :": "Eta :",
  "Tahmini Kargoya Veriliş Tarihi:": "Estimated Time To be Given To Delivery:",
  "Taksit": "Payment",
  "Taksit Seçenekleri": "Payment Options",
  "Taksit seçenekleri kart bilgilerinizi girdikten sonra güncellenecektir.": "Payment options will be updated after you enter your card information.",
  "TAMAM": "OKAY",
  "tarafından gönderilecektir.": "will be sent by.",
  "Tek Çekim": "One Payment",
  "Tekrar SMS Gönder": "Resend SMS",
  "Telefon Numaranız": "Your Phone Number",
  "Telefon numaranızı kontrol ediniz.": "Check your phone number.",
  "telefon numarasına gönderilen 6 haneli kodu giriniz.": "Enter the 6-digit code sent to your phone number.",
  "Temmuz": "July",
  "Teşekkürler": "Thanks",
  "Teslim Alacağım": "I Will Take Delivery",
  "teslim edilecektir": "will be delivered",
  "Teslimat Adresi": "Delivery Address",
  "Teslimat Bilgileri": "Delivery Information",
  "Teslimat Seçenekleri": "Delivery Options",
  "TEST": "TEST",
  "tıklayın.": "click.",
  "Toplam": "Total",
  "TL": "EUR.",
  "TL ’den": "from EUR",
  "TL bakiye yükleyebilirsiniz.": "you can upload EUR balance.",
  "TL en fazla": "EUR at most",
  "TL hesabınıza iade edebilirsiniz.": "you can refund to your EUR account.",
  "TL iade edilebilir.": "EUR is refundable.",
  "TL kartınıza iade edebilirsiniz.": "you can refund .... EUR to your card.",
  "TL’ye yükselmiştir.": "has increased to .... EUR",
  "tr": "eu",
  "TR99 9999 9999 9999 9999 9999 99": "TR99 9999 9999 9999 9999 99",
  "TRY": "TURKISH LIRA",
  "tutarında yükleme yapıldı ve ödeme işlemi tamamlandı.:": "an .... amount of loading has been done and the payment process is completed.",
  "ÜCRETSİZ": "FREE",
  "Ücretsiz": "Free",
  "Ücretsiz Kargo": "Free Delivery",
  "Ürün": "Producy",
  "ürün": "Producy",
  "Ürünler": "Products",
  "ürün daha ekleyin Kargo Bedava olsun.": "add .... more producys and get free delivery.",
  "Ürün Var": "Product Is Available",
  "Ürünlerinizi aşağıda adresi bulunan mağazamızdan teslim alabilirsiniz.": "You can receive your products from our store that has it's address given below.",
  "uyarınca işlenecektir.": "will be processed when warned.",
  "ÜYE OL": "SIGN UP",
  "HESAP OLUŞTUR": "CREATE ACCOUNT",
  "Üye olmadan": "Without signing up",
  "ÜYE OLMADAN ALIŞVERİŞ": "SHOPPING WITHOUT SIGNING UP",
  "Üye Olmadan Devam Et": "Continue Without Signing Up",
  "üyeleri kapıda nakit ödeme avantajından yararlanabilirler.": "members can benefit from cash on delivery payment advantages.",
  "Üyeleri, hiç kullanmadığı ürünleri 14 gün içerisinde hızlı ve kolay bir işlemle iade edebilme avantajından yararlanırlar.": "members can benefit from refunding their products that weren't used at all in 14 days with a quick and easy process.",
  "Üyelerimize özel yaptığımız kupon etkinliklerinden faydalanın": "Benefit from the coupon events we organize specifically for our members",
  "ÜYELİK AVANTAJLARI": "MEMBERSHIP BENEFITS",
  "uyelik_sozlesmesi": "membership_agreement",
  "Uygula": "Apply",
  "Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız": "Because your applied coupon isn't available with the discount in your cart, your cart amount",
  "VAZGEÇ": "GIVE UP",
  "Vazgeç": "Give Up",
  "ve": "and",
  "ve üzeri ücretsiz kargo": " and above free delivery",
  "Vergi Dairesi": "Tax Authority",
  "Vergi/ TC Kimlik No": "Tax / TC Identity No",
  "Vergi/ TC Kimlik No hatalı kontrol ediniz.": "Tax / TC Identity No is wrong, please check again.",
  "veya": "or",
  "ya Giriş Yapın": "Sign In ...",
  "Yapmış olduğun alışverişten": "From the purchase you have made",
  "Yeni Adres Ekle": "Add New Address",
  "Yıl": "Year",
  "Yüklediğin Para": "The Money You Have Loaded",
  "Yükleniyor": "Loading",
  "Tahmini Teslimat :": "Eta :",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin": "For us to pay delivery fee, all the products in your cart",
  "SEPETE GİT": "GO TO CART",
  "SİPARİŞİ TAMAMLA": "COMPLETE ORDER",
  "Siparişiniz hazırlandığında e-posta ile sizi bilgilendireceğiz.": "We will notify you with an e-mail when your order is prepared.",
  "Siparişinizle ilgili gelişmeleri Siparişlerim sayfasından takip edebilirsiniz.": "You can follow the updates on your order from 'My Orders' page.",
  "Lütfen geçerli bir eposta adresi yazın": "Please enter a viable e-mail address",
  "Kayıtlı kullanıcı bulunamadı": "No registered used found",
  "istenilen miktarda ürün bulunmamaktadır.": "Products in the wanted amount can not be found.",
  "Kredi / Banka Kartı": "Credit / Bank Card",
  "Lütfen geçerli bir numara giriniz.": "Please enter a viable number.",
  "Adres bilgisi 10 karakterden fazla olmalıdır.": "Address information must be more that 10 characters.",
  "Lütfen en az 5 karakter uzunluğunda bir değer giriniz.": "Please enter a value at least 5 characters long.",
  "Lütfen en az 10 karakter uzunluğunda bir değer giriniz.": "Please enter a value at least 10 characters long.",
  "E-Posta Adresinizi Doğrulayın": "Confirm Your E-Mail Address",
  "Lütfen kupon kodunu giriniz.": "Please enter coupon code.",
  "1- Mağazadan teslimat seçeneğinde il ve ilçe seçimi yaptıktan sonra teslim alınabilecek mağazaları görüntüleyebilirsiniz.": "1- For the receive from store option, you can see the stores that you can receive from only after you choose city and district.",
  "2- Siparişiniz teslim almak için hazırlandığında SMS ve e-posta ile bilgi paylaşılacaktır.": "2- When your order is being prepared for pick up, you will be notified by SMS and E-Mail.",
  "3- Siparişinizi mağazadan teslim alırken SMS ile gönderilen güvenlik kodunu mağaza çalışanı ile paylaşmanız gerekmektedir.": "3- When picking up your order from store, you have to share the security code sent via SMS with the store employee.",
  "4- Siparişiniz hazırlandıktan sonra 10 gün içerisinde mağazadan teslim alınmalıdır.": "4- Your order needs to be picked up from the store 10 days after it is prepared.",
  "5- Mağazadan 10 gün içerisinde teslim alınmayan siparişin iade girişi yapılacaktır.": "5- If an order is not picked up until 10 days after it is prepared, the order will be refunded.",
  "6- Siparişinizi mağazadan her gün 10:00 - 21:00 saatleri arasında teslim alabilirsiniz.": "6- You can pick your order up from the store every day between 10 am-9 pm.",
  "7- Saat 19:00'a kadar verdiğiniz siparişinizi aynı gün 21:00'a kadar teslim alabilirsiniz.": "7- You can pick up your order on the same day until 9 pm if it was given before 7 pm that same day.",
  "Loyalty": "Sadakat",
  "Puanı hemen kullan.": "Use points immediately.",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Please enter a value that is at least 3 characters long.",
  "Önceden Eklediklerim": "Previously Added",
};

export default data;
