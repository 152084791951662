import React from 'react';
import { useSelector } from 'react-redux'
import { gettext } from '@Translate';
import Card from './Card';
import { Swiper, SwiperSlide } from 'swiper/react';
import config from '@Config';
import { Navigation } from 'swiper/modules';
import { IsArray } from '../../../utils/IsArray';

const SpecialCampaign = () => {
  const { project } = config;
  const { shoppingCart } = useSelector((state) => state.cart)
  const campaignProducts = shoppingCart.widgetBasketPromotionItems
  if (!campaignProducts.length){
    return null
  }

  const showNavigation = campaignProducts.length > 2

  return(
    <div className={`special-campaign special-campaign--${project}`}>
      {showNavigation && (
        <>
          <div className='prev-btn swiper-button-prev' />
          <div className='next-btn swiper-button-next' />
        </>
      )}
      <div className='special-campaign-card-wrapper'>
        <h4 className='special-campaign-title'>{gettext('Sepetine Özel İndirimler')}</h4>
        <Swiper
          spaceBetween={10}
          slidesPerView={1.40}
          loop = {false}
          navigation={showNavigation ? {
            prevEl: '.prev-btn',
            nextEl: '.next-btn',
          } : false}
          modules={[Navigation]}
          breakpoints={{
            640: {
              slidesPerView: 1.40,
            },
            768: {
              slidesPerView: 2.75,
            },
            1024: {
              slidesPerView: 2.75,
            },
          }}
        >
          {IsArray(campaignProducts)?.map((campaignProduct, campaignProductIndex) => (
            <SwiperSlide key={campaignProductIndex}>
              <Card product={campaignProduct} productIndex={campaignProductIndex} grandTotal={shoppingCart.grandTotal} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default SpecialCampaign
