// Import project configuration
import config from '@Config';
const { currency, project } = config;

// Define allowed project names
const allowedProjectNames = ['sneakerbox'];

// Utility function to check if the current project matches the required project
const isCurrentProjectValid = (currentProject) => allowedProjectNames.includes(currentProject);

// Utility function to update the data layer with given event and data
const updateDataLayer = (event, data) => {
  if (!isCurrentProjectValid(project)) return;

  window.dataLayer = window.dataLayer || [];
  dataLayer.push({ event, 'ecommerce' : {
    ...data 
  }});
};

// Utility function to format product data for the data layer
const formatProductData = (product, quantityAdjustment = 0) => ({
  'item_id': product?.parentSku || product?.sku,
  'item_name': product?.name,
  'price': product?.secondPrice > 0 ? product.secondPrice.toString() : (product.firstPrice || product.price).toString(),
  'discount': product?.secondPrice > 0 ? ((product.firstPrice || product.price) - product.secondPrice).toString() : '0',
  'currency': currency,
  'item_brand': product?.brand,
  'item_category': product?.category?.name || '',
  'item_variant': product?.color,
  'quantity': (Number(product?.quantity) + quantityAdjustment).toString(),
});

const formatPurchaseData = (transactionDetails, items) => {
  const formattedTransactionData = {
    'transaction_id': transactionDetails?.incrementId,
    'value': transactionDetails?.grandTotal?.toString(),
    'shipping': transactionDetails?.shippingTotal?.toString(),
    'currency': currency,
    'coupon': transactionDetails?.couponCode || '',
  };

  const formattedItemsData = items?.map(item => formatProductData(item));

  return { ...formattedTransactionData, 'items': formattedItemsData };
};

// Events
export const addToCartEvent = (product) => {
  const eventData = formatProductData(product);
  updateDataLayer('add_to_cart', { items: [eventData] });
};

export const removeFromCartEvent = (product) => {
  const eventData = formatProductData(product);
  updateDataLayer('remove_from_cart', { items: [eventData] });
};

export const viewCartEvent = (items) => {
  const eventData = items?.map(item => formatProductData(item));
  updateDataLayer('view_cart', {  items: eventData });
};

export const beginCheckoutEvent = (items) => {
  const eventData = items?.map(item => formatProductData(item));
  updateDataLayer('begin_checkout', { items: eventData });
};

export const addShippingInfoEvent = (shippingTier, items) => {
  const eventData = items?.map(item => formatProductData(item));
  updateDataLayer('add_shipping_info', { shipping_tier: shippingTier, items: eventData });
};

export const addPaymentInfoEvent = (paymentType, items) => {
  const eventData = items?.map(item => formatProductData(item));
  updateDataLayer('add_payment_info', { payment_type: paymentType, items: eventData });
};

export const completePurchaseEvent = (transactionDetails, items) => {
  const purchaseData = formatPurchaseData(transactionDetails, items);
  updateDataLayer('purchase', purchaseData);
};

export const addToWishlistEvent = (product, index) => {
  const eventData = { ...formatProductData(product), 'index': (index + 1).toString() };
  updateDataLayer('add_to_wishlist', { items: [eventData] });
};

export const viewPromotionEvent = (promotions) => {
  const promotionData = promotions?.map(promo => ({
    promotion_id: promo.code,
    promotion_name: promo.name,
    creative_name: promo.campaignType,
  }));
  updateDataLayer('view_promotion', { promotions: promotionData });
};

export const selectPromotionEvent = (promotions) => {
  const promotionData = {
    promotion_id: promotions.code,
    promotion_name: promotions.name,
    creative_name: promotions.campaignType,
  };
  updateDataLayer('select_promotion', { promotions: promotionData });
};
