import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const CustomBadge = ({ className, children, rate }) => {
  let rateClass = '';

  if (rate) {
    if (children >= 8) {
      rateClass = 'rate__very-good';
    } else if (children >= 6) {
      rateClass = 'rate__good';
    } else if (children >= 4) {
      rateClass = 'rate__mid';
    } else if (children >= 2) {
      rateClass = 'rate__not-bad';
    } else if (children >= 1) {
      rateClass = 'rate__bad';
    }
  }

  return (
    children > 1 ? (
      <span className={`custom-badge ${className} ${rateClass}`}>
        {
          children < 10 ? (
            Number.isInteger(children) ? (
              <em>
                {children}
                {'.0'}
              </em>
            ):(
              String(children).substring(0,3)
            )
          ):(
            children
          )
        }
      </span>
    ) : null
  );
};

CustomBadge.defaultProps = {
  className: '',
  rate: true,
};

CustomBadge.propTypes = {
  className: PropTypes.string.isRequired,
  rate: PropTypes.bool.isRequired
};

export default CustomBadge;
