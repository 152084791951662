import React from 'react';
import { toMoney } from '@Constants';

export default ({ product, project }) => (
  <div className={`prev-add-card__price-container ${project}-prev-add-card__price-container`}>
    <div className="prev-add-card__price-wrapper">
      {(product?.secondPrice > 0 && !product?.storeMode) && (
        <div className="cart__price--old">
          <span>
            {toMoney(product?.firstPrice)}
          </span>
        </div>
      )}
      <div className={`prev-add-card__price--actual primary-color ${project === 'sneakerbox' ? 'sneakerbox-primary-color' : ''}`}>
        {
          product?.secondPrice > 0
            ? <span className={`${project}-second-price`}>{toMoney(product?.secondPrice)}</span>
            : toMoney(product?.firstPrice) || toMoney(product?.price)
        }
      </div>
    </div>
  </div>
)

