/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast, Zoom } from 'react-toastify';
import { ToastfyMessage, ToastifyDisaster } from '@Components';
import { delay } from '@Constants';
import API from '@API';
import {
  hidePopupAction,
  setBasketItemLimitAction,
  showPopupAction,
  isTopPopupAction,
  cartActionRequest,
} from '@Actions';
import Cookie from 'js-cookie';

import OutOfStock from '../components/OutOfStock';
import AgtErrorPopup from '../components/AgtErrorPopup/AgtErrorPopup';
import config from '@Config';
import { isDisasterPopupAction } from './popup';

const { BASE_URL } = config;

export const getSummoningShoppingCartIdAction = createAsyncThunk(
  'summoning/cart',
  async (arg, { dispatch, getState }) => {
    const {
      basketInfo,
    } = getState().cart;
    const response = await API.getSummoningShoppingCartId({...basketInfo, ...arg });
    return { ...response.data, response: arg };
  }
);

export const getCustomerRequest = createAsyncThunk(
  'getCustomer',
  async (arg, { dispatch }) => {
    const response = await API.getCustomer({ ...arg });
    return response.data;
  }
);

export const deliveryOptionsRequest = createAsyncThunk(
  'getDeliveryOptions',
  async (arg, { dispatch, getState  }) => {
    const response = await API.getDeliveryOptions({ ...arg });
    const { guest } = getState().auth;
    return {...response.data , guest};
  }
);

export const updateCustomerAddressAction = createAsyncThunk(
  'updateCustomerAddress',
  async ({
    shippingAddress, isCorporate, hidePopup, addressType,
  }, { dispatch }) => {
    const address = { ...shippingAddress };
    delete address.callback;
    address.county = address?.countyName;
    address.city = address?.cityName;
    address.city = address?.cityName;
    address.addressId = address?.customerAddressId;

    if (addressType === 'billingAddress') {
      address.type = 'billing';
    } else if (addressType === 'shippingAddress') {
      address.type = 'shipping';
    }

    if (!isCorporate && !shippingAddress.corporateAddress) {
      address.taxOffice = '';
      address.taxNumber = '';
      address.companyName = '';
    }

    const response = await API.updateCustomerAddress(address);
    if (hidePopup && !response.data.showVerifiedCode && ((typeof response.data.status === 'boolean' && response.data.status !== false) || response.data?.status?.code === 200)) {
      dispatch(hidePopupAction());
    }
    if (response.data?.status?.code === 200 && !response.data.showVerifiedCode) {
      dispatch(hidePopupAction());
    }
    return response.data;
  }
);

export const setDefaultPickupBillingAddress = createAsyncThunk(
  'setDefaultPickupBillingAddress',
  async (
    { shippingAddress, billingAddress, redirect, type },
    { dispatch, getState }
  ) => {

    const data = {
      shippingAddress,
      billingAddress: {
        addressId: billingAddress?.customerAddressId,
      },
      shippingMethod: 'FloShippingAras',
      useShippingAddressAsBillingAddress : false
    };

    const response = await dispatch(
      setCustomerAddressAction({
        data,
      })
    );

    return response.payload;
  }
);

export const setDefaultBillingAddress = createAsyncThunk(
  'setDefaultBillingAddress',
  async (
    { shippingAddress, billingAddress, redirect, type },
    { dispatch, getState }
  ) => {
    dispatch(
      customerDefaultBillingAddressActionReceive(billingAddress)
    );
    dispatch(
      customerDefaultShippingAddressActionReceive(shippingAddress)
    );

    const {
      useShippingAddressAsBillingAddress,
    } = getState().order;


    const data = {
      gift_message: '',
      receiver_name: '',
      shippingAddress: {
        addressId: shippingAddress?.customerAddressId,
      },
      billingAddress: {
        addressId: billingAddress?.customerAddressId,
      },
      shippingMethod: 'FloShippingAras',
      useShippingAddressAsBillingAddress,
    };

    const response = dispatch(
      setCustomerAddressAction({
        data,
      })
    );

    return response.payload;
  }
);

export const setDefaultShippingAddress = createAsyncThunk(
  'setDefaultShippingAddress',
  async (
    { shippingAddress, redirect, type, },
    { dispatch, getState }
  ) => {

    const {
      useShippingAddressAsBillingAddress,
    } = getState().order;
    if (!useShippingAddressAsBillingAddress) {
      dispatch(
        customerDefaultBillingAddressActionReceive(shippingAddress)
      );
    }
    dispatch(
      customerDefaultShippingAddressActionReceive(shippingAddress)
    );


    const data = {
      gift_message: '',
      receiver_name: '',
      shippingAddress: {
        addressId: shippingAddress?.customerAddressId,
        storeId: 0,
      },
      shippingMethod: 'FloShippingAras',
      useShippingAddressAsBillingAddress,
    };

    const response = await dispatch(
      setCustomerAddressAction({
        data,
      })
    );

    return response.payload;
  }
);

export const insertCustomerAddressAction = createAsyncThunk(
  'insertCustomerAddress',
  async (
    {
      shippingAddress,
      billingAddress,
      redirect,
      type,
      sameAddress,
      hidePopup,
      addressType,
      repeatCode
    },
    { dispatch, getState }
  ) => {
    const { useShippingAddressAsBillingAddress } = getState().order;
    const { guest } = getState().auth;

    const addressClone = { ...shippingAddress };
    addressClone.county = addressClone?.countyName;
    addressClone.city = addressClone?.cityName;

    if (addressType === 'billingAddress') {
      addressClone.type = 'billing';
    } else if (addressType === 'shippingAddress') {
      addressClone.type = 'shipping';
    }

    if(repeatCode) {
      addressClone.repeatCode = repeatCode
    }

    const response = await API.insertCustomerAddress(addressClone);

    let address = null;
    if(response?.data?.address) {
      let addressClone = {...response.data.address}
      addressClone.otp = '';
      address = addressClone
    } else {
      address = (response.data?.showVerifiedCode || response.data?.otpCode === false) ? null : {...response.data.address}
    }

    let response2 = null;

    if (!sameAddress && billingAddress) {
      await delay(100);
      const addressClone2 = { ...billingAddress };
      addressClone2.county = addressClone2.countyName;
      addressClone2.city = addressClone2.cityName;
      response2 = await API.insertCustomerAddress(addressClone2);
    }

    if (!redirect && addressType === 'shippingAddress' && address && Object.keys(address).length  > 0    ) {
      dispatch(
        customerDefaultShippingAddressActionReceive(
          address
        )
      );
      if(!useShippingAddressAsBillingAddress && response?.data?.customerAddresses.length <= 1 && !billingAddress && !guest){
        dispatch(
          customerDefaultBillingAddressActionReceive(
            address
          )
        );
      }
    }

    if (!redirect && addressType === 'billingAddress' && !response2 && address && Object.keys(address).length  > 0) {
      dispatch(
        customerDefaultBillingAddressActionReceive(
          address
        )
      );
    }

    if (
      useShippingAddressAsBillingAddress &&
      !guest &&
      address &&
      Object.keys(address).length  > 0 &&
      !redirect
    ) {
      dispatch(
        customerDefaultShippingAddressActionReceive(
          address
        )
      );
      dispatch(
        customerDefaultBillingAddressActionReceive(
          address
        )
      );
    }

    if (
      !useShippingAddressAsBillingAddress &&
      !redirect &&
      response2
    ) {
      dispatch(
        customerDefaultShippingAddressActionReceive(
          response.data.address
        )
      );
      dispatch(
        customerDefaultBillingAddressActionReceive(
          response2.data.address
        )
      );
    }

    if (
      response?.data?.status?.code === 200 &&
      useShippingAddressAsBillingAddress &&
      redirect
    ) {
      dispatch(
        setDefaultShippingAddress({
          shippingAddress: response?.data?.address,
          billingAddress: response?.data?.address,
          redirect,
          type,
        })
      );
    }
    if (
      response.data?.status?.code === 200 &&
      response2?.data?.status?.code === 200 &&
      !useShippingAddressAsBillingAddress &&
      redirect
    ) {
      dispatch(
        setDefaultBillingAddress({
          shippingAddress: response?.data?.address,
          billingAddress: response2?.data?.address,
          redirect,
          type,
        })
      );
    }

    if (response.data?.status?.code === 200 && !response.data?.showVerifiedCode) {
      dispatch(hidePopupAction());
    }

    if (response2?.data) {
      return response2.data;
    }

    return response.data;
  }
);

export const insertPickupBillingAddressAction = createAsyncThunk(
  'insertCustomerAddress',
  async (
    {
      shippingAddress,
      billingAddress,
      redirect,
      type,
      sameAddress,
      hidePopup,
      addressType,
    },
    { dispatch, getState }
  ) => {

    dispatch(
      setDefaultPickupBillingAddress({
        shippingAddress,
        billingAddress: billingAddress,
        type,
      })
    );

    if (hidePopup) {
      dispatch(hidePopupAction());
    }

    if (response2?.data) {
      return response2.data;
    }

    return response.data;
  }
);

export const getOrderSummaryAction = createAsyncThunk(
  'getOrderSummary',
  async (arg, { dispatch, getState }) => {
    const response = await API.getOrderSummary({ ...arg });

    if (response?.data?.status?.code === 200) {
      const {
        deliveryOptionChanged,
        savedCards,
        creditCardPlatform,
        creditCartNumber
      } = getState().order;
      if (response?.data?.paymentMethods[0]?.code && deliveryOptionChanged === true){
        if (savedCards){
          await dispatch(setInstallmentAction({ creditCardPlatform }))
        } else {
          await dispatch(setInstallmentAction({ creditCartNumber }))
        }
        await dispatch(getAgreementsAction({ method: response.data.paymentMethods[0]?.code, installment: 0 }));
      }
    }
    return response.data;
  },
);

export const getInstallmentsAction = createAsyncThunk(
  'getInstallments',
  async (arg, { dispatch, getState }) => {
    const {
      basketInfo,
    } = getState().cart;
    const response = await API.getInstallments({...basketInfo, ...arg });
    return response.data;
  },
);

export const getAllInstallmentsAction = createAsyncThunk(
  'getAllInstallments',
  async (arg, { dispatch, getState }) => {
    const response = await API.getAllInstallments({ ...arg });
    return response.data;
  },
);

export const setInstallmentAction = createAsyncThunk(
  'setInstallment',
  async (arg, { dispatch, getState }) => {
    try {
      const { location } = arg;
      const {
        paymentMethod
      } = getState().order;

      const {
        basketInfo,
      } = getState().cart;

      const response = await API.setInstallment(basketInfo);

      if (paymentMethod === 'flowallet') {
         dispatch(resetInstallmentsAction([]));
      }
      if (response?.data?.status?.code === 200 && location !== 'basket' && paymentMethod !== 'flowallet') {
        await dispatch(getInstallmentsAction({...arg }));
      }
      return response.data;

    } catch (error) {

    }
  },
);

export const getCitiesAction = createAsyncThunk(
  'getCities',
  async (arg) => {
    const response = await API.getCities({ ...arg });
    return response.data;
  },
);

export const getCountryAction = createAsyncThunk(
  'getCountry',
  async (arg) => {
    const response = await API.getCountry({ ...arg });
    return response.data;
  },
);

export const getCountiesAction = createAsyncThunk(
  'getCounties',
  async ({ addressType, cityId }) => {
    const response = await API.getCounties({ cityId });
    return { ...response.data, addressType };
  },
);

export const getNeighbourhoodAction = createAsyncThunk(
  'getNeighbourhood',
  async ({ districtId }) => {
    const response = await API.getNeighbourhood({ districtId });
    return response.data;
  },
);

export const getChequeAction = createAsyncThunk('/v1/wallet/topupchequecheckout', async (arg, { dispatch }) => {
  const response = await API.topupCheque({ ...arg });
  return { ...response.data, response: arg };
});

export const getSavedCardsAction = createAsyncThunk(
  'getSavedCards',
  async (arg, { dispatch }) => {
    const response = await API.getSavedCards({ ...arg });
    return response.data;
  },
);

export const setCustomerAddressAction = createAsyncThunk(
  'setCustomerAddress',
  async (arg, { dispatch, getState }) => {
    const response = await API.setCustomerAddress({ ...arg.data });

    if (response?.data?.success === false) {
      toast.dismiss();
      toast.error(ToastfyMessage({ text: response?.data?.message }));
      state.customerAddressLoading = false;
    }
    if (response?.data?.status?.code === 200) {

      const {
        paymentMethod,
        numberAxess,
        deliveryOptionChanged,
        deliveryOption,
        orderSummaryLoading,
      } = getState().order;

      const { selfCheckout } = getState().selfCheckout;

      if (selfCheckout) {
        dispatch(hidePopupAction());
      }

      if (paymentMethod && deliveryOptionChanged === false && orderSummaryLoading === false) {
        await dispatch(getAgreementsAction({ method: paymentMethod, installment: numberAxess }));
      }

      if (paymentMethod && deliveryOptionChanged === true || (orderSummaryLoading !== false)) {
        await dispatch(getOrderSummaryAction({ isStoreOrder: deliveryOption === 'store' ? true:false }));
        if (deliveryOptionChanged) {
          await dispatch(cartActionRequest({ lastBasketItems: false, getRevampingCoupons: 0 }))
        }
      }
    }
    return response.data;
  }
);

export const orderCompleteAction = createAsyncThunk(
  'completeOrder',
  async (arg, { dispatch, getState }) => {
    let {
      useBonus,
      paymentMethod,
      savedCards,
      creditCardPlatform,
      creditCartNumber,
      dijitalPaymentMethod,
      dijitalPaymentName,
    } = getState().order;

    let {
      basketInfo
    } = getState().cart;
    const { project } = getState().config;
    if (dijitalPaymentName.includes(paymentMethod)) {
      paymentMethod = dijitalPaymentMethod;
    }
    if (paymentMethod === "flowallet_cheque") {
      paymentMethod = "flowallet"
      useBonus = true
    }

    const data = {
      ...arg.data,
      ...basketInfo,
      useBonus,
      paymentMethod,
    };

    // @TODO : FLO Cuzdan
    // delete data.creditCartInfo;
    // data.pcidss = 1;

    const response = await API.completeOrder(data);

    if (
      response?.data?.status?.error_code ===
      'SDD_LOCATION_NOT_AVAILABLE'
    ) {
      await dispatch(isTopPopupAction(false));
      await dispatch(showPopupAction(() => AgtErrorPopup()));
      return response.data;
    }

    if (response?.data?.status?.code === 200) {
      if (
        !response?.data?.result?.check3D &&
        response?.data?.result?.orderId
      ) {
        dispatch(resetCustomerSelections());
        const {
          basketInfo,
        } = getState().cart;
        if (basketInfo?.basketType === 'self_checkout') {
          //window.location.href = `/success?order_id=${data?.order_id}&hsh=${data?.hash}`;
          navigate({
            pathname: '/store-self-qr',
            search: `?order_id=${response.data.result.orderId}&hsh=${response.data.result?.hash}`,
          });
        } else {
          window.navigate(
            `/success?order_id=${response.data.result.orderId}&hsh=${response.data.result?.hash}`
          );
        }
      }
      if (response.data.result?.check3D && paymentMethod === "craftgate_kz_kaspi") {
        dispatch(checkPaymentStatus(response.data.result))
      }
    }
    if (paymentMethod === dijitalPaymentMethod) {
      paymentMethod = "dijital_banka_odemeleri";
    }

    return { ...response.data, project };
  }
);

export const getInitializeAction = createAsyncThunk(
  'getInitialize',
  async (arg, { dispatch }) => {
    const response = await API.getInitialize({ ...arg });

    if (response?.data?.basketItemLimit) {
      await dispatch(
        setBasketItemLimitAction(response.data?.basketItemLimit)
      );
    }
    return response.data;
  },
);

export const checkPaymentStatus = createAsyncThunk('payment/checkStatus', async (arg, { dispatch, getState }) => {
  try {
    // Extracting necessary variables from the argument
    const { checkSequence, orderId, hash } = arg
    const { orderCheckRef, checkSequenceTimeout } = checkSequence

    // Calculate the end time for the loop based on the current time and timeout
    const endTime = Date.now() + (checkSequenceTimeout * 1000);
    let continueChecking = true;

    while (Date.now() < endTime && continueChecking ) {
      // Requesting payment status from the API
      const response = await API.checkPaymentStatus({orderCheckRef})

      // Navigate to success page if payment is successful and checkSequence is true
      if (response?.data?.success && !response?.data?.checkSequence) {
        window.navigate(`/checkout/success?order_id=${orderId}&hsh=${hash}`);
        continueChecking = false; // Stop checking after successful navigation
      }

      // If checkSequence and success is false, handle as a failure scenario
      if (!response?.data?.success  && !response?.data?.checkSequence) {
        dispatch(resetThreeDInfo())
        toast.dismiss();
        toast.error(ToastfyMessage({ text: response?.data?.status?.message || '' }));
        continueChecking = false; // Stop further checking
        break;
      }

      const { isOpenIframe } = getState().order;

      if (!isOpenIframe) {
        continueChecking = false; // Stop further checking
        break;
      }

      // Wait for 5 seconds before the next check
      await new Promise(resolve => setTimeout(resolve, 5000));
    }

    // Handle timeout scenario
    if (continueChecking) {
      dispatch(resetThreeDInfo());
      toast.dismiss();
      toast.error(ToastfyMessage({ text: 'Operation timed out.' }));
    }
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getOrderDetailAction = createAsyncThunk(
  'getOrderDetail',
  async (arg, { dispatch, getState }) => {
    const response = await API.getOrderDetail({ ...arg });
    const {
      selfCheckout,
    } = getState().selfCheckout;

    return {...response.data, ...{ selfCheckout }};
  }
);

export const setActivateCardAction = createAsyncThunk(
  'setActivateCard',
  async (arg, { dispatch }) => {
    const response = await API.setActivateCard(arg);
    return response.data;
  }
);

export const sendActivationSmsAction = createAsyncThunk(
  'sendActivationSms',
  async (arg, { dispatch }) => {
    const response = await API.sendActivationSms(arg);
    return response.data;
  }
);

export const checkActivationSmsAction = createAsyncThunk(
  'checkActivationSms',
  async (arg, { dispatch, getState }) => {
    const { paymentMethod } = getState().order;
    const response = await API.checkActivationSms({ ...arg });
    if (response?.data?.status?.code === 200) {
      await dispatch(
        orderCompleteAction({
          data: {
            paymentMethod,
            saveCartInfo: false,
            use3ds: false,
            useCustomerBalance: false,
          },
        })
      );
      await dispatch(hidePopupAction());
    }
    return response.data;
  }
);
export const getAgreementsAction = createAsyncThunk(
  'getAgreementsRequest',
  async (arg, { dispatch, getState }) => {
    const {
      basketInfo,
    } = getState().cart;
    const response = await API.getAgreementsRequest({ ...basketInfo, ...arg });
    return response.data;
  }
);

export const sendActivationOtpOrderAction = createAsyncThunk(
  'order/wallet/activate/send',
  async (arg, { dispatch }) => {
    const response = await API.sendActivationOtp({ ...arg });
    return { ...response.data, response: arg };
  }
);

export const validateActivationOtpOrderAction = createAsyncThunk(
  'order/wallet/activate/validate',
  async (arg, { dispatch }) => {
    const response = await API.validateActivationOtp({ ...arg });
    if (response.data.status.code === 200) {
      await dispatch(getOrderSummaryAction());
      await dispatch(hidePopupAction());
    }
    return { ...response.data, response: arg };
  }
);

const INITIAL_STATE = {
  customer: {
    firstName: '',
    lastName: '',
    phone: '',
  },
  savedCards: false,
  hepsipayLinkAccountLoading: false,
  revampingProductsLoading: false,
  code: '',
  creditCartNumber: '',
  savedCardStep: 1,
  creditCartName: '',
  expirationMonth: '',
  expirationYear: '',
  cvcNumber: '',
  floWalletCardActive: false,
  isAgreementCheck: false,
  isLoyaltyCheck: false,
  otpStep: 1,
  walletOtpStep: 1,
  useBonus: false,
  countiesArray: {},
  addresses: [],
  agreements: [],
  isOrderSuccess: false,
  pickUpInStoreEnabled: false,
  customerDefaultShippingAddress: null,
  customerDefaultBillingAddress: null,
  deliveryToAddress: null,
  storePickup: null,
  sameAddressBilling: true,
  useShippingAddressAsBillingAddress: true,
  installments: [],
  cardImageURL: '',
  platform: '',
  savedCardList: [],
  hepsiPaySavedCardList: [],
  defaultSavedCard: null,
  creditCartId: null,
  creditCardPlatform: null,
  numberOfInstallments: 1,
  numberAxess: 0,
  pickupTakeItMyself: false,
  pickupSummary: {},
  priceCurrency: 'TRY',
  deliveryOption: 'default', // default or store
  paymentMethods: [],
  paymentMethod: '',
  deliveryOptionChanged: false,
  installmentsUrl: '',
  hepsipayAlreadyLinked: false,
  dijitalPaymentMethod: '',
  garantiPayMethod: '',
  cashPaymentMethod : [],
  addressLoaded : false,
  billingAddress: {
    address: '',
    phone: '',
    lastName: '',
    firstName: '',
    titleAddress: '',
    cityId: 0,
    cityName: '',
    countyName: '',
    customerAddressId: 0,
    taxOffice:'',
    taxNumber:'',
    companyName:'',
    countyId:0,
    neighborhoodId: 0,
    neighborhoodName: '',
    otp:'',
    countryId: '',
  },
  shippingAddress: {
    address: '',
    phone: '',
    lastName: '',
    firstName: '',
    titleAddress: '',
    cityId: 0,
    cityName: '',
    countyName: '',
    customerAddressId: 0,
    taxOffice:'',
    taxNumber:'',
    companyName:'',
    countyId:0,
    neighborhoodId: 0,
    neighborhoodName: '',
    otp:'',
    countryId: '',
  },
  notices: [],
  pendingCardUuid: null,
  customerLoyalty360Info: null,
  orderDetail: {
    incrementId: null,
    priceTitles: [],
    products: [],
    billingAddress: {
      addressInfo: '',
      region: '',
      city: '',
      telephone: '',
      street: '',
    },
    shippingAddress: {
      addressInfo: '',
      region: '',
      city: '',
      telephone: '',
      street: '',
    },
    orderSummary: {
      priceItems: [],
    },
  },

  // pickup
  pickupCities: [],
  pickupDistricts: [],
  selectedPickupCity: undefined,
  selectedPickupDistrict: undefined,
  selectedPickupStores: undefined,
  selectedPickupStoreId: undefined,
  neighborhoods: null,
  pickupOwnFirstName: '',
  pickupOwnLastName: '',
  pickupOwnPhone: '',
  pickupOwnStoreName: '',
  pickupOwnCustomerId: '',

  metaAndroidData: [],
  metaIosData: [],
};

let shippingAddress = null;
let billingAddress = null;

const slice = createSlice({
  name: 'order',
  initialState: INITIAL_STATE,
  reducers: {
    resetThreeDInfo: (state) => {
      state.isOpenIframe = false;
      state.iframe = null;
      state.threeDInfo = null;
    },
    orderActionReceive: (state) => {
      return state;
    },
    setCodeAction: (state, action) => {
      state.code = action.payload;
    },
    setSavedCards: (state, action) => {
      state.savedCards = action.payload;
    },
    setSavedCardStep: (state, action) => {
      state.savedCardStep = action.payload;
    },
    setOtpStep: (state, action) => {
      state.otpStep = action.payload;
    },
    setCreditCartNumber: (state, action) => {
      state.creditCartNumber = action.payload;
    },
    setCreditCartName: (state, action) => {
      state.creditCartName = action.payload;
    },
    setHepsipayLinkAccountLoading: (state, action) => {
      state.hepsipayLinkAccountLoading = action.payload;
    },
    setRevampingProductsLoading: (state, action) => {
      state.revampingProductsLoading = action.payload;
    },
    setExpirationMonth: (state, action) => {
      state.expirationMonth = action.payload;
    },
    setExpirationYear: (state, action) => {
      state.expirationYear = action.payload;
    },
    setCvcNumber: (state, action) => {
      state.cvcNumber = action.payload;
    },
    setAgreementCheck: (state, action) => {
      state.isAgreementCheck = action.payload;
    },
    setLoyaltyCheck: (state, action) => {
      state.isLoyaltyCheck = action.payload;
    },
    resetCustomerSelections: (state) => {
      state.deliveryOption = 'default';
    },
    orderAddressesActionReceive: (state, action) => {
      state.addresses = action.payload;
    },
    setUseBonusAction: (state, action) => {
      state.useBonus = action.payload;
    },
    setPickupOwnFirstName: (state, action) => {
      state.pickupOwnFirstName = action.payload;
    },
    setPickupOwnCustomerId: (state, action) => {
      state.pickupOwnCustomerId = action.payload;
    },
    setPickupOwnStoreName: (state, action) => {
      state.pickupOwnStoreName = action.payload;
    },
    setPickupOwnLastName: (state, action) => {
      state.pickupOwnLastName = action.payload;
    },
    setPickupOwnPhone: (state, action) => {
      state.pickupOwnPhone = action.payload;
    },
    setPickupCities: (state, action) => {
      state.pickupCities = action.payload;
    },
    setPickupDistricts: (state, action) => {
      state.pickupDistricts = action.payload;
    },
    setSelectedPickupCity: (state, action) => {
      state.selectedPickupCity = action.payload;
    },
    setSelectedPickupDistrict: (state, action) => {
      state.selectedPickupDistrict = action.payload;
    },
    setSelectedPickupStores: (state, action) => {
      state.selectedPickupStores = action.payload;
    },
    setSelectedPickupStoreId: (state, action) => {
      state.selectedPickupStoreId = action.payload;
    },
    customerDefaultBillingAddressActionReceive: (state, action) => {
      state.customerDefaultBillingAddress = action.payload;
    },
    customerDefaultShippingAddressActionReceive: (state, action) => {
      state.customerDefaultShippingAddress = action.payload;
      state.selectedPickupCity = action.payload?.cityName;
      state.selectedPickupDistrict = action.payload?.countyName;
    },
    setUseShippingAddressAsBillingAddressAction: (state, action) => {
      state.useShippingAddressAsBillingAddress = action.payload;
    },
    orderSameAddressBillingAction: (state, action) => {
      state.sameAddressBilling = action.payload;
    },
    getCitiesReceiveAction: (state, action) => {
      state.cities = action.payload;
    },
    getCountiesReceiveAction: (state, action) => {
      state.counties = action.payload;
    },
    setCreditCardAction: (state, action) => {
      state.card = action.payload;
    },
    getCreditCardAction: (state) => {
      return state.card;
    },
    resetInstallmentsAction: (state, action) => {
      state.installments = action.payload;
    },
    setPlatformAction: (state, action) => {
      state.platform = action.payload;
    },
    getPlatformAction: (state, action) => {
      return state.platform;
    },
    setSavedCardsAction: (state, action) => {
      state.savedCardList = action.payload;
    },
    setHepsiPaySavedCardsAction: (state, action) => {
      state.hepsiPaySavedCardList = action.payload;
    },
    setCreditCartIdAction: (state, action) => {
      state.creditCardPlatform = action.payload?.name;
      state.creditCartId = action.payload?.id;
    },
    getCreditCartIdAction: (state, action) => {
      return state.creditCartId;
    },
    setCreditCartPlatformAction: (state, action) => {
      state.creditCardPlatform = action.payload;
    },
    getCreditCartPlatformAction: (state, action) => {
      return state.creditCardPlatform;
    },
    setNumberOfInstallmentsAction: (state, action) => {
      state.numberOfInstallments = action.payload;
    },
    setAxessAction: (state, action) => {
      state.numberAxess = action.payload;
    },
    getNumberOfInstallmentsAction: (state, action) => {
      return state.numberOfInstallments;
    },
    setPickupEnabledStoresAction: (state, action) => {
      state.stores = action.payload;
    },
    setPickupCitiesAction: (state, action) => {
      state.pickupCities = action.payload;
    },
    setPickupWhoWillTakeAction: (state, action) => {
      state.pickupSummary = action.payload;
    },
    setPickupInStoreEnabledAction: (state, action) => {
      state.pickUpInStoreEnabled = action.payload;
    },
    setAddressLoadedAction:(state, action) => {
      state.addressLoaded = action.payload;
    },
    setPaymentMethodsAction: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setDeliveryOptionChanged: (state, action) => {
      state.deliveryOptionChanged = action.payload;
    },
    setOrderSummaryAction: (state, action) => {
      state.orderSummary = action.payload;
    },
    setinstallmentsUrlAction: (state, action) => {
      state.installmentsUrl = action.payload;
    },
    setHepsipayAlreadyLinked: (state, action) => {
      state.hepsipayAlreadyLinked = action.payload;
    },
    setPaymentMethodAction: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setCashPaymentMethodAction: (state, action) => {
      state.cashPaymentMethod = action.payload;
    },
    setDijitalPaymentMethodAction: (state, action) => {
      state.dijitalPaymentMethod = action.payload;
    },
    setGarantiPayMethodAction: (state, action) => {
      state.garantiPayMethod = action.payload;
    },
    setfloWalletCardActiveAction: (state, action) => {
      state.floWalletCardActive = action.payload;
    },
    set3DPopupAction: (state, action) => {
      state.isOpenIframe = true;
      state.iframe = action.payload;
    },
    set3DPopupCloseAction: (state, action) => {
      state.isOpenIframe = false;
    },
    set3DInfoAction: (state, action) => {
      state.threeDInfo = action.payload;
    },
    setAddressFieldValueAction: (state, action) => {
      if (action.payload.addressType === 'billingAddress') {
        billingAddress = { ...state[action.payload?.addressType] };
        billingAddress[action.payload?.key] = action.payload?.value;
        state.billingAddress = billingAddress;
      }
      if (action.payload?.addressType === 'shippingAddress') {
        shippingAddress = { ...state[action.payload?.addressType] };
        shippingAddress[action.payload?.key] = action.payload?.value;
        state.shippingAddress = shippingAddress;
      }
    },
    setDeliveryOptionAction: (state, action) => {
      state.deliveryOption = action.payload;
    },
    resetAddress: (state) => {
      state.deliveryOption = 'default';
      state.useShippingAddressAsBillingAddress = true;
    },
    setCustomerInfoAction: (state, action) => {
      state.customer = action.payload;
    },
    resetShippingAddressAction: (state, action) => {
      state.shippingAddress = action.payload
        ? action.payload
        : {
            address: '',
            phone: '',
            lastName: '',
            firstName: '',
            titleAddress: '',
            cityId: 0,
            cityName: '',
            countyName: '',
            customerAddressId: 0,
            countyId:0,
            neighborhoodId: 0,
            neighborhoodName: '',
            otp:'',
            taxOffice:'',
            taxNumber:'',
            companyName:'',
          };
    },
    resetCountysAction: (state, action) => {
      state.counties = action.payload
        ? action.payload
        : [];
    },
    resetCitiesAction: (state, action) => {
      state.cities = action.payload
        ? action.payload
        : [];
      state.countiesArray = action.payload
        ? action.payload
        : {}
    },
    resetNeighbourhoodAction: (state, action) => {
      state.neighborhoods = action.payload
        ? action.payload
        : []
    },
    resetBillingAddressAction: (state, action) => {
      state.billingAddress = action.payload
        ? action.payload
        : {
            address: '',
            phone: '',
            lastName: '',
            firstName: '',
            titleAddress: '',
            cityId: 0,
            cityName: '',
            countyName: '',
            customerAddressId: 0,
            countyId:0,
            neighborhoodId: state.neighborhoods && state.neighborhoods.length > 0 ? 0 : 9999,
            neighborhoodName: '',
            taxOffice:'',
            taxNumber:'',
            companyName:'',
            otp:'',
          };
    },
    setPickupTakeItMyself: (state, action) => {
      state.pickupTakeItMyself = action.payload;
    },
    setOrderDetailAction: (state, action) => {
      state.orderDetail = action.payload;
    },
    setAgreementsAction: (state, action) => {
      state.agreements = action.payload;
    },
    setWalletOtpStepAction: (state, action) => {
      state.walletOtpStep = action.payload;
    },
    isOrderSuccessAction: (state,action) => {
      state.isOrderSuccess = action.payload
    },
    resetPaymentStepAction: (state) => {
      state.addressLoaded = false;
      state.paymentMethod = '';
      state.numberOfInstallments = 1;
      state.dijitalPaymentMethod = null;
      state.installments = [];
      state.numberAxess = 0;
      state.addresses = [];
      state.agreements = null;
      state.orderSummaryLoading = null;
      state.billingAddress = {
        address: '',
        phone: '',
        lastName: '',
        firstName: '',
        titleAddress: '',
        cityId: 0,
        cityName: '',
        countyName: '',
        customerAddressId: 0,
        countyId:0,
        neighborhoodId: state.neighborhoods && state.neighborhoods.length > 0 ? 0 : 9999,
        neighborhoodName: '',
        taxOffice:'',
        taxNumber:'',
        companyName:'',
        otp:'',
      };
      state.shippingAddress = {
          address: '',
          phone: '',
          lastName: '',
          firstName: '',
          titleAddress: '',
          cityId: 0,
          cityName: '',
          countyName: '',
          customerAddressId: 0,
          countyId:0,
          taxOffice:'',
          taxNumber:'',
          companyName:'',
          neighborhoodId: state.neighborhoods && state.neighborhoods.length > 0 ? 0 : 9999,
          neighborhoodName: '',
          otp:'',
        };
    },
    getBags: (state, action) => {
      state.bags = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getSummoningShoppingCartIdAction
    builder.addCase(
      getSummoningShoppingCartIdAction.pending,
      (state, action) => {
        state.summoningLoading = true;
        state.summoningRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      getSummoningShoppingCartIdAction.fulfilled,
      (state, { payload }) => {
        const { status, customer, response } = payload;
        if (status?.code === 200) {
          state.summoned = response;
          if (response?.message) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: response?.message }));
          }
        } else {
          if (status?.message) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: status?.message }));
          }
        }

        state.isOpenIframe = false;
        state.summoningLoading = false;
        state.summoningRequestId = null;
      }
    );
    builder.addCase(
      getSummoningShoppingCartIdAction.rejected,
      (state) => {
        state.summoningLoading = false;
        state.summoningRequestId = null;
      }
    );

    // getCustomerRequest
    builder.addCase(getCustomerRequest.pending, (state, action) => {
      state.customerLoading = true;
      state.customerRequestId = action.meta.requestId;
    });
    builder.addCase(
      getCustomerRequest.fulfilled,
      (state, { payload }) => {
        const { status, customer } = payload;
        if (status?.code === 200) {
          state.customer = customer;
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        if (status.code === 2072) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.customerLoading = false;
        state.customerRequestId = null;
      }
    );
    builder.addCase(getCustomerRequest.rejected, (state) => {
      state.customerLoading = false;
      state.customerRequestId = null;
    });

        /* getChequeAction */
        builder.addCase(getChequeAction.pending, (state, action) => {
          state.getChequeLoading = true;
          state.getChequeRequestId = action.meta.requestId;
        });
        builder.addCase(getChequeAction.fulfilled, (state, { payload }) => {
          const {
            success, status, data
          } = payload;
          if (!success) {
            toast.dismiss();
            toast.error(ToastfyMessage({ message: status?.message }));
          }
          state.getChequeLoading = false;
          state.getChequeRequestId = null;
        });
        builder.addCase(getChequeAction.rejected, (state, action) => {
          state.getChequeLoading = false;
          state.getChequeRequestId = null;
        });


    // deliveryOptionsRequest
    builder.addCase(deliveryOptionsRequest.pending, (state, action) => {
      state.deliveryOptionsLoading = true;
      state.deliveryOptionsRequestId = action.meta.requestId;
    });
    builder.addCase(
      deliveryOptionsRequest.fulfilled,
      (state, { payload }) => {
        const {
          status,
          data
        } = payload;
        if (status?.code === 200) {
          const deliveryToAddress = data.find(item => item.optionType === "deliveryToAddress");
          const storePickup = data.find(item => item.optionType === "storePickup");
          state.deliveryToAddress = deliveryToAddress
          state.storePickup = storePickup

          if (deliveryToAddress?.details) {
            state.addresses = deliveryToAddress.details.customerAddresses;
            state.customerDefaultBillingAddress = deliveryToAddress.details.customerDefaultBillingAddress;
            state.customerDefaultShippingAddress = deliveryToAddress.details.customerDefaultShippingAddress;

            state.addressLoaded = true;
            state.selectedPickupCity = deliveryToAddress.details.customerDefaultShippingAddress?.cityName;
            state.selectedPickupDistrict = deliveryToAddress.details.customerDefaultShippingAddress?.countyName;
          }

          state.stores = storePickup?.details?.stores?.sort(
            (a, b) => a.storeName - b.storeName
          );
          const sortValue = (a, b) => a.name?.localeCompare(b.name);
          state.pickupCities = storePickup?.details?.cities?.sort(sortValue);
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        state.deliveryOptionsLoading = false;
        state.deliveryOptionsRequestId = null;
      }
    );
    builder.addCase(deliveryOptionsRequest.rejected, (state) => {
      state.deliveryOptionsLoading = false;
      state.deliveryOptionsRequestId = null;
    });

    // updateCustomerAddressAction
    builder.addCase(
      updateCustomerAddressAction.pending,
      (state, action) => {
        state.updateCustomerLoading = true;
        state.updateCustomerRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      updateCustomerAddressAction.fulfilled,
      (state, { payload }) => {
        const {
          status,
          address,
          customerAddresses,
          customerDefaultShippingAddress,
          customerDefaultBillingAddress,
          otpCode,
          success,
          showVerifiedCode,
          callback = () => {},
        } = payload;
        if (status?.code === 200 && !showVerifiedCode) {
          // state.shoppingCart = shoppingCart

          state.addresses = customerAddresses;
          state.customerDefaultShippingAddress = customerDefaultShippingAddress;
          state.customerDefaultBillingAddress = customerDefaultBillingAddress;
        }
        if(typeof otpCode  === 'undefined') {
          if(success !==false){
            if (status?.code !== 200 && !status) {
              toast.dismiss();
              toast.error(ToastfyMessage({ text: status?.message }));
            }
          }
        }
        state.updateCustomerLoading = false;
        state.updateCustomerRequestId = null;
      }
    );
    builder.addCase(updateCustomerAddressAction.rejected, (state) => {
      state.updateCustomerLoading = false;
      state.updateCustomerRequestId = null;
    });

    // insertCustomerAddressAction
    builder.addCase(
      insertCustomerAddressAction.pending,
      (state, action) => {
        state.insertCustomerLoading = true;
        state.insertCustomerRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      insertCustomerAddressAction.fulfilled,
      (state, { payload }) => {
        const { status, customerAddresses, showVerifiedCode, otpCode, success} = payload;
        if (status?.code === 200 && !showVerifiedCode) {
          state.addresses = customerAddresses;
        }

        if(typeof otpCode  === 'undefined') {
          if(success !==false){
            if (status?.code !== 200 && !status ) {
              toast.dismiss();
              toast.error(ToastfyMessage({ text: status?.message }));
            }
          }
        }

        state.insertCustomerLoading = false;
        state.insertCustomerRequestId = null;
      }
    );
    builder.addCase(insertCustomerAddressAction.rejected, (state) => {
      state.insertCustomerLoading = false;
      state.insertCustomerRequestId = null;
    });

    // getOrderSummaryAction
    builder.addCase(
      getOrderSummaryAction.pending,
      (state, action) => {
        state.orderSummaryLoading = true;
        state.orderSummaryRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      getOrderSummaryAction.fulfilled,
      (state, { payload }) => {
        const { status, paymentMethods, orderSummary, installmentsUrl, hepsipayAlreadyLinked, savedCardList } = payload;
        if (status?.code === 200) {
          state.installmentsUrl = installmentsUrl;
          state.hepsipayAlreadyLinked = hepsipayAlreadyLinked;
          state.paymentMethods = paymentMethods;
          const filteredArray = paymentMethods.filter(item => item.code !== "flowallet");
          state.paymentMethod = filteredArray[0]?.code
          state.orderSummary = orderSummary;
          let savedCardListSort = savedCardList;
          if (savedCardList && savedCardList?.length > 0) {
            savedCardListSort = savedCardList.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
          }
          state.savedCardList = savedCardListSort;
          state.defaultSavedCard = savedCardListSort && savedCardListSort[0]?.name;
          state.creditCardPlatform = savedCardListSort && savedCardListSort[0]?.name;
          state.creditCartId = savedCardListSort && savedCardListSort[0]?.id;

          const digitalBankPayment = paymentMethods.find(item => item.code === 'dijital_banka_odemeleri');
          const hepsiPayPayment = digitalBankPayment?.subPaymentMethods.find(item => item.code === 'flo_hepsipay');
          state.hepsiPaySavedCardList = hepsiPayPayment?.savedCardList;

          state.dijitalPayment = paymentMethods?.find(item => item.subPaymentMethods);
          state.dijitalPaymentName = paymentMethods?.filter(items => items.subPaymentMethods).map(item => item.code);
          const otherPaymentMethod = paymentMethods.find(item => item.code === 'other_payment_methods');

          if (otherPaymentMethod) {
            state.otherPaymentMethods = otherPaymentMethod
          }

          // TODO: Kontrol Edilecek.
          state.cashPaymentMethod = paymentMethods?.find(item => item.code === 'cashondelivery');
          state.garantiPayMethod = paymentMethods?.find(item => item.code === 'flo_garantipay');
          // TODO: END
          state.customerWalletActive = paymentMethods?.find(item => item.code === 'flowallet')?.customerWalletActive;
          state.isPayableWithBonus = paymentMethods?.find(item => item.code === 'flowallet')?.isPayableWithBonus;
          state.isPayableWithoutBonus = paymentMethods?.find(item => item.code === 'flowallet')?.isPayableWithoutBonus;
          if(state.garantiPayMethod){
            state.garantiPayMethod = state.garantiPayMethod;
          }
        } else {
          if (status?.message) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: status?.message }));
          }
        }

        state.orderSummaryLoading = false;
        state.orderSummaryRequestId = null;
      }
    );
    builder.addCase(getOrderSummaryAction.rejected, (state) => {
      state.orderSummaryLoading = false;
      state.orderSummaryRequestId = null;
    });

    // getInstallmentsAction
    builder.addCase(
      getInstallmentsAction.pending,
      (state, action) => {
        state.installmentsLoading = true;
        state.installmentsRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      getInstallmentsAction.fulfilled,
      (state, { payload }) => {
        const { status, platform, installments, card_image } = payload;
        if (status.code === 200) {
          state.installments = installments;
          state.numberOfInstallments = 1;
          state.cardImageURL = card_image;
          state.numberAxess = 0;
          if (platform) {
            state.platform = platform.toLowerCase();
          }
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        if (status.code === 2072) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        state.installmentsLoading = false;
        state.installmentsRequestId = null;
      }
    );
    builder.addCase(getInstallmentsAction.rejected, (state) => {
      state.installmentsLoading = false;
      state.installmentsRequestId = null;
    });

    // getAllInstallmentsAction
    builder.addCase(
      getAllInstallmentsAction.pending,
      (state, action) => {
        state.installmentsLoading = true;
      }
    );
    builder.addCase(
      getAllInstallmentsAction.fulfilled,
      (state, { payload }) => {
        const { success, status, message } = payload;
        if (success === false) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: (status?.message || message) }));
        }
        state.installmentsLoading = false;
      }
    );
    builder.addCase(getAllInstallmentsAction.rejected, (state) => {
      state.installmentsLoading = false;
    });

    // setInstallmentAction
    builder.addCase(
      setInstallmentAction.pending,
      (state, action) => {
        state.installmentsLoading = true;
        state.installmentsRequestId = action.meta.requestId;
      },
    );
    builder.addCase(
      setInstallmentAction.fulfilled,
      (state, { payload }) => {
        try {
          const { status } = payload;
          if (status.code !== 200) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: status.message }));
          }
        } catch (error) {

        }
        state.installmentsLoading = false;
        state.installmentsRequestId = null;
      },
    );
    builder.addCase(setInstallmentAction.rejected, (state) => {
      state.installmentsLoading = false;
      state.installmentsRequestId = null;
    });

    // getCitiesAction
    builder.addCase(getCitiesAction.pending, (state, action) => {
      state.citiesLoading = true;
      state.citiesRequestId = action.meta.requestId;
    });
    builder.addCase(
      getCitiesAction.fulfilled,
      (state, { payload }) => {
        const { status, cities } = payload;
        if (status?.code === 200) {
          state.cities = cities;
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        if (status.code === 2072) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }
        state.citiesLoading = false;
        state.citiesRequestId = null;
      }
    );
    builder.addCase(getCitiesAction.rejected, (state) => {
      state.citiesLoading = false;
      state.citiesRequestId = null;
    });

    // getCountryAction
    builder.addCase(getCountryAction.pending, (state, action) => {
      state.countryLoading = true;
      state.countryRequestId = action.meta.requestId;
    });
    builder.addCase(getCountryAction.fulfilled, (state, { payload }) => {
      const { status, countries } = payload;
      if (status.code === 200) {
        state.country = countries;
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status.message }));
      }

      state.countryLoading = false;
      state.countryRequestId = null;
    });
    builder.addCase(getCountryAction.rejected, (state) => {
      state.countryLoading = false;
      state.countryRequestId = null;
    });

    // getCountiesAction
    builder.addCase(getCountiesAction.pending, (state, action) => {
      state.countiesLoading = true;
      state.countiesRequestId = action.meta.requestId;
    });
    builder.addCase(
      getCountiesAction.fulfilled,
      (state, { payload }) => {
        const { status, counties, addressType } = payload;
        if (status?.code === 200) {
          state.counties = counties;
          const countiesArray = state?.countiesArray;
          countiesArray[addressType] = counties;
          state.countiesArray = countiesArray;
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        if (status?.code === 2072) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.countiesLoading = false;
        state.countiesRequestId = null;
      }
    );
    builder.addCase(getCountiesAction.rejected, (state) => {
      state.countiesLoading = false;
      state.countiesRequestId = null;
    });

    // getNeighbourhoodAction
    builder.addCase(getNeighbourhoodAction.pending, (state, action) => {
      state.neighbourhoodLoading = true;
      state.neighbourhoodRequestId = action.meta.requestId;
    });
    builder.addCase(
      getNeighbourhoodAction.fulfilled,
      (state, { payload }) => {
        const { status, neighborhoods } = payload;
        if (status?.code === 200) {
          state.neighborhoods = neighborhoods;
          if (!neighborhoods) {
            state.billingAddress.neighborhoodId = 9999;
            state.shippingAddress.neighborhoodId = 9999;
          }
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.neighbourhoodLoading = false;
        state.neighbourhoodRequestId = null;
      }
    );
    builder.addCase(getNeighbourhoodAction.rejected, (state) => {
      state.neighbourhoodLoading = false;
      state.neighbourhoodRequestId = null;
    });

    // getSavedCardsAction
    builder.addCase(getSavedCardsAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(
      getSavedCardsAction.fulfilled,
      (state, { payload }) => {
        const { status, savedCardList } = payload;
        if (status?.code === 200) {
          state.savedCardList = savedCardList;
          if (savedCardList && savedCardList?.length) {
            state.creditCartId = savedCardList[0]?.id;
            state.creditCardPlatform = savedCardList[0]?.name;
          }
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        if (status.code === 2072) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.savedCardsLoading = false;
        state.savedCardsRequestId = null;
      }
    );
    builder.addCase(getSavedCardsAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // setCustomerAddressAction
    builder.addCase(
      setCustomerAddressAction.pending,
      (state, action) => {
        state.customerAddressLoading = true;
        state.customerAddressRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      setCustomerAddressAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;

        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.customerAddressLoading = false;
        state.customerAddressRequestId = null;
      },
    );
    builder.addCase(setCustomerAddressAction.rejected, (state) => {
      state.customerAddressLoading = false;
      state.customerAddressRequestId = null;
    });

    // orderCompleteAction
    builder.addCase(orderCompleteAction.pending, (state, action) => {
      state.orderCompleteLoading = true;
      state.orderCompleteRequestId = action.meta.requestId;
    });
    builder.addCase(
      orderCompleteAction.fulfilled,
      (state, { payload }) => {
        const { status, result } = payload;
        if (status?.code === 200) {
          if (result?.iframeNotAllowed == 1 && result?.redirect) {
            window.location.href = result.redirect;
          } else {
            if (result?.check3D) {
              state.isOpenIframe = true;
              state.iframe = result.redirect;
              state.threeDInfo = result;
            }
          }
        }

        if (status?.code !== 200 && status?.code !== 7777 && !status?.products?.length) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        if (status?.code === 1903 && status.products) {
          toast.dismiss();
          toast(() => <OutOfStock products={status?.products} redirectUrl={status?.redirect_url} />, {
            className: 'toast-out-of-stock',
            autoClose: false,
            closeButton: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            transition: Zoom,
          });
        }

        state.orderCompleteLoading = false;
        state.orderCompleteRequestId = null;
      }
    );
    builder.addCase(orderCompleteAction.rejected, (state) => {
      state.orderCompleteLoading = false;
      state.orderCompleteRequestId = null;
    });

    // getInitializeAction
    builder.addCase(getInitializeAction.pending, (state, action) => {
      state.initializeLoading = true;
      state.initializeRequestId = action.meta.requestId;
    });
    builder.addCase(
      getInitializeAction.fulfilled,
      (state, { payload }) => {
        const {
          status,
          pickUpInStoreEnabled,
          priceCurrency,
          isBrickCollectActive,
          isSameDayDeliveryActive,
          appleLoginIsActive,
          googleLoginIsActive,
          facebookLoginIsActive,
          isLoyaltyActive,
          discountMessage,
          isPhoneLoginActive,
        } = payload;
        if (status?.code === 200) {
          // state.isBrickCollectActive = isBrickCollectActive;
          state.pickUpInStoreEnabled = pickUpInStoreEnabled;
          state.priceCurrency = priceCurrency;
          state.isBrickCollectActive = isBrickCollectActive;
          state.isSameDayDeliveryActive = isSameDayDeliveryActive;
          state.loginWithGoogleIsActive = googleLoginIsActive;
          state.facebookLoginIsActive = facebookLoginIsActive;
          state.loginWithAppleIsActive = appleLoginIsActive;
          state.isLoyaltyActive = isLoyaltyActive;
          state.discountMessage = discountMessage;
          state.isPhoneLoginActive = isPhoneLoginActive;
        }

        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.initializeLoading = false;
        state.initializeRequestId = null;
      }
    );
    builder.addCase(getInitializeAction.rejected, (state) => {
      state.initializeLoading = false;
      state.initializeRequestId = null;
    });

    // getOrderDetailAction
    builder.addCase(getOrderDetailAction.pending, (state, action) => {
      state.orderDetailLoading = true;
      state.orderDetailRequestId = action.meta.requestId;
    });
    builder.addCase(
      getOrderDetailAction.fulfilled,
      (state, { payload }) => {

        const { status, orderDetail, header, selfCheckout, notices, pending_card_uuid, customerLoyalty360Info } = payload;
        if (header && !selfCheckout) {
          if (process.env.NODE_ENV !== 'development') {
            window.location.href = BASE_URL;
          }
        } else {
          if (status?.code === 200) {
            state.orderDetail = orderDetail;
            state.customerLoyalty360Info = customerLoyalty360Info;
            state.notices = notices;
            state.pendingCardUuid = pending_card_uuid;
          }

          if (status?.code !== 200) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: status?.message }));
          }

          state.orderDetailLoading = false;
          state.orderDetailRequestId = null;
        }
      }
    );
    builder.addCase(getOrderDetailAction.rejected, (state) => {
      state.orderDetailLoading = false;
      state.orderDetailRequestId = null;
    });

    // sendActivationSmsAction
    builder.addCase(
      sendActivationSmsAction.pending,
      (state, action) => {
        state.sendActivationSmsLoading = true;
        state.sendActivationSmsRequestId = action.meta.requestId;
      }
    );

    builder.addCase(
      sendActivationSmsAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;
        if (status?.code === 200) {
          state.otpStep = 2;
        }

        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        state.sendActivationSmsLoading = false;
        state.sendActivationSmsRequestId = null;
      }
    );

    builder.addCase(sendActivationSmsAction.rejected, (state) => {
      state.sendActivationSmsLoading = false;
      state.sendActivationSmsRequestId = null;
    });

    //set activated card
    builder.addCase(
      setActivateCardAction.pending,
      (state, action) => {
        state.setActivateCardLoading = true;
        state.sendActivateCardRequestId = action.meta.requestId;
      }
    );

    builder.addCase(
      setActivateCardAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;

        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        state.setActivateCardLoading = false;
        state.sendActivateCardRequestId = null;
      }
    );

    builder.addCase(setActivateCardAction.rejected, (state) => {
      state.setActivateCardLoading = false;
      state.sendActivateCardRequestId = null;
    });

    // checkActivationSmsAction
    builder.addCase(
      checkActivationSmsAction.pending,
      (state, action) => {
        state.checkActivationSmsLoading = true;
        state.checkActivationSmsRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      checkActivationSmsAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;
        if (status.code === 200) {
        }

        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.checkActivationSmsLoading = false;
        state.checkActivationSmsRequestId = null;
      }
    );
    builder.addCase(checkActivationSmsAction.rejected, (state) => {
      state.checkActivationSmsLoading = false;
      state.checkActivationSmsRequestId = null;
    });

    // getAgreementsAction
    builder.addCase(getAgreementsAction.pending, (state, action) => {
      state.agreementsLoading = true;
      state.agreementsRequestId = action.meta.requestId;
    });
    builder.addCase(
      getAgreementsAction.fulfilled,
      (state, { payload }) => {
        const { status, agreements } = payload;
        if (status?.code === 200) {
          state.agreements = agreements;
          state.deliveryOptionChanged = false;
        }

        if (status?.code !== 200 && status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.agreementsLoading = false;
        state.agreementsRequestId = null;
      }
    );
    builder.addCase(getAgreementsAction.rejected, (state) => {
      state.agreementsLoading = false;
      state.agreementsRequestId = null;
    });

    // sendActivationOtpOrderAction
    builder.addCase(
      sendActivationOtpOrderAction.pending,
      (state, action) => {
        state.sendActivationOtpLoading = true;
        state.sendActivationOtpRequestId = action.meta.requestId;
      }
    );

    builder.addCase(
      sendActivationOtpOrderAction.fulfilled,
      (state, { payload }) => {
        const { status, otp_key } = payload;
        // {"status":{"code":200,"message":"OK"},"otp_key":"cd1aee51005935e","validation_method":"phone","validation_value":"+90*****21","sms_activation":true,"time":120}
        if (status?.code === 200) {
          state.walletOtpStep = 2;
          state.otp_key = otp_key;
        }

        if (status?.code !== 200 && status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.isOpenActivationOtp = false;
        state.sendActivationOtpLoading = false;
        state.sendActivationOtpRequestId = null;
      }
    );

    builder.addCase(
      sendActivationOtpOrderAction.rejected,
      (state) => {
        state.isOpenActivationOtp = false;
        state.sendActivationOtpLoading = false;
        state.sendActivationOtpRequestId = null;
      }
    );

    // validateActivationOtpOrderAction
    builder.addCase(
      validateActivationOtpOrderAction.pending,
      (state, action) => {
        state.validOtpLoading = true;
        state.validOtpRequestId = action.meta.requestId;
      }
    );

    builder.addCase(
      validateActivationOtpOrderAction.fulfilled,
      (state, { payload }) => {
        const { status, otp_key } = payload;
        const { project } = config;
        // {"status":{"code":200,"message":"OK"},"otp_key":"cd1aee51005935e","validation_method":"phone","validation_value":"+90*****21","sms_activation":true,"time":120}
        if (status?.code === 200) {
          state.walletOtpStep = 1;
          // state.walletStep = 3;
          if (project !== 'sneakerbox') {
            window.events.emit('analytics.gtmWallet', {
              eventAction: 'Checkout Wallet Success',
              eventLabel:
                Cookie.get('is_logged_in') !== undefined
                  ? window.dataLayer[0].user.masterId
                  : '',
              event: 'Checkout Wallet Success',
            });
          }
        }

        if (status?.code !== 200 && status?.message) {
          state.code = '';
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }

        state.isOpenActivationOtp = false;
        state.validOtpLoading = false;
        state.validOtpRequestId = null;
      }
    );

    builder.addCase(
      validateActivationOtpOrderAction.rejected,
      (state) => {
        state.isOpenActivationOtp = false;
        state.validOtpLoading = false;
        state.validOtpRequestId = null;
      }
    );
  },
});

export default slice.reducer;

export const {
  orderActionReceive,
  orderAddressesActionReceive,
  customerDefaultBillingAddressActionReceive,
  customerDefaultShippingAddressActionReceive,
  orderSameAddressBillingAction,
  getCitiesReceiveAction,
  getCountiesReceiveAction,
  setUseShippingAddressAsBillingAddressAction,
  setCreditCardAction,
  resetInstallmentsAction,
  setPlatformAction,
  getPlatformAction,
  setSavedCardsAction,
  setCreditCartIdAction,
  getCreditCartIdAction,
  setCreditCartPlatformAction,
  getCreditCartPlatformAction,
  setNumberOfInstallmentsAction,
  setAxessAction,
  getNumberOfInstallmentsAction,
  setPickupEnabledStoresAction,
  setPickupCitiesAction,
  setPickupWhoWillTakeAction,
  setPickupInStoreEnabledAction,
  setPaymentMethodsAction,
  setDeliveryOptionChanged,
  setAddressLoadedAction,
  setOrderSummaryAction,
  setinstallmentsUrlAction,
  setHepsipayAlreadyLinked,
  setPaymentMethodAction,
  setCashPaymentMethodAction,
  setDijitalPaymentMethodAction,
  setGarantiPayMethodAction,
  set3DPopupAction,
  set3DPopupCloseAction,
  set3DInfoAction,
  setAddressFieldValueAction,
  setDeliveryOptionAction,
  setCustomerInfoAction,
  resetShippingAddressAction,
  resetCountysAction,
  resetCitiesAction,
  resetNeighbourhoodAction,
  resetBillingAddressAction,
  setPickupTakeItMyself,
  setOrderDetailAction,
  setAgreementsAction,
  isOrderSuccessAction,
  resetPaymentStepAction,
  getCreditCardAction,
  setPickupOwnFirstName,
  setPickupOwnCustomerId,
  setPickupOwnStoreName,
  setPickupOwnLastName,
  setPickupOwnPhone,
  setPickupCities,
  setAgreementCheck,
  setLoyaltyCheck,
  setPickupDistricts,
  setSelectedPickupCity,
  setSelectedPickupDistrict,
  setSelectedPickupStores,
  setSelectedPickupStoreId,
  resetAddress,
  resetCustomerSelections,
  resetThreeDInfo,
  setUseBonusAction,
  setWalletOtpStepAction,
  setCreditCartNumber,
  setCreditCartName,
  setExpirationMonth,
  setExpirationYear,
  setCvcNumber,
  setCodeAction,
  setSavedCards,
  setOtpStep,
  setfloWalletCardActiveAction,
  setSavedCardStep,
  setHepsiPaySavedCardsAction,
  setHepsipayLinkAccountLoading,
  setRevampingProductsLoading,
} = slice.actions;
