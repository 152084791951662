import React from 'react';
import { useSelector } from 'react-redux';
import { gettext } from '@Translate';
import HepsiPay from '../DijitalPayment/HepsiPay/index';
import config from '@Config';

const PaymentMethodItem = ({ 
  subPaymentMethodItem, 
  onChangePaymentMethod, 
  hepsipayAlreadyLinked 
}) => {

  const { project } = config;

  const {
    paymentMethod
  } = useSelector((state) => state.order);

  return (
    <div
      className={`dijital-payment-item ${
        paymentMethod === subPaymentMethodItem?.code ? 'active' : ''
      }`}
    >
      <button
        type="button"
        onClick={() => onChangePaymentMethod(subPaymentMethodItem)}
        className="dijital-payment-item__button"
        data-test="checkout-dijital-payment-check-button"
      >
        <div className="dijital-payment-item__box">
          <span className={`dijital-payment-item__icon dijital-payment-item__icon--${project}`}><i className="icon-check-bold" /></span>
          <picture className="dijital-payment-item__image">
            <img src={subPaymentMethodItem?.logo} alt="" />
          </picture>
          <span className="dijital-payment-item__text">{subPaymentMethodItem?.title}</span>
        </div>
        {subPaymentMethodItem.code === 'flo_hepsipay' && hepsipayAlreadyLinked === false && (
          <span className="dijital-payment-item__badge">{gettext('Aktif Et')}</span>
        )}
      </button>
      <div className="dijital-payment-item__body">
        {subPaymentMethodItem.code === 'flo_hepsipay' ? (
          hepsipayAlreadyLinked && (
            <div className="dijital-payment-item__hepsipay">
              <HepsiPay item={subPaymentMethodItem} />
            </div>
          )
        ) : (
          <div dangerouslySetInnerHTML={{
            __html: subPaymentMethodItem?.description,
          }}>
          </div>
        )}
      </div>
    </div>
  )
}


export default PaymentMethodItem;

