import { goToLogin } from './GoToLogin';
import ValidateLuhn from './LuhnAlgorithm';
import ValidateEmail from './EmailValidation';
import { mindboxSetCart, mindboxFavoriteList } from './MindboxEvent';
import { releatedEventSetCart, releatedFavoriteList, releatedFavoriteListRemove, releatedEventPurchase } from './ReleatedGtmEvent';
import { selectPromotionEvent, viewPromotionEvent, viewCartEvent, beginCheckoutEvent, addShippingInfoEvent, addPaymentInfoEvent, completePurchaseEvent, addToWishlistEvent } from './gtmEvents';

export {
  goToLogin,
  ValidateLuhn,
  ValidateEmail,
  mindboxSetCart,
  mindboxFavoriteList,
  releatedEventSetCart,
  releatedFavoriteList,
  releatedFavoriteListRemove,
  releatedEventPurchase,
  selectPromotionEvent,
  viewPromotionEvent,
  viewCartEvent,
  beginCheckoutEvent,
  addShippingInfoEvent,
  addPaymentInfoEvent,
  completePurchaseEvent,
  addToWishlistEvent
};
