/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from 'react';
import {
  createSlice,
  createAsyncThunk,
  current,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { checkAxiosInstance } from '../network/apis/index';
import { ToastfyCouponConfirm, ToastfyMessage } from '@Components';
import Cookie from 'js-cookie';
import { resetAddress } from '@Actions';
import { helper, logEvent } from '@Constants';
import API from '@API';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import config from '@Config';
import { mindboxFavoriteList, mindboxSetCart, releatedFavoriteList, releatedEventSetCart, viewPromotionEvent, addToWishlistEvent, releatedFavoriteListRemove } from '@Utils';


export const addToShoppingCartAction = createAsyncThunk('cart/addToShoppingCart', async (arg, { dispatch, getState }) => {
  const params = { ...arg };
  const {
    basketInfo,
  } = getState().cart;
  delete params.product;
  const response = await API.addToShoppingCart(params);
  const res = await API.getShoppingCart({ ...basketInfo, lastBasketItems: true, getRevampingCoupons: 1 });

  dispatch(resetAddress());

  return {
    ...res.data,
    ...response.data,
    product: arg?.product,
    productSku: arg?.sku,
  };
});

export const getCouponsAction = createAsyncThunk('coupons/list', async (arg, { dispatch }) => {
  const response = await API.getCouponsRequest({ ...arg });
  return response.data;
});

export const removeCouponAction = createAsyncThunk('coupons/remove', async (arg, { dispatch }) => {
  const response = await API.removeCouponRequest({ ...arg, lastBasketItems: false });
  return response.data;
});

export const applyCouponAction = createAsyncThunk('coupons/apply', async (arg, { dispatch }) => {
  const response = await API.applyCouponRequest({ ...arg, lastBasketItems: false });
  return response.data;
});

export const useLoyaltyAction = createAsyncThunk('mindbox/useloyalty', async (arg, { dispatch }) => {
  const response = await API.useLoyalty({ ...arg });
  return response.data;
});

export const applyBonusAction = createAsyncThunk('cart/applyBonus', async (arg, { dispatch }) => {
  const response = await API.applyBonus({ ...arg });
  return response.data;
});

export const cartActionRequest = createAsyncThunk('cart/list', async (arg, { dispatch, getState }) => {
  try {
    const {
      basketInfo,
    } = getState().cart;
    const response = await API.getShoppingCart({...basketInfo, ...arg });
    return response.data;
  } catch (error) {
    
  }
});

export const updateCartItemActionRequest = createAsyncThunk('cart/item/update', async (arg, { dispatch }) => {
  const response = await API.updateShoppingCartItem({ ...arg, lastBasketItems: false });
  dispatch(resetAddress());
  return {
    ...response.data,
    productSku: arg?.sku,
    oldQuantity: arg?.oldQuantity,
    quantity: arg?.quantity,
  };
});

export const removeCartItemActionRequest = createAsyncThunk('cart/item/remove', async (arg, { dispatch, getState }) => {
  const { sku, getRevampingCoupons } = arg;
  const {
    basketInfo,
  } = getState().cart;
  const { project } = config;

  const response = await API.deleteShoppingCartItem({ ...basketInfo, ...{ sku }, getRevampingCoupons, lastBasketItems: true  });
  const { shoppingCartItemList } = response.data?.shoppingCart;
  if (shoppingCartItemList.length > 0 && project === 'sneakerbox') {
    mindboxSetCart(shoppingCartItemList);
  }
  dispatch(resetAddress());
  return {
    ...response.data,
    productSku: sku,
    quantity: arg.quantity,
    productObj: arg,
  };
});

export const bunsarEventAction = createAsyncThunk(
  'cart/bunsar',
  async (arg) => {
    const response = await API.bunsarRequest(arg);
    return response.data;
  },
);

export const addFavoriteActionRequest = createAsyncThunk('cart/item/addFavorite', async (arg, { dispatch, getState }) => {
  const { sku } = arg;
  const { productId } = arg;
  const response = await API.addFavouriteProduct({ productId });
  const { project } = config;
  if (response?.data?.status.code === 200) {
    const {
      basketInfo
    } = getState().cart;
    if (project === 'sneakerbox') {
      mindboxFavoriteList(productId);
    }
    const res = await API.deleteShoppingCartItem({ ...basketInfo, ...{ sku }, lastBasketItems: true, getRevampingCoupons: 1 });
    const { shoppingCartItemList } = res.data?.shoppingCart;
    if (shoppingCartItemList.length > 0 && project === 'sneakerbox') {
      mindboxSetCart(shoppingCartItemList);
    }
    return {
      ...res?.data,
      productSku: sku,
      quantity: arg?.quantity,
      price:arg?.price,
      category: arg?.category
    };
  }
  return {
    ...response?.data,
    productSku: sku,
    quantity: arg?.quantity,
    price:arg?.price,
    category: arg?.category
  };
});

export const removeFavoriteActionRequest = createAsyncThunk('cart/item/removeFavorite', async (arg) => {
  const { product } = arg;
  const productId = product.productId
  const response = await API.removeFavouriteProduct({ productId });
  const { project } = config;

  if (response?.data?.status.code === 200) {
    if (!!Cookie.get('webview')) {
      logEvent('remove_from_favorite',{
        checkout_step: 1,
        currency: "TRY",
        content_id: product.sku,
        price:product.price,
        content_type:product.category?.name
      });
    } else {
      if (project !== 'sneakerbox') {
        window.events.emit('analytics.removeFromFavorites', { sku: product.sku, productObj: product });
        releatedFavoriteListRemove(product.sku, product.price);
      }
    }
    const res = await API.getFavouriteProducts();
    return res
  }

  return {
    ...response?.data,
    productSku: sku,
    quantity: arg?.quantity,
    price:arg?.price,
    category: arg?.category
  };
});

const getOrSetVisitorId = () => {
  const { COOKIE_ROOT_URL, project } = config;
  if (project === 'flo') {
    let visitorId = Cookie.get('bunsar_visitor_id', { domain: COOKIE_ROOT_URL });
    if (!visitorId) {
      visitorId = uuidv4();
      Cookie.set('bunsar_visitor_id', visitorId, { domain: COOKIE_ROOT_URL, expires: 365 });
    }

    return visitorId;
  }
  return null
}

const getShopperId = () => {
  const customerId = Cookie.get('customer_id');
  return customerId ? md5(customerId) : null;
}

const INITIAL_STATE = {
  coupons: [],
  basketItemLimit: {
    limit: 10,
    message: 'En fazla 20 adet ürün ekleyebiliriz',
  },
  basketId: null,
  basketInfo: {
    basketType: '',
    storeCode: '',
  },
  visitorId: getOrSetVisitorId(),
  shopperId: getShopperId(),
  shoppingCart: {
    ...((() => {
      if (window.shoppingCart) {
        return window.shoppingCart;
      }

      return {
        couponCode: null,
        shoppingCartItemList: [],
      };
    })()),
  },
  isServiceReady: true,
  favouriteProducts: [],
  revampingTabProducts: [],
};

const slice = createSlice({
  name: 'cart',
  initialState: INITIAL_STATE,
  reducers: {
    cartActionReceive: (state, action) => {
      state.shoppingCart = action.payload;
    },
    setCouponsAction: (state, action) => {
      state.coupons = action.payload;
    },
    setBasketItemLimitAction: (state, action) => {
      state.basketItemLimit = action.payload;
    },
    setHandleQuantityAction: (state, action) => {
      return false;
    },
    setBunsarStoreAction: (state) => {
      state.shopperId = getShopperId();
    },
    setBasketInfo: (state, action) => {
      if (action.payload.storeCode) {
        localStorage.setItem('storeCode', action.payload.storeCode);
        localStorage.setItem('basketType', 'self_checkout');
        state.basketInfo = action.payload;
      } else if (localStorage.getItem('storeCode')) {
        state.basketInfo = {
          storeCode: localStorage.getItem('storeCode'),
          basketType: localStorage.getItem('basketType')
        };
      }
    },
    setFavouriteProductsAction: (state, action) => {
      state.favouriteProducts = action.payload;
    },
    setRevampingTabProductsAction: (state, action) => {
      state.revampingTabProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    // addToShoppingCartAction
    builder.addCase(addToShoppingCartAction.pending, (state, action) => {
      state.removeCouponsLoading = true;
      state.removeCouponsRequestId = action.meta.requestId;
    });
    builder.addCase(addToShoppingCartAction.fulfilled, (state, { payload }) => {
      const { status, warningMessage, productSku, product, shoppingCart } = payload;
      const { project } = config;
      if (status?.code === 200) {
        if (!!Cookie.get('webview')) {
          const cloneProduct = {...product}
          cloneProduct.quantity = 1;
          logEvent('add_to_cart', {
            ...cloneProduct,
            value: shoppingCart?.grandTotal,
            currency: "TRY"
          });
        } else {
          if (project !== 'sneakerbox') {
            window.events?.emit('analytics.addToCart', {
              product,
              quantity: 1,
              sku: productSku,
              update: 1,
            });
            releatedEventSetCart(shoppingCart?.shoppingCartItemList, shoppingCart?.shoppingCartId);
          }
        }
        if (warningMessage) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: warningMessage.message }));
        }

        state.shoppingCart = shoppingCart;
        state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
        state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item.stockSourceId) === 0).length === 0);
        const info = shoppingCart?.shoppingCartItemList?.find((item) => Number(item.stockSourceId) > 0)?.bcInfo || null;
        state.bcInfo = info;
        state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item.stockSourceId) === 0).length === 0 && info);
      }

      if (status.code !== 200) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status.message }));
      }

      state.removeCouponsLoading = false;
      state.removeCouponsRequestId = null;
    });
    builder.addCase(addToShoppingCartAction.rejected, (state) => {
      state.removeCouponsLoading = false;
      state.removeCouponsRequestId = null;
    });

    // removeCouponAction
    builder.addCase(removeCouponAction.pending, (state, action) => {
      state.removeCouponsLoading = true;
      state.removeCouponsRequestId = action.meta.requestId;
    });
    builder.addCase(removeCouponAction.fulfilled, (state, { payload }) => {
      const { status, shoppingCart, message } = payload;
      if (status?.code === 200) {
        Object.assign(shoppingCart?.products, current(state.shoppingCart?.products));
        state.shoppingCart = shoppingCart;
        state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
        state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0);
        const info = shoppingCart?.shoppingCartItemList?.find((item) => Number(item?.stockSourceId) > 0)?.bcInfo || null;
        state.bcInfo = info;
        state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item.stockSourceId) === 0).length === 0 && info);
        const couponData = { ...shoppingCart.couponData };
        logEvent('removed_coupon', {
          couponData,
        });

        if (message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message }));
        }
      }

      if (status.code !== 200) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status.message }));
      }

      state.removeCouponsLoading = false;
      state.removeCouponsRequestId = null;
    });
    builder.addCase(removeCouponAction.rejected, (state) => {
      state.removeCouponsLoading = false;
      state.removeCouponsRequestId = null;
    });

    // applyCouponAction
    builder.addCase(applyCouponAction.pending, (state, action) => {
      state.applyCouponsLoading = true;
      state.applyCouponsRequestId = action.meta.requestId;
    });
    builder.addCase(applyCouponAction.fulfilled, (state, { payload }) => {
      const { BASE_URL } = config;
      let RENOVATED_BASE_URL = BASE_URL;
      if (RENOVATED_BASE_URL.endsWith('/')) {
        RENOVATED_BASE_URL = RENOVATED_BASE_URL.slice(0, -1);
      }
      const { status, shoppingCart, low_price_after_coupon, message, success, infoMessage } = payload;

      if (status?.code === 200 && !infoMessage) {
        // state.coupons = activeCoupons;
        Object.assign(shoppingCart?.products, current(state.shoppingCart?.products));
        state.shoppingCart = shoppingCart;
        const beforeApplyCouponGrandTotal = shoppingCart?.before_apply_coupon_grand_total;
        const afterApplyCouponGrandTotal = shoppingCart?.after_apply_coupon_grand_total;
        const couponData = { ...shoppingCart.couponData };
        logEvent('added_coupon', {
          couponData,
        });
        if (low_price_after_coupon) {
          toast.dismiss();
          toast.error(ToastfyCouponConfirm({ beforeApplyCouponGrandTotal, afterApplyCouponGrandTotal }, {
            beforeApplyCouponGrandTotal,
            afterApplyCouponGrandTotal,
          }), { autoClose: false, closeButton: false });
        } else if (message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message }));
        }
      } else if (status?.code === 200 && infoMessage) {
        toast.dismiss();
        toast.error(ToastfyMessage({
          text: 'Kuponunu kullanmam için son adım!',
          description: `Kupon kullanabilmek için sepete <strong>${infoMessage.amount} TL</strong>’lik daha ürün eklemen gerekir.`,
          buttonLink: RENOVATED_BASE_URL + infoMessage.query,
          type: 'apply-coupon',
        }), { autoClose: false })
      }

      if (status?.code !== 200 || success === false ) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message || message }), { autoClose: 8000 });
      }

      state.applyCouponsLoading = false;
      state.applyCouponsRequestId = null;
    });
    builder.addCase(applyCouponAction.rejected, (state) => {
      state.applyCouponsLoading = false;
      state.applyCouponsRequestId = null;
    });

    // getCouponsAction
    builder.addCase(getCouponsAction.pending, (state, action) => {
      state.couponsLoading = true;
      state.couponsRequestId = action.meta.requestId;
    });
    builder.addCase(getCouponsAction.fulfilled, (state, { payload }) => {
      const { status, activeCoupons } = payload;
      if (status?.code === 200) {
        state.coupons = activeCoupons;
        if (activeCoupons.length > 0) {
          viewPromotionEvent(activeCoupons)
        }
      }

      if (status?.code !== 200) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status.message }));
      }

      state.couponsLoading = false;
      state.couponsRequestId = null;
    });
    builder.addCase(getCouponsAction.rejected, (state) => {
      state.couponsLoading = false;
      state.couponsRequestId = null;
    });

    // cartActionRequest
    builder.addCase(cartActionRequest.pending, (state, action) => {
      state.cartLoading = true;
      state.cartRequestId = action.meta.requestId;
    });
    builder.addCase(cartActionRequest.fulfilled, (state, { payload }) => {
      try {
        const { status, shoppingCart } = payload;
        if (status.code === 200) {
          state.shoppingCart = shoppingCart;
          state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
          state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0);
          const info = shoppingCart?.shoppingCartItemList?.find((item) => Number(item?.stockSourceId) > 0)?.bcInfo || null;
          state.bcInfo = info;
          state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0 && info);
          state.basketId = shoppingCart?.shoppingCartId;
          state.pickUpInStoreEnabled = shoppingCart?.pickUpInStoreEnabled;
          // bunsar event for succes page.
          if (!localStorage.getItem('bunsarEventCredentials')) {
            localStorage.setItem('bunsarEventCredentials',JSON.stringify(shoppingCart?.bunsarEventCredentials))
          }
        }
  
        if (status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }
  
      } catch (error) {
      // Servis herhangi bir şekilde hata alır ise, state false olarak güncellenir.
      state.isServiceReady=false
      }
      state.cartLoading = false;
      state.cartRequestId = null;  
    });
    builder.addCase(cartActionRequest.rejected, (state) => {
      state.cartLoading = false;
      state.cartRequestId = null;
    });

    // useLoyaltyAction
    builder.addCase(useLoyaltyAction.pending, (state, action) => {
      state.cartLoading = true;
    });
    builder.addCase(useLoyaltyAction.fulfilled, (state, { payload }) => {
      const { status, shoppingCart } = payload;
      if (status.code === 200) {
        state.shoppingCart = shoppingCart;
      }
      state.cartLoading = false;
    });
    builder.addCase(useLoyaltyAction.rejected, (state) => {
      state.cartLoading = false;
    });

    // applyBonusAction
    builder.addCase(applyBonusAction.pending, (state, action) => {
      state.applyBonusLoading = true;
    });
    builder.addCase(applyBonusAction.fulfilled, (state, { payload }) => {
      const { status, shoppingCart } = payload;
      if (status.code === 200) {
        state.shoppingCart = shoppingCart;
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }
      state.applyBonusLoading = false;
    });
    builder.addCase(applyBonusAction.rejected, (state) => {
      state.applyBonusLoading = false;
    });

    // updateCartItemActionRequest
    builder.addCase(updateCartItemActionRequest.pending, (state, action) => {
      state.updateCartItemLoading = true;
      state.updateCartItemRequestId = action.meta.requestId;
    });
    builder.addCase(updateCartItemActionRequest.fulfilled, (state, { payload }) => {
      const { status, shoppingCart, productSku, oldQuantity, quantity } = payload;
      if (status?.code === 200) {
        Object.assign(shoppingCart?.products, current(state.shoppingCart?.products));
        state.shoppingCart = shoppingCart;
        state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
        state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0);
        const info = shoppingCart?.shoppingCartItemList?.find((item) => Number(item?.stockSourceId) > 0)?.bcInfo || null;
        state.bcInfo = info;
        state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0 && info);
        const product = Object.assign({}, shoppingCart?.shoppingCartItemList?.find((item) => item?.sku === productSku));
        const { project } = config;
        if (!!Cookie.get('webview')) {
          if (oldQuantity >= quantity) {
            product.quantity = (oldQuantity - quantity);
            logEvent('remove_from_cart', {
              ...product,
              items: shoppingCart?.shoppingCartItemList,
              value: shoppingCart?.grandTotal,
              checkout_step: 1,
              currency: "TRY"
            });
          } else {
            product.quantity = (quantity - oldQuantity);
            logEvent('add_to_cart', {
              ...product,
              value: shoppingCart?.grandTotal,
              currency: "TRY"
            });
          }
          if (shoppingCart?.shoppingCartItemList.length > 0) {
            logEvent('begin_checkout', {
              items: shoppingCart?.shoppingCartItemList,
              value: shoppingCart?.grandTotal,
              checkout_step: 1,
              currency: 'TRY'
            });
          }
        } else {
          if (oldQuantity > quantity) {
            if (project !== 'sneakerbox') {
              window.events?.emit('analytics.removeFromCart', {
                quantity: (oldQuantity - quantity),
                sku: productSku,
                update: 1,
                productObj: product
              });
            }
          } else {
            if (project !== 'sneakerbox') {
              window.events?.emit('analytics.addToCart', {
                product,
                quantity: (quantity - oldQuantity),
                sku: productSku,
                update: 1,
              });
              releatedEventSetCart(product, shoppingCart?.shoppingCartId);
            }
          }
          if (shoppingCart?.shoppingCartItemList.length > 0 && project === 'sneakerbox') {
            mindboxSetCart(shoppingCart?.shoppingCartItemList);
          }
        }
      }

      if (status?.code !== 200) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message || '' }));
      }

      state.updateCartItemLoading = false;
      state.updateCartItemRequestId = null;
    });
    builder.addCase(updateCartItemActionRequest.rejected, (state) => {
      state.updateCartItemLoading = false;
      state.updateCartItemRequestId = null;
    });

    // removeCartItemActionRequest
    builder.addCase(removeCartItemActionRequest.pending, (state, action) => {
      state.removeCartItemLoading = true;
      state.removeCartItemRequestId = action.meta.requestId;
    });
    builder.addCase(removeCartItemActionRequest.fulfilled, (state, { payload }) => {
      const { status, shoppingCart, productSku, quantity } = payload;
      const { project } = config;
      if (status?.code === 200) {
        const oldShoppingCart = current(state.shoppingCart);
        state.shoppingCart = shoppingCart;
        state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
        state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0);
        const info = shoppingCart.shoppingCartItemList?.find((item) => Number(item?.stockSourceId) > 0)?.bcInfo || null;
        state.bcInfo = info;
        state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0 && info);
        const product = Object.assign({}, oldShoppingCart?.shoppingCartItemList?.find((item) => item?.sku === productSku));
        if (!!Cookie.get('webview')) {
          logEvent('remove_from_cart', {
            ...payload,
            items: [product],
            value: shoppingCart?.grandTotal,
            checkout_step: 1,
            currency: "TRY"
          });
          if (shoppingCart?.shoppingCartItemList.length > 0) {
            logEvent('begin_checkout', {
              items: shoppingCart?.shoppingCartItemList,
              value: shoppingCart?.grandTotal,
              checkout_step: 1,
              currency: 'TRY'
            });
          }
        } else {
          if (project !== 'sneakerbox') {
            window.events?.emit('analytics.removeFromCart', {
              quantity: quantity || 1,
              sku: productSku,
              update: 1,
              productObj: product
            });
          }
        }
        toast.dismiss();
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      if (status.code === 2072) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      state.removeCartItemLoading = false;
      state.removeCartItemRequestId = null;
    });
    builder.addCase(removeCartItemActionRequest.rejected, (state) => {
      state.removeCartItemLoading = false;
      state.removeCartItemRequestId = null;
    });

    // addFaovireActionRequest
    builder.addCase(addFavoriteActionRequest.pending, (state, action) => {
      state.removeCartItemLoading = true;
      state.removeCartItemRequestId = action.meta.requestId;
    });
    builder.addCase(addFavoriteActionRequest.fulfilled, (state, { payload }) => {
      const { status, shoppingCart, productSku, quantity, price, category } = payload;
      const { project } = config;
      if (status?.code === 200) {
        const oldShoppingCart = current(state.shoppingCart);
        if (shoppingCart) {
          state.shoppingCart = shoppingCart;
          state.openPickUpInStore = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.merchant?.id || 10000) > 10000).length === 0)
          state.isBrickCollectActive = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0);
          const info = shoppingCart?.shoppingCartItemList?.find((item) => Number(item?.stockSourceId) > 0)?.bcInfo || null;
          state.bcInfo = info;
          state.isOnlyBrickCollect = (shoppingCart?.shoppingCartItemList?.filter((item) => Number(item?.stockSourceId) === 0).length === 0 && info);
        }
        const product = Object.assign({}, oldShoppingCart?.shoppingCartItemList?.find((item) => item?.sku === productSku));
        if (!!Cookie.get('webview')) {
          logEvent('remove_from_cart',{
            ...payload,
            items: [product],
            value: shoppingCart?.grandTotal,
            checkout_step: 1,
            currency: "TRY"
          });
          logEvent('add_to_wishlist',{
            checkout_step: 1,
            currency: "TRY",
            content_id: productSku,
            price:price,
            content_type:category?.name
          });
        } else {
          // remove from cart
          if (project !== 'sneakerbox') {
            window.events?.emit('analytics.removeFromCart', {
              quantity,
              sku: productSku,
              update: 1,
              productObj: product
            });
  
            // add to favorite
            window.events.emit('analytics.addToFavorite', { sku: productSku, productObj: product });
            releatedFavoriteList(productSku, price);
          }

          const index = oldShoppingCart?.shoppingCartItemList?.findIndex((item) => item?.sku === productSku);
          addToWishlistEvent(product, index)
        }
      }
      if (status.code !== 200) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status.message }));
      }

      state.removeCartItemLoading = false;
      state.removeCartItemRequestId = null;
    });
    builder.addCase(addFavoriteActionRequest.rejected, (state) => {
      state.removeCartItemLoading = false;
      state.removeCartItemRequestId = null;
    });
  },
});

export default slice.reducer;

export const {
  cartActionReceive,
  setCouponsAction,
  setBasketItemLimitAction,
  setBasketInfo,
  setBunsarStoreAction,
  setFavouriteProductsAction,
  setRevampingTabProductsAction
} = slice.actions;
