import React from 'react';
import config from '@Config';
import {
  gettext
} from '@Translate';

const { BASE_URL } = config;

const InstallmentItem = ({installment}) => {

const cardPlatform = installment.find((item)=> item.platform);
const platfomImage = cardPlatform.platform;

  return (
    <>
        <div class="instalment-box">
          <figure class="instalment-box__images">
            <img src={`${BASE_URL}/pub/assets/instreet/images/${platfomImage}.png`} alt="" />
          </figure>
          <div class="instalment-box__item">
            <table>
              <thead>
                <tr>
                  <th>Taksit Sayısı</th>
                  <th>Taksit Tutarı</th>
                  <th>Toplam Tutar</th>
                </tr>
              </thead>
              <tbody>
                {installment.map((installmentItem,itemIndex) => (
                  <tr key={itemIndex} data-platform={installmentItem?.platform}>
                    <td>{installmentItem?.installment} {gettext('Taksit')}</td>
                    <td>{installmentItem?.installment_price} TL</td>
                    <td>{installmentItem?.installment_total_price} TL</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </>
  );
};

export default InstallmentItem;
